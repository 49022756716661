import BookingCard from '@/components/elements/Card/BookingCard/BookingCard';
import { LinkButton } from '@/components/elements/forms/buttons';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Icon from '@/components/icons/Icon';
import EmptyState from '@/components/templates/EmptyState';
import { config } from '@/config';
import {
  canPayCardOnFileAfterBooking,
  canPayKlarnaAfterBooking,
  canPayQliroAfterBooking,
  getDynamicPriceListIdKey,
  getPlaceTimezone,
  getSalonDate,
  getTotalPrice,
  isBookingInThePast,
  isTotalPayable,
  showPrices,
  trackMpEvent,
} from '@/helpers';
import { _s } from '@/locale';
import { ConfirmedBooking } from '@/types/api/services/booking';
import * as moment from 'moment';
import { Fragment } from 'react';

const isToday = (momentDate) => momentDate.isSame(moment().startOf('day'), 'd');

const PRE_PAYMENT_OPTION = {
  COF: 'cof',
  KLARNA: 'klarna',
  QLIRO: 'qliro',
} as const;

type PrePayOption = (typeof PRE_PAYMENT_OPTION)[keyof typeof PRE_PAYMENT_OPTION];

export const getAvailablePrepayOption = (appointment: ConfirmedBooking): PrePayOption | undefined => {
  const anyEmployee = appointment?.extra?.anyEmployee;
  const priceListId = (!anyEmployee && appointment.services?.[0]?.employee?.about?.priceListId) || 0;
  const totalWithoutFee = getTotalPrice(
    appointment.services,
    appointment.place,
    priceListId,
    appointment.extra.giftcards || {},
    0,
    1,
    false,
    appointment.extra.campaigns || [],
    false,
    getDynamicPriceListIdKey(appointment),
    appointment.extra.appliedBundle,
  );
  const shouldShowPrices = showPrices(appointment.place, appointment.services);
  const isPayable = isTotalPayable(totalWithoutFee, shouldShowPrices);
  const isPayAtPlace = appointment.extra.paymentMethod === 0;

  const canPayWithKlarna = isPayable && canPayKlarnaAfterBooking(appointment);
  const canPayWithQliro = isPayable && canPayQliroAfterBooking(appointment);
  const canPayWithCardOnFile = isPayable && canPayCardOnFileAfterBooking(appointment);

  if (canPayWithCardOnFile && isPayAtPlace) {
    return 'cof';
  }

  if (canPayWithKlarna && isPayAtPlace) {
    return 'klarna';
  }

  if (canPayWithQliro && isPayAtPlace) {
    return 'qliro';
  }

  return undefined;
};

const handlePaymentClick = (id: number) => {
  trackMpEvent('pay_online_now_click', { screen_name: 'my_bookings_upcoming', booking_id: id });
};

export const PrePayAction = ({ appointmentId }: { appointmentId: number }) => {
  return (
    <LinkButton
      variant="primary"
      size="md"
      block
      onClick={() => handlePaymentClick(appointmentId)}
      to={`/booking/checkout/${appointmentId}`}
      leftIcon={<Icon variant="pre-pay" />}
      title={_s('payOnline')}>
      {_s('payOnline')}
    </LinkButton>
  );
};

const Appointment = ({ appointment, past }: { appointment: any; past: boolean }) => {
  const timezone = getPlaceTimezone(appointment.place);
  const dateTz = getSalonDate(appointment.start, timezone);
  const day = {
    weekDay: '',
    day: '',
    year: '',
    month: '',
    time: '',
  };
  day.weekDay = isToday(dateTz) ? _s('today') : _s(config.weekDaysShort[dateTz.day()]);
  day.day = dateTz.date().toString();
  day.year = dateTz.year().toString();
  day.month = config.monthsShort[dateTz.month()];
  day.time = dateTz.format('HH:mm');

  const date = `${day.weekDay} ${day.day} ${day.month} ${day.year} - ${day.time}`;

  let chosenServices =
    appointment.services[0].service.name +
    (appointment.services.length > 1 ? ' (+ ' + (appointment.services.length - 1) + ` ${_s('moreAmount')})` : '');

  const anyEmployee = appointment?.extra?.anyEmployee;
  const chosenEmployee = anyEmployee
    ? null
    : appointment.services.length > 0 && appointment.services[0]?.employee?.id
    ? appointment.services[0].employee?.about?.name
    : null;

  const placeName = appointment.place && appointment.place?.about?.name;

  const image = appointment?.place?.about?.profileImage
    ? {
        src: appointment?.place?.about?.profileImage,
        alt: appointment?.place?.about?.name,
      }
    : null;

  const availablePrePayOption = getAvailablePrepayOption(appointment);
  const isUpcoming = !isBookingInThePast(appointment);
  const status = appointment.status + (!isUpcoming ? 100 : 0);

  return (
    <BookingCard
      src={{
        pathname: `/bokning/${appointment.id}`,
        state: { from: past ? '/bokningar/tidigare' : '/bokningar' },
      }}
      placeName={placeName}
      dateTime={date}
      image={image}
      performer={chosenEmployee}
      services={chosenServices}
      status={status}
      {...(availablePrePayOption && {
        bottomSlot: <PrePayAction appointmentId={appointment.id} />,
      })}
    />
  );
};

const AppointmentsList = ({ past, appointments }) => {
  const message = past ? 'pastAppointmentsMessage' : 'upcomingAppointmentsMessage';
  const label = past ? 'nopast' : 'noupcoming';
  const title = _s(label);

  const hasPrePayOption = ((appointments || []) as unknown[]).some((appointment) =>
    Boolean(getAvailablePrepayOption(appointment)),
  );

  return (
    <div id={past ? 'Past' : 'Upcoming'} role="tabpanel" aria-labelledby={past ? 'Past-tab' : 'Upcoming-tab'}>
      {!past && hasPrePayOption && (
        <div className="mb-lg">
          <Alert
            variant="information"
            leftSlot={<Icon variant="info-circle" color="information-900" />}
            body={_s('encouragePrePayBooking')}
          />
        </div>
      )}
      {appointments.length ? (
        <Fragment>
          {appointments.map((appointment) => (
            <div key={appointment.id} className="mb-md">
              <Appointment appointment={appointment} past={!!past} />
            </div>
          ))}
        </Fragment>
      ) : (
        <EmptyState
          body={_s(message)}
          title={title}
          icon={
            label === 'noupcoming' ? (
              <img src="/images/no-bookings1.svg" alt="" />
            ) : (
              <img src="/images/no-bookings2.svg" alt="" />
            )
          }
          cta={
            <LinkButton variant="primary" size="sm" to="/" title={_s('bookAService')}>
              {_s('bookAService')}
            </LinkButton>
          }
        />
      )}
    </div>
  );
};

export default AppointmentsList;
