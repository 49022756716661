import Icon from '@/components/icons/Icon';
import { ChangeEvent, FocusEventHandler, MouseEventHandler, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { ListItem, ListItemContent } from '../../lists';
import css from './SearchInput.module.scss';

const renderSuggestion = (suggestion) => (
  <ListItem
    type="div"
    leftSlot={<Icon variant="location" />}
    onClick={() => {}}
    underline
    className="hover:bg-black-50">
    <ListItemContent title={suggestion} />
  </ListItem>
);

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestionsContainer = ({ containerProps, children }) => {
  return (
    <>
      {children && (
        <div
          {...containerProps}
          className="py-lg mt-xs absolute left-0 right-0 z-10 rounded-lg bg-white drop-shadow-lg">
          {children}
        </div>
      )}
    </>
  );
};

type SearchInputProps = {
  value?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, { newValue }: { newValue?: string }) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onRemove: MouseEventHandler<HTMLButtonElement>;
  onSuggestionSelected?: () => void;
  error?: string;
  description?: string;
  placeholder: string;
  title?: string;
  suggestions?: Array<string>;
};

const SearchInput = ({
  value = '',
  onChange,
  onFocus = null,
  onRemove,
  onSuggestionSelected,
  error = null,
  description = null,
  placeholder,
  title = null,
  suggestions = [],
}: SearchInputProps) => {
  const [currentSuggestions, setCurrentSuggestions] = useState([]);

  const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : suggestions.filter((suggestion) => suggestion.toLocaleLowerCase().slice(0, inputLength) === inputValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setCurrentSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setCurrentSuggestions([]);
  };

  const renderInputComponent = (inputProps) => {
    return (
      <div className={`gap-xxs flex w-full flex-col`}>
        {title && (
          <label htmlFor="search-input" className="text-md text-black-900">
            {title}
          </label>
        )}
        <div
          className={`border-black-200 gap-sm px-lg py-md relative flex border bg-white ${
            error ? 'focus-within:border-danger' : 'focus-within:border-black-900'
          } ${css['search-input__container']}`}>
          <Icon variant="search" size="sm" />
          <input id="search-input" {...inputProps} />
          {value && (
            <button onClick={onRemove} className="min-h-[24px] min-w-[24px] outline-none">
              <Icon size="sm" variant="close-circle" />
            </button>
          )}
        </div>
        {description && <div className="text-black-600 text-xxs flex justify-start">{description}</div>}
        {error && <div className="text-danger text-xxs flex justify-start">{error}</div>}
      </div>
    );
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
    onFocus,
    className: 'placeholder:text-black-600 text-black-900 w-full flex-1 text-sm outline-none',
  };

  const containerProps = {
    className: 'relative',
  };

  return (
    <Autosuggest
      id="search-input-autosuggest"
      suggestions={currentSuggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={renderInputComponent}
      containerProps={containerProps}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

export default SearchInput;
