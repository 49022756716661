import { blogActions } from '@/actions';
import { h3Class } from '@/components/elements/content';
import { ArchiveLayout } from '@/components/layouts/blog';
import { classnames, useBlogData } from '@/helpers';
import { _s } from '@/locale';
import { connect, useDispatch } from 'react-redux';
import { ArchivePost, LoadingPosts } from '.';

const DCLatestPosts = ({ posts: ssrPosts }) => {
  const dispatch = useDispatch();
  const fetchPosts = () => dispatch(blogActions.latest());
  const posts = useBlogData(ssrPosts, fetchPosts);

  if (!posts) return <LoadingPosts />;

  return (
    <>
      <h2 className={classnames(h3Class, 'text-secondary-500 !my-10')}>{_s(`blogPage.morePosts`)}</h2>
      <ArchiveLayout>
        {posts && posts.length > 0 && posts.map((post, i) => <ArchivePost post={post} key={i} />)}
      </ArchiveLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  posts: state.blog.latest,
});

const LatestPosts = connect(mapStateToProps)(DCLatestPosts);
export default LatestPosts;
