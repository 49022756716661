import { loggedUser } from '@/helpers';
import { _s } from '@/locale';
import { NavItemIdentifier } from '@/types/navigation';

const tBase = (key: string) => _s(`footerNavigation.${key}`);

type FooterLink = {
  url: string;
  title: string;
  link?: boolean;
  identifier?: NavItemIdentifier;
};
export enum FooterColumnGroup {
  Bokadirekt = 'bokadirekt',
  PopularHealth = 'popular-health',
  PopularMix = 'popular-mix',
  PopularBeauty = 'popular-beauty',
  More = 'more',
  Sistaminutentider = 'sistaminutentider',
}

export type FooterColumn = {
  title: string;
  links: FooterLink[];
  group: FooterColumnGroup;
  className?: string;
};

export const footerTosItems: FooterLink[] = [
  { url: '/articles/ethical-policy', title: tBase('ethicalPolicy'), link: true },
  { url: '/articles/privacy-policy', title: tBase('privacyPolicy'), link: true },
  { url: '/articles/cookies', title: tBase('cookies'), link: true },
  { url: '/articles/terms', title: tBase('termsConditions'), link: true },
];

const businessConnectLink: FooterLink = {
  url: 'https://business.bokadirekt.se/',
  title: tBase('connectBussiness'),
};

export const footerNavigationItems = (isSistaminuten: boolean, isListPage: boolean): FooterColumn[] =>
  [
    !isSistaminuten && {
      ...{
        group: FooterColumnGroup.Bokadirekt,
        title: tBase('myAccount'),
        links: [
          loggedUser
            ? undefined
            : {
                url: '#',
                title: tBase('login'),
                identifier: NavItemIdentifier.LogIn,
              },
          {
            url: '/bokningar',
            title: tBase('myBookings'),
            identifier: NavItemIdentifier.Bookings,
            link: true,
          },
          {
            url: '/favoriter',
            title: tBase('myFavorites'),
            identifier: NavItemIdentifier.Favorites,
            link: true,
          },
          {
            url: '/giftcards',
            title: tBase('useGiftcard'),
            identifier: NavItemIdentifier.GiftCardUse,
            link: true,
          },
          {
            url: '/giftcards',
            title: tBase('useWellnesscard'),
            identifier: NavItemIdentifier.WellnessCardUse,
          },
        ].filter((a) => a),
      },
    },
    {
      group: FooterColumnGroup.Bokadirekt,
      title: tBase('contact'),
      links: (
        [
          { url: '/support', title: tBase('support'), link: true },
          {
            url: 'https://konto.bokadirekt.se/login?ReturnUrl=%2Fsignin%3Ftype%3Dmerchant',
            title: tBase('companyLogin'),
          },
          { url: '/articles/about', title: tBase('aboutBokadirekt'), link: true },
        ] as FooterLink[]
      ).concat(isSistaminuten ? [...footerTosItems, businessConnectLink] : []),
    },
    !isSistaminuten && {
      ...{
        group: FooterColumnGroup.PopularMix,
        title: tBase('popularBokadirekt'),
        links: [
          { url: '/frisör/var', title: tBase('hairdresser'), link: true },
          { url: '/naglar/var', title: tBase('nails'), link: true },
          { url: '/hudvård/var', title: tBase('skincare'), link: true },
          { url: '/fransförlängning/var', title: tBase('eyelashExtension'), link: true },
          { url: '/lashlift/var', title: tBase('lashlift'), link: true },
          { url: '/browlift/var', title: tBase('browlift'), link: true },
          { url: '/fillers/var', title: tBase('fillers'), link: true },
          { url: '/ipl/var', title: tBase('ipl'), link: true },
          { url: '/vaxning/var', title: tBase('waxing'), link: true },
          { url: '/fotvård/var', title: tBase('pedicure'), link: true },
          { url: '/massage/var', title: tBase('massage'), link: true },
          { url: '/träning/var', title: tBase('exercise'), link: true },
          { url: '/friskvård/var', title: tBase('wellness'), link: true },
          { url: '/acne/var', title: tBase('acne'), link: true },
          { url: '/fysioterapi/var', title: tBase('physiotherapy'), link: true },
          { url: '/naprapat/var', title: tBase('naprapat'), link: true },
          { url: '/kiropraktik/var', title: tBase('chiropractic'), link: true },
          { url: '/svettbehandling/var', title: tBase('sweatTreatment'), link: true },
        ],
        className: `block ${isListPage ? '2xl:hidden' : 'lg:hidden'}`,
      },
    },
    !isSistaminuten && {
      ...{
        group: FooterColumnGroup.PopularBeauty,
        title: tBase('popularBeauty'),
        links: [
          { url: '/frisör/var', title: tBase('hairdresser'), link: true },
          { url: '/naglar/var', title: tBase('nails'), link: true },
          { url: '/hudvård/var', title: tBase('skincare'), link: true },
          { url: '/fransförlängning/var', title: tBase('eyelashExtension'), link: true },
          { url: '/lashlift/var', title: tBase('lashlift'), link: true },
          { url: '/browlift/var', title: tBase('browlift'), link: true },
          { url: '/fillers/var', title: tBase('fillers'), link: true },
          { url: '/ipl/var', title: tBase('ipl'), link: true },
          { url: '/vaxning/var', title: tBase('waxing'), link: true },
          { url: '/fotvård/var', title: tBase('pedicure'), link: true },
        ],
        className: `hidden ${isListPage ? '2xl:block' : 'lg:block'}`,
      },
    },
    !isSistaminuten && {
      ...{
        group: FooterColumnGroup.PopularHealth,
        title: tBase('popularHealth'),
        links: [
          { url: '/massage/var', title: tBase('massage'), link: true },
          { url: '/träning/var', title: tBase('exercise'), link: true },
          { url: '/friskvård/var', title: tBase('wellness'), link: true },
          { url: '/acne/var', title: tBase('acne'), link: true },
          { url: '/fysioterapi/var', title: tBase('physiotherapy'), link: true },
          { url: '/naprapat/var', title: tBase('naprapat'), link: true },
          { url: '/kiropraktik/var', title: tBase('chiropractic'), link: true },
          { url: '/svettbehandling/var', title: tBase('sweatTreatment'), link: true },
        ],
        className: `hidden ${isListPage ? '2xl:block' : 'lg:block'}`,
      },
    },
    isSistaminuten && {
      ...{
        group: FooterColumnGroup.Sistaminutentider,
        title: tBase('categories'),
        links: [
          { url: '/frisör/var', title: tBase('hairdresser'), link: true },
          { url: '/träning/var', title: tBase('exercise'), link: true },
          { url: '/hudvård/var', title: tBase('skincare'), link: true },
          { url: '/massage/var', title: tBase('massage'), link: true },
          { url: '/naglar/var', title: tBase('nails'), link: true },
          { url: '/fillers/var', title: tBase('fillers'), link: true },
          { url: '/friskvård/var', title: tBase('wellness'), link: true },
          { url: '/fransförlängning/var', title: tBase('eyelashExtension'), link: true },
        ],
      },
    },
    !isSistaminuten && {
      ...{
        group: FooterColumnGroup.More,
        title: tBase('more'),
        links: [
          {
            url: '/giftcards/buy',
            title: tBase('buyGiftcard'),
            identifier: NavItemIdentifier.GiftCardBuy,
            link: true,
          },
          {
            url: '/giftcards/buy?ugctype=wellness',
            title: tBase('buyWellnesscardMassage'),
            identifier: NavItemIdentifier.WellnessCardBuy,
            link: true,
          },
          { url: 'https://careers.bokadirekt.se', title: tBase('careers') },
          { url: '/newsletter', title: tBase('newsletter'), link: true },
          { url: '/blogg/', title: tBase('blog') },
          { url: '/fakta-och-rad', title: tBase('factsAndAdvice'), link: true },
          { url: '/faq-vanliga-fragor', title: tBase('faqBeautyTreatments'), link: true },
        ],
      },
    },
  ].filter((shown) => shown);
