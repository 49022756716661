import { config } from '@/config';
import { allowTracking } from '@/helpers';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import * as Sentry from '@sentry/react';
import { createContext, useContext, useEffect, useState } from 'react';

export type AmplitudeExperimentContextType = ReturnType<typeof useAmplitudeExperiment>;
export const AmplitudeExperimentContext = createContext<AmplitudeExperimentContextType>(null);

type Variant = {
  value?: string;
  payload?: any;
};

const useAmplitudeExperiment = (): {
  getVariant: (exposureEvent: string) => Variant;
} => {
  const [amplitudeExperimentInstance, setAmplitudeExperimentInstance] = useState<ExperimentClient>(null);

  useEffect(() => {
    if (allowTracking() && config.amplitudeExperiment) {
      const experiment = Experiment.initializeWithAmplitudeAnalytics(config.amplitudeExperiment, {
        pollOnStart: false,
      });

      experiment
        .start()
        .then(() => setAmplitudeExperimentInstance(experiment))
        .catch((err) => {
          const requestTimeout = err.message.startsWith('Request timeout');
          const failedToFetch =
            err instanceof TypeError &&
            (err.message === 'Failed to fetch' || err.message === 'Load failed' || err.message === 'NetworkError');
          const isNetworkError = requestTimeout || failedToFetch;
          if (!isNetworkError) {
            Sentry.captureException(err);
          }
        });
    }
    return () => {
      if (amplitudeExperimentInstance) {
        amplitudeExperimentInstance.stop();
      }
    };
  }, []);

  const getVariant = (exposureEvent: string): Variant => {
    const { value, payload } = amplitudeExperimentInstance
      ? amplitudeExperimentInstance.variant(exposureEvent)
      : { value: null, payload: null };

    return {
      value,
      payload,
    };
  };

  return { getVariant };
};

export const useGetAmplitudeExperimentVariant = (): AmplitudeExperimentContextType['getVariant'] => {
  const { getVariant } = useContext(AmplitudeExperimentContext);
  return getVariant;
};

export const AmplitudeExperimentProvider = ({ children }) => {
  return (
    <AmplitudeExperimentContext.Provider value={useAmplitudeExperiment()}>
      {children}
    </AmplitudeExperimentContext.Provider>
  );
};
