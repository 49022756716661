import { featurePromoActions } from '@/actions/featurePromoActions';
import colors from '@/colors';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { FEATURE_PROMO_LOCAL_STORAGE_KEY } from '@/constants/featurePromoConstants';
import { trackMpEvent } from '@/helpers';
import { _s } from '@/locale';
import { getScreenName } from '@/services/navigationServices';
import * as Sentry from '@sentry/react';
import { getSvgPath } from 'figma-squircle';
import { Dispatch, useEffect, useRef, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, Placement, STATUS, Step } from 'react-joyride';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../forms/buttons';

const baseTranslationKey = `featurePromos`;

const getTranslationStep = (featureId: string, stepTarget: string) => {
  return {
    title: _s(`${baseTranslationKey}.${featureId}.${stepTarget}.title`),
    body: _s(`${baseTranslationKey}.${featureId}.${stepTarget}.body`),
  };
};

const getFeaturePromoTrackingId = (featurePromoId) => `feature_promo_${featurePromoId.replace(/-/g, '_')}`;

type FeaturePromoState = {
  run: boolean;
  stepIndex: number;
};

export type FeaturePromoProps = {
  key: string;
  id: string;
  steps: FeaturePromoStep[];
  start: boolean;
};

type FeaturePromos = FeaturePromoProps[];

type FeaturePromoStep = {
  image?: {
    src: string;
    alt: string;
  };
  target: string;
  placement?: Placement | 'auto' | 'center';
  beaconInteraction?: 'click' | 'hover';
  onNextClick?: string; // What target should be clicked on when user clicks next
  onBackClick?: string; // What target should be clicked on when user clicks back
  onFinishClick?: string;
  onFinish?: {
    click?: string; // What target should be clicked on when user clicks finish
    redirectToSource?: boolean; // If the user should be sent to starting route after finish
  };
  spotlightPadding?: number;
  screenName?: string; // Used for tracking
};

const Tooltip = ({
  size,
  isLastStep,
  index,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  skipProps,
  onNextClick,
  onBackClick,
  onSkipClick,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (!tooltipRef.current) return;
    const { width, height } = tooltipRef.current.getBoundingClientRect();
    const path = getSvgPath({ width, height, cornerRadius: 18, cornerSmoothing: 1 });
    setStyle({ width, height, clipPath: `path('${path}')` });
  }, []);

  const handleNextClick = (e) => {
    primaryProps.onClick(e);
    onNextClick();
  };

  const handleBackClick = (e) => {
    backProps.onClick(e);
    onBackClick();
  };

  const handleSkipClick = (e) => {
    skipProps.onClick(e);
    onSkipClick();
  };

  const stepsLeft = size > 1 && !isLastStep;
  return (
    <div {...tooltipProps}>
      <div ref={tooltipRef} className="w-[350px] bg-white drop-shadow-lg md:w-[400px]" style={style}>
        {step.title && <div>{step.title}</div>}
        {step.content && <div>{step.content}</div>}
        <div ref={tooltipRef} className="p-lg flex">
          <div className="flex-1 justify-start">
            {stepsLeft && (
              <Button size="sm" variant="link" {...skipProps} onClick={handleSkipClick}>
                {_s('featurePromo.skip')}
              </Button>
            )}
          </div>
          <div className="gap-md flex">
            {index > 0 && (
              <Button size="sm" variant="link" {...backProps} onClick={handleBackClick}>
                {_s('featurePromo.back')}
              </Button>
            )}
            <Button size="sm" {...primaryProps} onClick={handleNextClick}>
              {stepsLeft ? `${_s('featurePromo.next')} (${index + 1}/${size})` : _s('featurePromo.done')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeaturePromo = ({ steps, id, start }: FeaturePromoProps) => {
  const dispatch = useDispatch();
  const [{ run, stepIndex }, setState] = useState<FeaturePromoState>({
    run: false,
    stepIndex: 0,
  });
  const [source, setSource] = useState<string>('');
  const history = useHistory();
  const isMultipleSteps = steps.length > 1;
  const eventId = getFeaturePromoTrackingId(id);

  useEffect(() => {
    setSource(history.location.pathname);
  }, []);

  // Format steps for joyride
  const joyrideSteps = createSteps({ steps, featureId: id });

  // Create an object with click actions linked to their specific step
  const stepActions = createStepActions({ steps });

  const handleNextClick = () => {
    trackMpEvent(eventId, {
      action: stepIndex === steps.length - 1 ? 'finish_click' : 'next_click',
      screen_name: steps[stepIndex].screenName,
      step: `${stepIndex + 1}/${steps.length}`,
    });
  };
  const handleBackClick = () => {
    trackMpEvent(eventId, {
      action: 'back_click',
      screen_name: steps[stepIndex].screenName,
      step: `${stepIndex + 1}/${steps.length}`,
    });
  };

  const handleSkipClick = () => {
    trackMpEvent(eventId, {
      action: 'skip_click',
      screen_name: steps[stepIndex].screenName,
      step: `${stepIndex + 1}/${steps.length}`,
    });
  };

  useEffect(() => {
    setState({ run: start, stepIndex: 0 });
  }, [start]);

  useEffect(() => {
    const screenName = steps[stepIndex]?.screenName;
    if (screenName) {
      trackMpEvent(eventId, {
        action: 'show',
        screen_name: steps[stepIndex].screenName,
        step: `${stepIndex + 1}/${steps.length}`,
      });
    }
  }, [stepIndex]);

  const handleJoyrideCallback = (data: CallBackProps, id: FeaturePromoProps['id'], dispatch: Dispatch<any>) => {
    const { action, index, status, type } = data;
    const targetString = typeof data.step?.target === 'string' ? data.step?.target : '';

    dispatch(featurePromoActions.updateFeaturePromoEvent({ id, event: type }));

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Set that user has skipped or finished feature promo
      updateFeaturePromoLocalStorage(id);
      dispatch(featurePromoActions.updateFeaturePromoFinished({ id, finished: true }));

      // If step has set a finish click target
      if (stepActions[targetString]?.onFinish.click) {
        try {
          const finishTargetString = stepActions[targetString].onFinish.click;
          const finishTargetElement = document.getElementById(finishTargetString);
          finishTargetElement.click();
        } catch (err) {
          Sentry.captureException(err);
        }
      }

      if (stepActions[targetString]?.onFinish?.redirectToSource) {
        history.push(source);
      }

      setState({ run: false, stepIndex: 0 });
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      // If step has set a next click target
      if (stepActions[targetString]?.onNextClick && action !== ACTIONS.PREV && action !== ACTIONS.CLOSE) {
        try {
          const nextTargetString = stepActions[targetString].onNextClick;
          const nextTargetElement = document.getElementById(nextTargetString);
          nextTargetElement.click();
        } catch (err) {
          toast(({ closeToast }) => <Snackbar type="danger" label={_s('featurePromo.error')} onClose={closeToast} />);
          Sentry.captureException(err);
        }

        // Stop feature promo to let new elements render in dom
        setState({ run: false, stepIndex });

        // Step to next index and highlight newly rendered element
        setTimeout(() => {
          setState({ run: true, stepIndex: nextStepIndex });
        }, 400);

        // If step has set a back click target
      } else if (stepActions[targetString]?.onBackClick && action === ACTIONS.PREV) {
        try {
          const previousTargetString = stepActions[targetString].onBackClick;
          const previousTargetElement = document.getElementById(previousTargetString);
          previousTargetElement.click();
        } catch (err) {
          toast(({ closeToast }) => <Snackbar type="danger" label={_s('featurePromo.error')} onClose={closeToast} />);
          Sentry.captureException(err);
        }

        // Stop feature promo to let new elements render in dom
        setState({ run: false, stepIndex: stepIndex });

        // Step to next index and highlight newly rendered element
        setTimeout(() => {
          setState({ run: true, stepIndex: nextStepIndex });
        }, 400);

        // If user close it by clicking outside of the toolbar
      } else if (action === ACTIONS.CLOSE && status === STATUS.RUNNING) {
        // If the feature promo is currently at the last step
        if (index + 1 >= steps.length) {
          // Set that user has finished feature promo
          updateFeaturePromoLocalStorage(id);
          dispatch(featurePromoActions.updateFeaturePromoFinished({ id, finished: true }));

          setState({
            stepIndex: 0,
            run: false,
          });
        } else {
          setState({
            stepIndex: nextStepIndex,
            run: run,
          });
        }
      } else {
        setState({
          stepIndex: nextStepIndex,
          run: true,
        });
      }
    }
  };

  return (
    <Joyride
      tooltipComponent={(props) => (
        <Tooltip {...props} onNextClick={handleNextClick} onBackClick={handleBackClick} onSkipClick={handleSkipClick} />
      )}
      steps={joyrideSteps}
      run={run}
      continuous={isMultipleSteps}
      showProgress
      disableCloseOnEsc
      disableOverlayClose
      debug
      showSkipButton
      callback={(props) => handleJoyrideCallback(props, id, dispatch)}
      styles={{
        options: {
          zIndex: 10001,
        },
        beaconOuter: {
          backgroundColor: colors.danger[500] + '33',
          border: `1px solid ${colors.danger[500]}`,
        },
        beaconInner: {
          backgroundColor: colors.danger[500],
        },
      }}
      stepIndex={stepIndex}
    />
  );
};

// Create mappings for steps and their respective click actions
const createStepActions = ({ steps }: { steps: FeaturePromoStep[] }) => {
  const stepActions = {};
  steps.forEach((step) => {
    const stepTargetWithCssSelector = step.target === 'root' ? `:${step.target}` : `#${step.target}`;
    stepActions[stepTargetWithCssSelector] = {
      onNextClick: step?.onNextClick ?? '',
      onBackClick: step?.onBackClick ?? '',
      onFinishClick: step?.onFinishClick ?? '',
      onFinish: step?.onFinish ?? '',
    };
  });
  return stepActions;
};

// Create formatted steps for react joyride
const createSteps = ({
  steps,
  featureId,
}: {
  steps: FeaturePromoStep[];
  featureId: FeaturePromoProps['id'];
}): Step[] => {
  return steps.map((step) => ({
    content: (
      <>
        {step?.image?.src && (
          <div className="flex justify-center">
            <img src={step.image.src} alt={step.image?.alt ?? ''} />
          </div>
        )}
        <div className="p-lg gap-md flex flex-col">
          <h2 className="text-black-900 text-xl font-bold">{getTranslationStep(featureId, step.target).title}</h2>
          <p className="text-black-600 text-md">{getTranslationStep(featureId, step.target).body}</p>
        </div>
      </>
    ),
    target: `${step.target === 'root' ? ':' : '#'}${step.target}`,
    ...(step?.placement ? { placement: step.placement } : {}),
    ...(step?.beaconInteraction ? { event: step.beaconInteraction } : {}),
    spotlightPadding: step?.spotlightPadding ?? 0,
    disableBeacon: true,
    isFixed: true,
  }));
};

export default FeaturePromo;

// Sync and remove closed/skipped featurePromos from localstorage that is no longer present
export function syncFeaturePromoLocalStorage(newFeaturePromos: FeaturePromos) {
  const featurePromos = localStorage.getItem(FEATURE_PROMO_LOCAL_STORAGE_KEY);
  if (!featurePromos) return;

  const oldFeaturePromos = JSON.parse(featurePromos);
  const syncedFeaturePromos = oldFeaturePromos.filter((oldFeaturePromo) =>
    newFeaturePromos.some((newFeaturePromo) => newFeaturePromo.id === oldFeaturePromo.id),
  );
  if (syncedFeaturePromos.length !== oldFeaturePromos.length) {
    localStorage.setItem(FEATURE_PROMO_LOCAL_STORAGE_KEY, JSON.stringify(syncedFeaturePromos));
  }
}

export function checkIfClosedPreviously(featurePromoId: string) {
  const localStorageFeaturePromos = JSON.parse(
    localStorage.getItem(FEATURE_PROMO_LOCAL_STORAGE_KEY) ?? JSON.stringify([]),
  );

  return localStorageFeaturePromos.some((localStorageFeaturePromo) => localStorageFeaturePromo.id === featurePromoId);
}

export function checkIfScreenBlocked(blockedScreens: string[]) {
  const screenName = getScreenName(undefined);
  const isBlocked = blockedScreens.some((screen) => screenName === screen);
  return isBlocked;
}

function updateFeaturePromoLocalStorage(id: string) {
  const newFeaturePromos = JSON.parse(localStorage.getItem(FEATURE_PROMO_LOCAL_STORAGE_KEY) ?? JSON.stringify([]));

  newFeaturePromos.push({ id: id, hasClosed: true });

  localStorage.setItem(FEATURE_PROMO_LOCAL_STORAGE_KEY, JSON.stringify(newFeaturePromos));
}
