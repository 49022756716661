import { ChevronIcon } from '@/components/icons';
import { isMobile, scrollAccordionContentInView, startOfDay, trackMpEvent } from '@/helpers';
import { themed } from '@/helpers/theme';
import { _s } from '@/locale';
import { Fragment, useState } from 'react';
import Bundle, { PlaceBundle } from './Bundle';

type Place = {
  id: number;
  bookingBundles?: PlaceBundle[];
};
type BundlesProps = {
  place: Place;
  isOpen?: boolean;
  date?: number;
  source?: string;
};

const Bundles = ({ place, isOpen, date, source }: BundlesProps) => {
  const [expanded, setExpanded] = useState(!!(isOpen ?? true));
  const collapseClass = expanded ? 'block' : 'hidden';

  if (!place?.bookingBundles?.length) {
    return null;
  }

  if (!date) {
    date = startOfDay();
  }

  const handleCollapse = (e) => {
    const newExpanded = !expanded;
    if (expanded) {
      trackMpEvent('bundles_category_expanded', {
        company_id: place.id,
        screen_name: source ? source : 'company_details',
      });
      setExpanded(newExpanded);
      scrollAccordionContentInView(e.target);
    } else {
      setExpanded(newExpanded);
    }
  };

  const bundles = place.bookingBundles.map((item) => (
    <Fragment key={item.id}>
      <Bundle bundle={item} place={place} />
    </Fragment>
  ));

  return (
    <div className={`item ${expanded && !isMobile() ? 'mb-4' : ''}`}>
      <div
        onClick={handleCollapse}
        className={`mb-[2px] flex cursor-pointer items-center justify-between p-3 ${themed('bg-black-50', '')}`}>
        <h3 className="font-semibold">{_s('buyBundles.bundles')}</h3>
        <span
          className={`flex items-center justify-center rounded-full p-2 ${
            expanded ? 'bg-[#ADB4C4]' : themed('bg-primary', 'bg-sm_primary')
          }`}>
          <ChevronIcon className={`h-[8px] w-[8px] text-white ${expanded ? 'rotate-180' : ''}`} />
        </span>
      </div>

      <div className={'accordion-content ' + collapseClass} role="tabpanel">
        <ul>{bundles}</ul>
      </div>
    </div>
  );
};

export default Bundles;
