/* eslint react/jsx-no-target-blank: 0 */
import { loadingActions } from '@/actions';
import { Button } from '@/components/elements/forms/buttons';
import { LoadingIcon } from '@/components/icons';
import {
  getAddress,
  getCookie,
  getDirectionsUrl,
  isIOS,
  isMobile,
  setCookie,
  trackMpEvent,
  url,
  getGeoLocation,
} from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import { connect } from 'react-redux';
import Actionsheet from './Actionsheet';

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showActionsheet: false };
  }

  getParams() {
    const { contact, showAddress = true, showMap = true } = this.props;

    if (!(contact && contact.position && contact.position.lat && contact.position.lon))
      return {
        discard: true,
      };

    const address = getAddress(contact.address);
    const gotoLatitude = contact.position.lat;
    const gotoLongitude = contact.position.lon;
    const gotoLocation = gotoLatitude + ':' + gotoLongitude;

    const mapWidth = '270';
    const zoomMargin = '&zoom.margin=10';
    const mapUrl =
      'https://api.hitta.se/image/v2/0_2x/15/' +
      gotoLocation +
      '?width=' +
      mapWidth +
      '&height=94&markers=%7B%22pn%22:%5B' +
      gotoLatitude +
      '%5D,%22pe%22:%5B' +
      gotoLongitude +
      '%5D,%22marker%22:%22https://s3.eu-central-1.amazonaws.com/staging-booking/pin2.png%22%7D&zoom.to=markers' +
      zoomMargin;

    return {
      gotoLatitude,
      gotoLongitude,
      address,
      gotoLocation,
      mapUrl,
      contact,
      showAddress,
      showMap,
    };
  }

  openInAppleMaps(params) {
    const url =
      (!this.state.showDirections ? 'http://maps.apple.com/?address=' : 'http://maps.apple.com/?daddr=') +
      encodeURI(params.gotoLatitude + ',' + params.gotoLongitude);
    window.location.href = url;
  }

  openInGoogleMaps(params) {
    const url =
      !this.state.showDirections
        ? `https://www.google.com/maps/search/?api=1&query=${encodeURI(params.address)}`
        : `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(params.gotoLatitude +','+params.gotoLongitude)}`;
    window.location.href = url;
  }

  openInMaps(showDirections = false) {
    if (isIOS()) {
      this.setState({ showActionsheet: true, showDirections });
    } else {
      const params = this.getParams();
      if (showDirections) {
        this.setState({ showDirections }, () => {
          this.openInGoogleMaps(params);
        });
      } else {
        this.openInGoogleMaps(params);
      }
    }
  }

  render() {
    const params = this.getParams();
    const {
      discard,
      mapUrl,
      contact,
      gotoLongitude,
      gotoLatitude,
      showAddress,
      showMap,
    } = params;

    if (discard) return null;

    const address = contact && contact.address ? contact.address : null;

    let actionsheetMenus = [
      {
        text: __('openInGoogleMaps'),
        action: () => this.openInGoogleMaps(params),
      },
    ];
    if (isIOS()) {
      actionsheetMenus.push({
        text: __('openInAppleMaps'),
        action: () => this.openInAppleMaps(params),
      });
    }

    return (
      <div id="location">
        <div
          className={`text-inherit ${showMap ? 'mb-4' : ''}`}
          onClick={this.handleAddressClick(params)}
          itemProp="address"
          itemScope
          itemType="http://schema.org/PostalAddress">
          {showAddress && (
            <p className="cursor-pointer">
              <span itemProp="streetAddress">{address && address.street ? address.street.trim() : ''}</span>
              {address && address.zipcode ? ', ' : ''}
              <span itemProp="postalCode">{address && address.zipcode ? address.zipcode.trim() : ''}</span>
              {address && address.city ? ', ' : ''}
              <span itemProp="addressLocality">{address && address.city ? address.city.trim() : ''}</span>
            </p>
          )}
        </div>
        {showMap && (
          <>
            <div onClick={this.handleMapClick(params)} className="link col-12 p-0">
              <img
                width={270}
                height={90}
                src={mapUrl}
                alt="map"
                loading="lazy"
                itemProp="image"
                className="rounded-lg"
                itemType="http://schema.org/ImageObject"
                style={{ width: '100%', height: 'auto' }}
              />
              <span itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
                <meta itemProp="latitude" content={gotoLatitude || ''} />
                <meta itemProp="longitude" content={gotoLongitude || ''} />
                <meta itemProp="postalCode" content={address && address.zipcode ? address.zipcode : ''} />
              </span>
            </div>
            <div className="mt-4 flex justify-center">{this.getDirectionsHtml(params)}</div>
          </>
        )}
        {isMobile() && (
          <Actionsheet
            menus={actionsheetMenus}
            show={this.state.showActionsheet}
            hide={() => (e) => this.setState({ showActionsheet: false })}
          />
        )}
      </div>
    );
  }

  getDirectionsHtml(params) {
    const { loadSource, show } = this.props;

    const buttonProps = {
      ...(show && loadSource === 'loadLocation'
        ? {
            rightIcon: <LoadingIcon />,
          }
        : {}),
    };

    return (
      <Button variant="secondary" onClick={this.handleButtonClick(params)} {...buttonProps}>
        {__('getDirection')}
      </Button>
    );
  }

  getCurrentPosition(params) {
    const { dispatch } = this.props;
    if (navigator.geolocation) {
      getGeoLocation(
        (lat, lon) => {
          dispatch(loadingActions.hide());
          this.setLatitudeAndLongitude(lat, lon);
          this.gotoDirectionsMap(params, lat + ':' + lon);
        },
        () => {},
        () => {
          url.fakeLoading(dispatch, 'loadLocation', 120000);
        },
        () => {
          dispatch(loadingActions.hide());
          this.gotoDirectionsMap(params);
        },
      );
    } else {
      this.gotoDirectionsMap(params);
    }
  }

  handleAddressClick = (params) => (e) => {
    trackMpEvent('address_clicked', {
      company_id: this.props.placeId,
      screen_name: 'company_details',
    });
    this.openInMaps();
  };

  handleButtonClick = (params) => (e) => {
    trackMpEvent('navigation_clicked', {
      company_id: this.props.placeId,
      screen_name: 'company_details',
    });
    this.openInMaps(true);
  };

  handleMapClick = (params) => (e) => {
    trackMpEvent('map_clicked', {
      company_id: this.props.placeId,
      screen_name: 'company_details',
    });
    this.openInMaps();
  };

  getDirections(params) {
    const userPosition = getCookie('position'); // user location
    if (userPosition && userPosition.position) {
      const fromLocation = userPosition.position.replace('-', ':');
      this.gotoDirectionsMap(params, fromLocation);
    } else {
      this.getCurrentPosition(params);
    }
  }

  gotoDirectionsMap(params, fromLocation) {
    const { address, gotoLocation } = params;
    window.open(getDirectionsUrl(address, gotoLocation, fromLocation), '_blank');
  }

  setLatitudeAndLongitude(latitude, longitude) {
    const pos = { position: latitude + '-' + longitude };
    setCookie('position', pos);
  }
}

function mapStateToProps(state) {
  const { show, loadSource } = state.loading;
  return {
    loadSource,
    show,
  };
}

const connectedLocation = connect(mapStateToProps)(Location);
export { connectedLocation as Location };
