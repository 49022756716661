import CardIcon from '@/components/elements/CardIcon';
import { ListItem } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { getCardName, hasCardExpired } from '@/helpers';
import { __ } from '@/locale';
import { PaymentCard } from '@/types/paymentcards';

type CardItemProps = {
  card: PaymentCard | null;
  onSelect: (card: PaymentCard) => void;
};

const PaymentCardListItem: React.FC<CardItemProps> = ({ card, onSelect }) => {
  const isExpired = hasCardExpired(card.expiryMonth, card.expiryYear);
  const cardReferenceHasUpcomingBooking = !!card?.preventDisable;

  return (
    <ListItem
      underline
      leftSlot={<CardIcon card={card} size="medium" />}
      rightSlot={
        !cardReferenceHasUpcomingBooking && (
          <button
            aria-label={__('removeCard')}
            onClick={() => onSelect(card)}
            className="hover:text-danger text-black-900 flex items-center justify-center border-none outline-none">
            <Icon variant="trash" />
          </button>
        )
      }>
      <h3 className="mb-1 font-semibold">{getCardName(card.brand)}</h3>
      <p className="relative top-[-4px] flex items-center">
        ...
        {card.lastFour}.&nbsp;
        <span className={`${isExpired ? 'text-danger' : ''}`}>
          {`${isExpired ? __('expired') : __('expires')}: ${card.expiryMonth}/${card.expiryYear}`}
        </span>
      </p>
    </ListItem>
  );
};
export default PaymentCardListItem;
