/**
 * Trigger mopinion survey with surveyId
 * Mopinion is set up to trigger the survey when a global variable is set to a specific value (in this case 'triggered')
 * The surveyId var and the value is configured in mopinion
 */
export const triggerMopinionSurvey = (surveyId: string) => {
  if (surveyId && window[surveyId] !== 'triggered') {
    let counter = 0;
    const interval = setInterval(() => {
      if (window.Pastease) {
        window[surveyId] = 'triggered';
        clearInterval(interval);
      }
      counter++;
      if (counter > 5) {
        clearInterval(interval);
      }
    }, 500);
  }
};
