import { userActions } from '@/actions';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { getCookie, isIPhone, promiseWrapper, removeCookie } from '@/helpers';
import { openIosKeyboard } from '@/helpers/utilsTS';
import { _s } from '@/locale';
import { loginServices } from '@/services';
import { LoginTriggerSource } from '@/types/analytics';
import { ReactNode, createContext, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';

type EmailState = {
  value: string;
  error: boolean;
  submitting: boolean;
  isChecked: boolean;
  isRegistered: boolean;
};

type EmailAction =
  | { type: 'SET_EMAIL_VALUE'; payload: { value: string } }
  | { type: 'SET_EMAIL_ERROR'; payload: { error: boolean } }
  | { type: 'SET_EMAIL_SUBMITTING'; payload: { submitting: boolean } }
  | { type: 'SET_EMAIL_IS_MP_CHECKED'; payload: { isChecked: boolean } }
  | { type: 'SET_EMAIL_IS_REGISTERED'; payload: { isRegistered: boolean } };

const emailReducer = (state: EmailState, action: EmailAction): EmailState => {
  switch (action.type) {
    case 'SET_EMAIL_VALUE': {
      const { value } = action.payload;
      return { ...state, value };
    }
    case 'SET_EMAIL_ERROR': {
      const { error } = action.payload;
      return { ...state, error };
    }
    case 'SET_EMAIL_SUBMITTING': {
      const { submitting } = action.payload;
      return { ...state, submitting };
    }
    case 'SET_EMAIL_IS_MP_CHECKED': {
      const { isChecked } = action.payload;
      return { ...state, isChecked };
    }
    case 'SET_EMAIL_IS_REGISTERED': {
      const { isRegistered } = action.payload;
      return { ...state, isRegistered };
    }
  }
};

export type ModalView = 'requestToken' | 'verifyOtp' | 'verifySuccess';

const useLoginManager = (): {
  email: EmailState;
  view: ModalView;
  user: any;
  updateView: (view: ModalView) => void;
  handleEmailSubmit: ({
    triggerSource,
    redirectUrl,
  }: {
    triggerSource?: LoginTriggerSource;
    redirectUrl: string;
  }) => void;
  handleLogin: (code: string[]) => Promise<boolean>;
  updateEmail: (email: string) => void;
  clearLoginState: () => void;
  handleEmailResendClicked: () => void;
} => {
  const [email, dispatch] = useReducer(emailReducer, {
    value: '',
    error: false,
    submitting: false,
    isChecked: false,
    isRegistered: false,
  });
  const [view, setView] = useState<ModalView>('requestToken');
  const user = useSelector((state: any) => state.users)?.user;
  const storeDispatch = useDispatch();

  const handleEmailSubmit = async ({
    triggerSource,
    redirectUrl,
  }: {
    triggerSource: LoginTriggerSource;
    redirectUrl: string;
  }) => {
    dispatch({ type: 'SET_EMAIL_SUBMITTING', payload: { submitting: true } });
    if (email.error) return;

    if (!email.isChecked) {
      const isRegistered = await checkIsEmailRegistered();
      if (!isRegistered) return;
    }

    if (isIPhone()) {
      openIosKeyboard();
    }

    loginServices
      .requestOtp({
        email: email.value,
        triggerSource,
        redirectUrl,
      })
      .catch(() => {
        toast(
          ({ closeToast }) => (
            <Snackbar
              type="danger"
              label={_s('loginModal.error.message')}
              action={
                <button
                  onClick={() => {
                    closeToast();
                    setView('requestToken');
                  }}>
                  {_s('loginModal.error.cta')}
                </button>
              }
            />
          ),
          { autoClose: false },
        );
      });

    setView('verifyOtp');
  };

  const checkIsEmailRegistered = async () => {
    const { error } = await promiseWrapper(loginServices.isEmailRegistered(email.value));

    dispatch({ type: 'SET_EMAIL_IS_MP_CHECKED', payload: { isChecked: true } });
    dispatch({ type: 'SET_EMAIL_IS_REGISTERED', payload: { isRegistered: !error } });

    if (error) {
      dispatch({ type: 'SET_EMAIL_SUBMITTING', payload: { submitting: false } });
      return false;
    }

    return true;
  };

  const handleEmailResendClicked = () => {
    dispatch({ type: 'SET_EMAIL_SUBMITTING', payload: { submitting: false } });
    setView('requestToken');
  };

  const handleLogin = async (code: string[]): Promise<boolean> => {
    const { error } = await promiseWrapper(loginServices.login({ email: email.value, code: code.join('') }));
    const token = getCookie('BokadirektMP');
    if (error || !token) return false;
    removeCookie('BokadirektMP');
    storeDispatch(userActions.profile(token, true, false));
    return true;
  };

  const updateEmail = (_email: string) => {
    dispatch({ type: 'SET_EMAIL_VALUE', payload: { value: _email } });
    dispatch({ type: 'SET_EMAIL_SUBMITTING', payload: { submitting: false } });
    dispatch({ type: 'SET_EMAIL_IS_MP_CHECKED', payload: { isChecked: false } });
  };

  const updateView = (view: ModalView) => {
    if (view === 'verifyOtp' && email.error) setView('requestToken');
    setView(view);
  };

  const clearLoginState = () => {
    dispatch({ type: 'SET_EMAIL_VALUE', payload: { value: '' } });
    dispatch({ type: 'SET_EMAIL_ERROR', payload: { error: false } });
    dispatch({ type: 'SET_EMAIL_SUBMITTING', payload: { submitting: false } });
    dispatch({ type: 'SET_EMAIL_IS_MP_CHECKED', payload: { isChecked: false } });
    setView('requestToken');
  };

  useEffect(() => {
    dispatch({ type: 'SET_EMAIL_ERROR', payload: { error: !isEmail(email.value) } });
  }, [email.value]);

  return {
    email,
    view,
    user,
    updateEmail,
    updateView,
    handleEmailSubmit,
    handleLogin,
    clearLoginState,
    handleEmailResendClicked,
  };
};

export type LoginContextType = ReturnType<typeof useLoginManager>;

export const LoginContext = createContext<LoginContextType>({
  email: {
    value: '',
    error: false,
    submitting: false,
    isChecked: false,
    isRegistered: false,
  },
  view: 'requestToken',
  updateView: () => {},
  handleEmailSubmit: () => {},
  handleLogin: () => Promise.resolve(false),
  updateEmail: () => {},
  clearLoginState: () => {},
  handleEmailResendClicked: () => {},
  user: {},
});

export const LoginProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <LoginContext.Provider value={useLoginManager()}>{children}</LoginContext.Provider>;
};
