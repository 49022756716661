import { userActions } from '@/actions';
import Input from '@/components/elements/forms/Input/Input';
import { Button } from '@/components/elements/forms/buttons';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Divider from '@/components/elements/lists/Divider/Divider';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import EmptyState from '@/components/templates/EmptyState';
import { promiseWrapper, trackMpEvent } from '@/helpers';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { userService } from '@/services';
import { handleLoginClick, handleLogoutClick } from '@/services/navigationServices';
import { updateProfileResponseSchema } from '@/types/api/services/user';
import { NavItemIdentifier } from '@/types/navigation';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const baseTranslationKey = 'account-settings';

const DeletaAccountModal = ({
  isOpen,
  handleClick,
  handleClose,
  confirmEmail,
  submitting,
}: {
  isOpen: boolean;
  handleClick: () => void;
  handleClose: () => void;
  confirmEmail: string;
  submitting: boolean;
}) => {
  const [email, setEmail] = useState('');
  const { isMobileView } = useMobileView();
  const translationKey = `${baseTranslationKey}.delete-account-modal`;

  return (
    <Modal isOpen={isOpen}>
      <Modal.Content floating={!isMobileView}>
        <Modal.Header title={_s(`${translationKey}.title`)} />
        <div className={`gap-sm flex flex-col ${isMobileView ? 'px-lg' : ''}`}>
          <div className="gap-sm flex">
            <div className="flex-shrink-0" style={{ width: 24, height: 24 }}>
              <Icon variant="warning" color="danger-500" />
            </div>
            <p className="text-danger">{_s(`${translationKey}.warning`)}</p>
          </div>
          <Input
            title={_s(`${translationKey}.input-title`, { email: confirmEmail })}
            placeholder=""
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => null}
            value={email}
          />
        </div>
        <Modal.Footer>
          <Button
            variant="link"
            size="sm"
            loading={submitting}
            className="text-danger"
            onClick={handleClick}
            disabled={email !== confirmEmail}>
            {_s(`${translationKey}.delete-button`)}
          </Button>
          <Button variant="primary" size="sm" onClick={handleClose}>
            {_s(`${translationKey}.cancel-button`)}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

const LogoutModal = ({
  isOpen,
  handleClick,
  handleClose,
}: {
  isOpen: boolean;
  handleClick: () => void;
  handleClose: () => void;
}) => {
  const { isMobileView } = useMobileView();
  const translationKey = `${baseTranslationKey}.logout-modal`;
  return (
    <Modal isOpen={isOpen}>
      <Modal.Content floating={!isMobileView}>
        <Modal.Header title={_s(`${translationKey}.title`)} />
        <div className={`gap-sm flex flex-col ${isMobileView ? 'px-lg' : ''}`}>
          <p className="text-black-600">{_s(`${translationKey}.body`)}</p>
        </div>
        <Modal.Footer>
          <Button variant="link" size="sm" onClick={handleClick}>
            {_s(`${translationKey}.logout-button`)}
          </Button>
          <Button variant="primary" size="sm" onClick={handleClose}>
            {_s(`${translationKey}.cancel-button`)}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

const AccountSettings = () => {
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const user = useAppSelector((state) => state.users?.user);
  const forceLoginCheckbox = user?.about?.accountProtected ?? false;
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);
  const appDispatch = useDispatch();

  useEffect(() => {
    // trackMpEvent('screen_shown', { screen_name: 'account_settings' });
  }, []);

  const handleLogout = () => handleLogoutClick(appDispatch, NavItemIdentifier.Logout, 'account_settings');

  const handleDeleteAccount = () => {
    setDeleteUserLoading(true);
    userService
      .deleteProfile()
      .then(() => {
        toast(({ closeToast }) => (
          <Snackbar
            type="success"
            label={_s(`${baseTranslationKey}.snackbar.delete-account.success`)}
            onClose={closeToast}
          />
        ));
        appDispatch(userActions.deleteUser());
        setDeleteUserLoading(false);
        setShowDeleteAccountModal(false);
        setRedirectToHome(true);
      })
      .catch((error) => {
        toast(({ closeToast }) => (
          <Snackbar
            type="danger"
            label={_s(`${baseTranslationKey}.snackbar.delete-account.error`, { error })}
            onClose={closeToast}
          />
        ));
        setDeleteUserLoading(false);
        setShowDeleteAccountModal(false);
      });
  };

  const handleAccountProtectedChange = async () => {
    const { data } = await promiseWrapper(
      userService.updateProfile({ about: { accountProtected: !forceLoginCheckbox } }),
    );

    const validatedResponse = updateProfileResponseSchema.safeParse(data);

    if (!validatedResponse.success) {
      toast(({ closeToast }) => (
        <Snackbar type="danger" label={_s(`${baseTranslationKey}.forceLogin.error`)} onClose={closeToast} />
      ));
      return;
    }

    toast(({ closeToast }) => (
      <Snackbar
        type="success"
        label={_s(
          `${baseTranslationKey}.forceLogin.${!forceLoginCheckbox ? 'successForceLogin' : 'successNotForceLogin'}`,
        )}
        onClose={closeToast}
      />
    ));
    appDispatch(userActions.updateUser({ ...user, ...validatedResponse.data }));
  };

  if (redirectToHome) return (window.location.href = '/');

  return (
    <PageViewLayout type="subView" wrapperClass="bg-gradient" back title={_s(`${baseTranslationKey}.title`)}>
      <div className="flex flex-col items-center justify-center md:min-h-[calc(100vh-64px)]">
        <LogoutModal
          isOpen={showLogoutModal}
          handleClick={handleLogout}
          handleClose={() => setShowLogoutModal(false)}
        />
        <DeletaAccountModal
          isOpen={showDeleteAccountModal}
          handleClick={handleDeleteAccount}
          handleClose={() => setShowDeleteAccountModal(false)}
          confirmEmail={user?.contact?.email || ''}
          submitting={deleteUserLoading}
        />
        <div className="container md:w-[390px]">
          {!user ? (
            <EmptyState
              icon={<img src="/images/illustrations/settings2.svg" alt="" />}
              title={_s(`${baseTranslationKey}.empty-state.title`)}
              cta={
                <Button
                  size="sm"
                  onClick={() => handleLoginClick(appDispatch, NavItemIdentifier.LogIn, 'account_settings')}>
                  {_s(`${baseTranslationKey}.empty-state.cta`)}
                </Button>
              }
              body={_s(`${baseTranslationKey}.empty-state.body`)}
            />
          ) : (
            <div className="pt-xxl">
              <Divider label="Inloggning" size="md" />
              <ListItem
                verticalAlign="top"
                onClick={handleAccountProtectedChange}
                leftSlot={<Icon variant={forceLoginCheckbox ? 'checkbox-checked' : 'checkbox-unchecked'} />}>
                <ListItemContent
                  subTitle={<span className="text-black-900">{_s(`${baseTranslationKey}.forceLogin.label`)}</span>}
                />
              </ListItem>

              <div className="px-lg py-lg">
                <Button block size="md" onClick={() => setShowLogoutModal(true)}>
                  {_s('logOut')}
                </Button>
              </div>
              <Divider label="Danger zone" size="md" />
              <div className="px-lg py-lg">
                <Button
                  block
                  variant="link"
                  size="md"
                  className="!text-danger !no-underline	"
                  leftIcon={<Icon variant="trash-alt" color="danger-500" />}
                  onClick={() => setShowDeleteAccountModal(true)}>
                  {_s('deleteAccount')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageViewLayout>
  );
};

export default AccountSettings;
