import { bookActions } from '@/actions';
import Tag from '@/components/elements/Tag';
import { Button, LinkButton } from '@/components/elements/forms/buttons';
import {
  getEmployeesWhoPerformServices,
  getPlaceTimezone,
  getTotalPrice,
  isServer,
  makeReadableDayShort,
  shouldPickEmployee,
  shouldShowFrom,
  trackMpEvent,
  url,
} from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CampaignService from './CampaignService';

class Campaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceListId: this.getPriceId(Object.keys(props.campaignServices)),
    };
  }

  initiateBookingProcess() {
    const { dispatch, place, services, time, employee, campaignServices } = this.props;
    dispatch(bookActions.removeEmployee());
    dispatch(bookActions.clearBook());
    dispatch(bookActions.addPlace(place));
    dispatch(
      bookActions.appendService(
        Object.keys(campaignServices).map((id) => campaignServices[id]),
        place,
        time && time.timestamp,
        services,
        employee,
        false,
      ),
    );
  }

  trackClick() {
    const { source, place } = this.props;
    const screenName = source ? source : 'company_details';
    let eventName = screenName === 'company_details' ? 'choose_campaign_clicked' : 'campaign_selected';
    if (!this.isPackage()) {
      eventName = screenName === 'company_details' ? 'choose_service_clicked' : 'service_selected';
    }

    const eventProps = {
      screen_name: screenName,
      company_id: place.id,
    };

    if (!this.isPackage()) {
      eventProps.campaign = 'yes';
    }

    trackMpEvent(eventName, eventProps);
  }

  goToAppointment = (e) => {
    const { dispatch, fromEmployee, append, services, time, employee, place, campaignServices } = this.props;

    const service = this.props.service ? this.props.service : campaignServices[Object.keys(campaignServices)[0]];

    const recommendedService = url.returnGetParameter('rs');
    const employeeService = url.returnGetParameter('ss');
    const employeePicked = url.returnGetParameter('sr');

    this.trackClick();

    if (append) {
      dispatch(
        bookActions.appendService(
          Object.keys(campaignServices).map((id) => campaignServices[id]),
          place,
          time && time.timestamp,
          services,
          employee,
        ),
      );

      if (this.props.hidePopupCallback) {
        this.props.hidePopupCallback();
      }

      return;
    }

    const shouldPickAddOn =
      !recommendedService &&
      service &&
      service.extra &&
      service.extra.addOnServices &&
      service.extra.addOnServices.length;

    if (!recommendedService) {
      this.initiateBookingProcess();
    }

    if (fromEmployee) {
      dispatch(bookActions.pickEmployee(fromEmployee.id, fromEmployee.about.priceListId));
      dispatch(bookActions.keepEmployee());
    }

    bookActions.clearTrackingProps();

    if (
      !shouldPickAddOn &&
      shouldPickEmployee(this.props) &&
      !isServer &&
      !employeePicked &&
      (!employeeService || parseInt(employeeService, 10) !== service.id)
    ) {
      this.props.history.push({ search: url.changeGetParameter('ss', service.id) });
      return;
    }

    if (shouldPickAddOn) {
      this.props.history.push({ search: url.changeGetParameter('rs', service.id) });
    } else {
      // this.props.history.replace({search: url.changeGetParameter('rs')})
      this.props.history.push({
        pathname:
          '/boka-tjanst/' +
          (place.about.slug ? place.about.slug + '-' : '') +
          place.id +
          '/' +
          (service.about.slug ? service.about.slug + '-' : '') +
          service.id,
        state: { fromLanding: true },
      });
    }
  };

  getPriceId(campaignServices) {
    const { fromEmployee, place } = this.props;
    let priceListId = null;
    if (fromEmployee && fromEmployee.about) {
      priceListId = fromEmployee.about.priceListId;
    } else {
      const performing = getEmployeesWhoPerformServices(
        place.employees,
        campaignServices.map((service) => service.id),
      );
      if (performing.length === 1 && performing[0].about) {
        priceListId = performing[0].about.priceListId;
      }
    }

    return priceListId;
  }

  isPackage() {
    const { campaign = {}, campaignServices } = this.props;
    return campaign.type !== 6 && campaignServices.length > 1;
  }

  render() {
    const { campaign = {}, place = {}, campaignServices } = this.props;
    const { priceListId } = this.state;

    if (!campaignServices) {
      return null;
    }

    let campaignItems = [],
      services = [];
    Object.keys(campaignServices).forEach((id) => {
      services.push(campaignServices[id]);
      campaign.services.forEach((item) => {
        if (parseInt(item.id, 10) === parseInt(id, 10)) {
          campaignItems.push(item);
        }
      });
    });

    const isPackage = this.isPackage();
    const readMoreButton = (
      <div className="modal-actionbar stick-to-bottom">
        <Button onClick={this.goToAppointment}>{__('book')}</Button>
      </div>
    );

    const servicesHtml = campaignItems.map((service, index) => (
      <CampaignService
        key={index}
        campaignService={service}
        service={campaignServices[service.id]}
        place={place}
        priceListId={priceListId}
        isPackage={isPackage}
        readMoreButton={readMoreButton}
      />
    ));

    const endDate = makeReadableDayShort(campaign.endDate * 1000, getPlaceTimezone(place));
    let packagePriceHtml = null;
    if (isPackage) {
      const ordPrice = getTotalPrice(services, place, priceListId, {}, 0, 1, true);
      const offerPrice = getTotalPrice(services, place, priceListId, {}, 0, 1, true, [campaign]);
      const isPriceVariable =
        shouldShowFrom(services, place) && offerPrice !== __('variablePrice') && offerPrice !== __('freePrice')
          ? __('from') + ' '
          : '';

      packagePriceHtml = (
        <div className="package-price">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{__('packagePrice')}</span>
            <span className="text-information text-sm">{__('appliedUntil') + endDate}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="offer-price">{isPriceVariable + offerPrice}</span>
            <span className="ord-price">{ordPrice !== offerPrice ? 'ord. ' + ordPrice : ''}</span>
          </div>
        </div>
      );
    }

    return (
      <li
        className="!last-of-type:border-none hover:bg-black-50 !flex cursor-pointer items-start justify-between p-[10px]"
        onClick={this.goToAppointment}>
        <div className="flex flex-col">
          <div className="mb-xs">
            <Tag size="large" variant="campaign">
              {isPackage ? __('packageOffer') : __('campaignOffer')}
            </Tag>
          </div>
          <div className={isPackage ? 'border-information border-l-[1px] border-solid pl-4' : ''}>
            {servicesHtml}
            {!isPackage && <span className="text-information text-sm">{__('appliedUntil') + endDate}</span>}
          </div>
        </div>
        <LinkButton
          variant="primary"
          title={__('book') + ' ' + campaign.name}
          to={
            '/boka-tjanst/' +
            (place.about.slug ? place.about.slug + '-' : '') +
            place.id +
            '/' +
            (services[0].about.slug ? services[0].about.slug + '-' : '') +
            services[0].id
          }
          onClick={(e) => {
            e.preventDefault();
          }}>
          {__('book')}
        </LinkButton>
        {packagePriceHtml}
      </li>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.users;
  const { services, time, employee } = state.book;

  return { user, services, time, employee };
}

const CampaignC = connect(mapStateToProps)(Campaign);
export default withRouter(CampaignC);
