import { LoginTriggerSource } from '@/types/analytics';
import { modalConstants } from '../constants';

export const modalActions = {
  redirect,
  hideModal,
  login,
  userProfileOnboarding,
  userLocationOnboarding,
};

function hideModal() {
  window.location.hash = '';
  return { type: modalConstants.HIDE, data: {} };
}

function login({ show, triggerSource }: { show: boolean; triggerSource?: LoginTriggerSource }) {
  return { type: modalConstants.LOGIN, data: { show, triggerSource } };
}

function userProfileOnboarding({ show }: { show: boolean }) {
  return { type: modalConstants.USER_PROFILE_ONBOARDING, data: { show } };
}

function userLocationOnboarding({ show }: { show: boolean }) {
  return { type: modalConstants.USER_LOCATION_ONBOARDING, data: { show } };
}

function redirect(type, url) {
  function red(url) {
    return { type: modalConstants.REDIRECT, data: url };
  }

  return (dispatch) => {
    dispatch(red(url));
    dispatch(hideModal());
  };
}
