import Label, { LabelProps } from '@/components/elements/Label/Label';
import { ListItem } from '@/components/elements/lists';
import { ListItemProps } from '@/components/elements/lists/ListItem/ListItem';
import { CompanyAvatar } from '@/components/elements/redesign/Avatar';
import Icon from '@/components/icons/Icon';
import { _s } from '@/locale';
import { AppointmentStatus } from '@/types/appointment';

type Image = {
  src: string;
  alt?: string;
};

type BookingListContentProps = {
  image?: Image;
  placeName?: string;
  dateTime?: string;
  performer?: string;
  services?: string;
  label?: LabelProps;
};

export type BookingListItemProps = BookingListContentProps & {
  onClick?: () => void;
  src?: ListItemProps['to'];
  status?: AppointmentStatus;
} & Pick<ListItemProps, 'leftPadding' | 'rightPadding' | 'underline'>;

const bookingStatusLabelMap: {
  [key in AppointmentStatus]?: { label: string; className: string };
} = {
  1: { label: _s('booked'), className: 'bg-brand-700 text-white' },
  101: { label: _s('confirmed'), className: 'bg-black-100 text-black-900' },
  2: { label: _s('canceled'), className: 'bg-danger-700 text-white' },
  102: { label: _s('canceled'), className: 'bg-danger-700 text-white' },
};

const BookingListItem = (props: BookingListItemProps) => {
  const statusLabel = bookingStatusLabelMap[props.status] || null;

  return (
    <ListItem
      leftSlot={<CompanyAvatar alt={props.image?.alt ?? ''} size="md" imgUrl={props.image?.src ?? ''} />}
      onClick={props.onClick}
      verticalAlign="top"
      to={props.src}
      underline={props.underline}
      leftPadding={props.leftPadding}
      rightPadding={props.rightPadding}
      rightSlot={<Icon variant="chevron-s-right" />}>
      <BookingListContent {...props} label={props.label ?? statusLabel} />
    </ListItem>
  );
};

const BookingListContent = ({ placeName, performer, services, dateTime, label }: BookingListContentProps) => {
  return (
    <div className="space-y-xs">
      {label && <Label {...label} />}
      {placeName && <p className="text-black-900 text-md">{placeName}</p>}
      {dateTime && <p className="text-black-600 text-xs">{dateTime}</p>}
      {performer && <p className="text-black-900">{performer}</p>}
      {services && <p className="text-black-600">{services}</p>}
      {}
    </div>
  );
};

export default BookingListItem;
