/**
 * @readonly
 * @enum {string}
 */
export const ShippingMethod = Object.freeze({
  DIGITAL: 'digital',
  SHIPPING: 'shipping',
});

export const GIFTCARD_DEFAULT_VALUES = [1500, 2000, 4000];
export const GIFTCARD_DEFAULT_VALUE_SELECTED = GIFTCARD_DEFAULT_VALUES[1];

export const WELLNESS_DEFAULT_VALUES = [2000, 3500, 5000];
export const WELLNESS_DEFAULT_VALUE_SELECTED = WELLNESS_DEFAULT_VALUES[1];

export const MIN_AMT_DIGITAL = 100;
export const MIN_AMT_SHIPPING = 600;
