import { blogActions } from '@/actions';
import { h3Class, XS } from '@/components/elements/content';
import { LinkButton } from '@/components/elements/forms/buttons';
import { ArrowIcon, ArrowLongIcon } from '@/components/icons';
import { blog, classnames, isMobile, useBlogData } from '@/helpers';
import { _s } from '@/locale';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingPosts from './LoadingPosts';

const base = 'blogPage.latestPost';

const DCFeaturedPost = ({ post: ssrPost }) => {
  const fetchPost = () => dispatch(blogActions.featured());
  const post = useBlogData(ssrPost, fetchPost);
  const dispatch = useDispatch();

  if (!post) return <LoadingPosts />;

  const postLink = `/blogg/p/${post.slug}-${post.id}`;

  return (
    <>
      <h2 className={classnames(h3Class, 'text-secondary-500 !my-10')}>{_s(`${base}.title`)}</h2>
      <img
        src={blog.getImage(post.featured_media.source_url, isMobile() ? '375x' : '1200x')}
        alt={post.featured_media.alt_text}
        className="aspect-[12/6] w-full object-cover object-center"
      />
      <div className="bg-secondary-50 px-8 py-4 md:px-10 md:py-8">
        <XS className="text-secondary-500 text-xs">{blog.getPostDate(post)} </XS>
        <Link to={postLink} className="my-3 block">
          <span
            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
            className={`md:text-secondary-500 text-black-900 text-lg font-semibold md:text-3xl md:font-normal`}></span>
        </Link>
        <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} className={'md:text-secondary-500'}></p>
        <div className="mt-10 flex justify-end">
          <div className="hidden md:block">
            <LinkButton variant="secondary" to={postLink}>
              {_s('blogPage.readPost')}
              <ArrowIcon className="ml-2 h-4 w-4" />
            </LinkButton>
          </div>
          <div className="md:hidden">
            <Link
              to={postLink}
              className="text-secondary-500 hover:text-secondary-500 inline-flex items-center justify-start">
              {_s('blogPage.readWholePost')}
              <ArrowLongIcon className="ml-2 w-8" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ post: state.blog.featured });

const FeaturedPost = connect(mapStateToProps)(DCFeaturedPost);
export default FeaturedPost;
