import { threeDSConfig } from '@/config/adyen';
import AdyenCheckout from '@adyen/adyen-web';
import { useEffect, useRef, useState } from 'react';

export type UseThreeDSProps = {
  action: any;
  handleOnAdditionalDetails: (state: any) => void;
};

const useThreeDS = ({ action, handleOnAdditionalDetails }: UseThreeDSProps) => {
  const [checkout, setCheckout] = useState(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    const initThreeDS = async () => {
      const configuration = {
        ...threeDSConfig,
        onAdditionalDetails: handleOnAdditionalDetails,
      };

      const checkout = await AdyenCheckout(configuration);
      setCheckout(checkout);
    };

    initThreeDS();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!checkout || !isMounted.current) {
      return;
    }
    checkout.createFromAction(action, { challengeWindowSize: '05' })?.mount(componentRef?.current);

    return () => {
      if (checkout) checkout.unmount();
    };
  }, [checkout]);

  return { componentRef };
};

export default useThreeDS;
