import { Button, LinkButton } from '@/components/elements/forms/buttons';
import { isServer, setCookiePreferences, isGoogleCrawler } from '@/helpers';
import { __, _s } from '@/locale';
import { MouseEvent, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import Modal from './redesign/Modal/Modal';

const handleClickModal = (e: MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
  e.preventDefault();
};

const AllowCookies = () => {
  const [show, setShow] = useState(true);
  if (isServer) return null;
  if (window.location.pathname === '/articles/cookies') return null;
  if (isGoogleCrawler()) return null;

  const acceptCookie = () => {
    setCookiePreferences();
    setShow(false);
    window.location.reload();
  };

  return (
    <Modal isOpen={show} shouldCloseOnOverlayClick={false}>
      <Modal.Content floating>
        <Modal.Header title={_s('allowCookiesTitle')} />
        <div className="flex flex-col" onClick={handleClickModal}>
          <div className="pb-xxl text-md">
            <span className="text-black-600 whitespace-pre-wrap">{_s('allowCookiesContent')}&nbsp;</span>
            <Link
              to={{ pathname: '/articles/cookies', state: { fromAllowCookiesPopup: window.location.href } }}
              className="underline">
              {_s('readMore')}
              <span className="sr-only"> {_s('aboutOurCookiePolicy')}</span>
            </Link>
          </div>
          <div className="space-y-lg flex flex-col">
            <Button size="md" onClick={acceptCookie}>
              {_s('allowCookiesButton')}
            </Button>
            <LinkButton
              size="md"
              to={{ pathname: '/articles/cookies', state: { fromAllowCookiesPopup: window.location.href } }}
              style={{ textAlign: 'center' }}>
              {__('customise')}
            </LinkButton>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(AllowCookies);
