import Header from '@/components/elements/Header/Header';
import { Button } from '@/components/elements/forms/buttons';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Icon from '@/components/icons/Icon';
import { EVENT_NAME, SCREEN_NAME } from '@/constants/analytics';
import { trackMpEvent } from '@/helpers';
import { LoginContextType } from '@/hooks/useLogin';
import { _s } from '@/locale';
import { LoginTriggerSource } from '@/types/analytics';
import { useLocation } from 'react-router-dom';

const baseTranslationKey = `components.templates.checkout.UserLogin`;

type UserLoginProps = Omit<
  LoginContextType,
  'handleLogin' | 'updateView' | 'view' | 'user' | 'clearLoginState' | 'handleEmailResendClicked'
> & { triggerSource?: LoginTriggerSource; continueAsGuest?: () => void };

const UserLogin = ({ email, updateEmail, handleEmailSubmit, triggerSource, continueAsGuest }: UserLoginProps) => {
  const { pathname } = useLocation();
  const allowsContinueAsGuest = continueAsGuest !== undefined;
  const titleTranslation = _s(`${baseTranslationKey}.title${allowsContinueAsGuest ? 'AsGuest' : ''}`);

  const handleLoginClick = () => {
    trackMpEvent(EVENT_NAME.LOGIN_CONTINUE_CLICK, { screen_name: SCREEN_NAME.LOGIN_START });
    handleEmailSubmit({ triggerSource: triggerSource, redirectUrl: pathname });
  };

  const handleContinueAsGuestClick = () => {
    trackMpEvent(EVENT_NAME.LOGIN_CONTINUE_AS_GUEST_CLICK, { screen_name: SCREEN_NAME.LOGIN_START });
    continueAsGuest();
  };

  return (
    <div className="gap-md px-lg flex flex-col">
      <div className="py-md">
        <Header size="md" label={titleTranslation} />
      </div>
      <div className="gap-md flex flex-col">
        <ListInput
          id="email"
          type="email"
          value={email.value}
          label={_s(`${baseTranslationKey}.input.label`)}
          placeholder="namn@exempel.com"
          leftIcon={<Icon variant="email" />}
          onChange={(e) => updateEmail(e.target.value)}
          error={email.error && email.submitting ? _s(`RequestToken.input.error`) : undefined}
        />
        {email.isChecked && !email.isRegistered && (
          <Alert
            leftSlot={<Icon size="sm" variant="info-circle" />}
            variant="information"
            verticalAlign="top"
            rightSlot={
              <p>
                {_s(`RequestToken.newEmailAccount.notFound`)} <strong>{email.value}</strong>{' '}
                {_s(`RequestToken.newEmailAccount.continue`, { cta: _s(`RequestToken.cta`) })}
              </p>
            }
          />
        )}
        <Button
          disabled={email.error || email.submitting}
          label={_s(`${baseTranslationKey}.loginCta${allowsContinueAsGuest ? 'AsGuest' : ''}`)}
          onClick={handleLoginClick}
          size="md"
          variant="primary"
        />
        {allowsContinueAsGuest && (
          <Button
            label={_s(`${baseTranslationKey}.continueAsGuest`)}
            onClick={handleContinueAsGuestClick}
            size="sm"
            variant="link"
          />
        )}
        <p className="text-black-600 text-xs">
          {_s(`RequestToken.terms.text`)}
          <br />
          <a href="/articles/terms-booking-consumer" target="_blank" className="underline">
            {_s(`RequestToken.terms.link`)}
          </a>
        </p>
      </div>
    </div>
  );
};

export default UserLogin;
