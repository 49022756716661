import { loadingConstants } from '../constants';

export const loadingActions = {
  show,
  hide,
};

function show(data) {
  return { type: loadingConstants.SHOW, data };
}

function hide(data) {
  return { type: loadingConstants.HIDE, data };
}
