import CheckoutTerms from '@/components/modules/checkout/CheckoutTerms/CheckoutTerms';
import { classnames } from '@/helpers';
import { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

const durationMS = 125;

const styles = `
.slide-enter { transform: translateY(100%) }
.slide-enter-active { transform: translateY(0); transition: all ${durationMS}ms linear }
.slide-exit { transform: translateY(0) }
.slide-exit-active { transform: translateY(100%); transition: transform ${durationMS}ms linear }
`;

const FloatingCTAWrapper = ({
  show,
  children,
  description,
  linkLabel,
  termsPageId,
  onCloseTerms,
}: {
  show: boolean;
  children: ReactNode;
  description: string;
  linkLabel: string;
  termsPageId: number;
  onCloseTerms: () => void;
}) => {
  return (
    <>
      <style>{styles}</style>
      <CSSTransition
        in={show}
        timeout={durationMS}
        classNames={{
          enter: 'slide-enter',
          enterActive: 'slide-enter-active',
          exit: 'slide-exit',
          exitActive: 'slide-exit-active',
        }}
        unmountOnExit>
        <div className="shadow-bottom-sm fixed bottom-0 left-0 right-0 bg-white">
          <div className={classnames('py-lg px-lg space-x-md container flex flex-row items-start justify-between')}>
            <CheckoutTerms
              description={description}
              linkLabel={linkLabel}
              termsPageId={termsPageId}
              className="max-w-sm text-left"
              onClose={onCloseTerms}
              triggerSource="checkout_booking_summary"
            />
            {children}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default FloatingCTAWrapper;
