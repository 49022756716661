import { modalActions, searchActions } from '@/actions';
import { filterEmptyValues, isServer, removeLabels, trackMpEvent, url, setLocationInCookies } from '@/helpers';
import { __ } from '@/locale';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from './Autosuggest/DateTime';

class SearchFunctions extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let {
      q = '',
      location = '',
      prefs,
      page,
      startDate = null,
      endDate = null,
      timeOfDay,
      sort,
      lat,
      lon,
      wellness = null,
    } = url.getUrlParameters(props);

    if (!startDate || !endDate) {
      startDate = moment().format(DATE_FORMAT);
      endDate = moment().add(1, 'M').format(DATE_FORMAT);
    }

    if (props.source === 'home') {
      this.props.dispatch(searchActions.removeParameter(['q', 'page']));
      q = '';
      page = 0;
    }

    page = parseInt(page, 10);
    this.state = {
      q: q,
      location: location,
      position: (props.search && props.search.position) || '',
      locationId: (props.search && props.search.locationId) || '',
      prefs: prefs,
      startDate,
      endDate,
      timeOfDay,
      page: !isNaN(page) ? page : (props.search && props.search.page) || 0,
      sort: sort ? sort : (props.search && props.search.sort) || undefined,
      lat,
      lon,
      wellness,
    };
  }

  parseUrl() {
    const { dispatch } = this.props;
    const newState = { ...this.state };

    dispatch(searchActions.setParameter(filterEmptyValues(newState)));
    if (this.props.source === 'list') {
      const search = removeLabels(filterEmptyValues(newState));
      this.changeUrl(search.q, search.location, false);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.parseUrl();
    if (this.searchInput) this.searchInput.focus();
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { source } = this.props;
    if (source === 'mobile' || source === 'home') {
      const { dispatch } = this.props;
      dispatch(searchActions.setParameter({ q: this.state.q, location: this.state.location }));
    }
  }

  componentDidUpdate(prevProps) {
    let {
      q = '',
      location = '',
      prefs = '',
      page,
      timeOfDay,
      startDate,
      endDate,
      sort,
      lat,
      lon,
      wellness,
    } = url.getUrlParameters(this.props);

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState(
        {
          q: q && q.toLowerCase() !== 'vad' ? q : '',
          location: location && location.toLowerCase() !== 'var' ? location : '',
        },
        () => {
          this.gotosearch();
        },
      );
    }
    if (this.state.locationId !== this.props.search.locationId || this.state.position !== this.props.search.position) {
      this.setState({ locationId: this.props.search.locationId, position: this.props.search.position });
    }
    if (page !== this.state.page) {
      this.setState({ page });
    }
    if (prefs !== this.state.prefs) {
      this.setState({ prefs });
    }
    if (startDate !== this.state.startDate) {
      this.setState({ startDate });
    }
    if (endDate !== this.state.endDate) {
      this.setState({ endDate });
    }
    if (timeOfDay !== this.state.timeOfDay) {
      this.setState({ timeOfDay });
    }

    if (sort !== this.state.sort) this.setState({ sort });
    if (lon !== this.state.lon || lat !== this.state.lat) this.setState({ lon, lat });
    if (wellness !== this.state.wellness) this.setState({ wellness });
  }

  gotosearch() {
    const { dispatch } = this.props;

    const param = { ...this.state };
    if (this.state.location) {
      param.bounds = undefined;
    }

    // reset page count
    param.page = 0;
    param.shouldUpdate = true;
    dispatch(searchActions.setParameter(param));
    const currentState = removeLabels(filterEmptyValues(param));
    this.changeUrl(currentState.q, currentState.location, true, true);
  }

  changeUrl = (vad = 'vad', where = 'var', resetPage = true, isSearch = false) => {
    const { source } = this.props;

    // This urls should be decoded here
    // fix only %
    vad = vad || 'vad';
    where = where || 'vad';
    vad = vad.split('%25').join('%').split('%').join('%25');
    where = where.split('%25').join('%').split('%').join('%25');
    let locationSearch = !isServer ? window.location.search.replace('?', '') : undefined;

    if (resetPage || !this.props.search.page) {
      locationSearch = url.changePage(0);
    }

    isSearch
      ? this.props.history.push(
          `/${encodeURIComponent(vad)}/${encodeURIComponent(where)}${locationSearch ? '?' + locationSearch : ''}`,
          { fromHome: source === 'home' },
        )
      : this.props.history.replace(
          `/${encodeURIComponent(vad)}/${encodeURIComponent(where)}${locationSearch ? '?' + locationSearch : ''}`,
        );
  };

  showSearchForm = (e) => {
    this.props.history.push('/search' + this.props.location.search);
  };

  handleClick = (name) => (e) => {
    e.preventDefault();
    const { pathname } = this.props.location;

    if (name === 'search-button-clicked') {
      trackMpEvent('search_button_clicked', {
        screen_name: 'search_autocomplete',
        search_type: 'button',
      });
    }

    return false;
  };

  clickSearch = (e) => {
    return this.makeSearch(e);
  };

  onSubmit = (e) => {
    e.preventDefault();
    return this.makeSearch(e);
  };

  makeSearch(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const { dispatch, source } = this.props;

    if (source && source !== 'list' && source !== 'mobile') {
      const search = removeLabels(filterEmptyValues(this.state));
      search.page = 0;
      search.shouldUpdate = true;

      dispatch(searchActions.setParameter(search));
      this.gotoSearchAndChangeSorter(true);
    } else {
      this.gotoSearchAndChangeSorter(false);
    }
  }

  gotoSearchAndChangeSorter = async (isHome) => {
    const { dispatch } = this.props;
    const search = removeLabels(filterEmptyValues(this.state));
    if (search?.position?.includes('-') && !this.state?.locationId) {
      const pos = search?.position?.split('-');
      if (pos && pos?.length === 2) {
        const positionParams = { lat: pos[0], lon: pos[1] };

        this.setState({ sort: 'closest' });
        const p = { ...positionParams, sort: 'closest', changedDefaultSorting: true };

        const newSearchQuery = url.addSortingFiltersWithValue(p);
        this.props.history.push({ search: newSearchQuery });
        await dispatch(searchActions.setParameter({ ...p, shouldUpdate: true }));

        this.gotosearch();
        return;
      }
    } else if (this?.props?.search?.changedDefaultSorting) {
      //reset
      const p = {
        ...this?.props?.search,
        sort: 'popular',
        changedDefaultSorting: false,
        position: undefined,
        lat: undefined,
        lon: undefined,
      };
      const newSearchQuery = url.addSortingFiltersWithValue(p);

      this.props.history.push({ search: newSearchQuery });
      this.setState({ sort: 'popular', position: undefined, lat: undefined, lon: undefined });
      await dispatch(searchActions.setParameter({ ...p, shouldUpdate: true }));

      this.gotosearch();
      return;
    }
    if (isHome) {
      this.changeUrl(search.q, search.location, true, true);
    } else {
      this.gotosearch();
    }
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      trackMpEvent('search_button_clicked', {
        screen_name: 'search_autocomplete',
        search_type: 'keyboard',
      });
      return;
    }
  };

  updateLocation = (params, skipSearch = false) => {
    params = { ...params, locationId: params?.locationId || undefined, position: params?.position || '' };
    this.setState({ ...params }, async () => {
      if (!skipSearch) {
        setLocationInCookies(params);
        await this.props.dispatch(searchActions.setParameter(params));
        if (!this.props.isMobileView && this.props.source === 'list') {
          this.gotoSearchAndChangeSorter(false);
        }
      }
    });
  };

  handleRequestUserLocationOnbarding = (makeSearch = false) => {
    const { onRequestUserLocationPermission } = this.props;
    this.props.dispatch(modalActions.userLocationOnboarding({ show: false }));
    onRequestUserLocationPermission((position) => {
      const { latitude, longitude } = position;
      const params = {
        location: __('currentLocation'),
        locationId: undefined,
        position: latitude && longitude ? latitude + '-' + longitude : '',
      };

      this.setState({ ...params }, () => {
        if (makeSearch) {
          setLocationInCookies(params);
          this.props.dispatch(searchActions.setParameter(params));
          // this.gotosearch();
          this.gotoSearchAndChangeSorter(false);
        }
      });
    });
  };
}

export default SearchFunctions;
