import { z } from 'zod';
import { placeSchema } from './shared';

const bundleServiceSchema = z.object({
  mpId: z.number(),
  name: z.string(),
  quantity: z.number(),
});

const bundleSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().optional(),
  validity: z.string().optional(),
  validMonths: z.number(),
  price: z.number(),
  priceLabel: z.string(),
  finalPrice: z.number().optional(),
  finalPriceLabel: z.string().optional(),
  durationLabel: z.string().optional(),
  service: bundleServiceSchema,
});

export const bundleStateSchema = z.object({
  bundle: bundleSchema,
  place: placeSchema,
});

export type BundleState = z.infer<typeof bundleStateSchema>;
