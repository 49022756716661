import css from './Switch.module.scss';

type SwitchProps = {
  switched: boolean;
  onChange: () => void;
  alt?: string;
};

const Switch = ({ switched, onChange, alt = '' }: SwitchProps) => {
  return (
    <button
      onClick={onChange}
      aria-checked={switched}
      role="switch"
      className={`${css.button} ${
        switched ? 'bg-black-800 border-black-900' : 'bg-black-500 border-black-600'
      } relative inline-flex w-[52px] items-center rounded-full border-[1.5px] outline-none`}>
      <span className="sr-only">{alt}</span>
      <span
        className={`${
          switched ? 'translate-x-[21px]' : 'translate-x-0'
        } inline-block h-7 w-7 transform rounded-full bg-white transition`}
      />
    </button>
  );
};

export default Switch;
