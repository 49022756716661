import { getImagePreview } from '@/helpers';
import { HTMLAttributes } from 'react';

export type AvatarSize = keyof typeof sizeClass;

const sizeClass = {
  xs: 'w-5 h-5',
  sm: 'w-8 h-8',
  md: 'h-12 w-12',
  lg: 'w-20 h-20',
  '56': 'h-[56px] w-[56px]',
  '60': 'h-[60px] w-[60px]',
  '64': 'h-[64px] w-[64px]',
} as const;

type Props = {
  style?: HTMLAttributes<HTMLDivElement>['style'];
  size?: AvatarSize;
  src?: string;
  srcStyle?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
};

const Avatar = ({ style = null, size = '64', src = null, srcStyle = null, onClick = null, className = '' }: Props) => {
  const avatarStyle = style
    ? style
    : {
        display: 'flex',
        overflow: 'hidden',
        flexShrink: 0,
        alignItems: 'center',
        borderRadius: '12px',
        justifyContent: 'center',
      };

  let imgSrc = src ? src : '/images/avatar.svg';
  const mainsrc = getImagePreview(imgSrc, '60x60');
  const src2x = getImagePreview(imgSrc, '120x120');
  const computedStyle = srcStyle
    ? srcStyle
    : src
    ? { width: '100%', height: '100%' }
    : { width: '24px', height: '16px' };
  const set = [mainsrc + ' 1x', src2x + ' 2x'].join(',\n');

  return (
    <div className={`avatar ${className} ${sizeClass[size]} bg-black-50`} style={avatarStyle} onClick={onClick}>
      <img
        width="60"
        height="60"
        src={mainsrc}
        srcSet={set}
        alt="noImage"
        style={computedStyle}
        itemProp="image"
        itemType="http://schema.org/ImageObject"
        loading="lazy"
      />
    </div>
  );
};

export default Avatar;
