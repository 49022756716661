import { CertificateTag, GiftCardTag, KlarnaTag, QliroTag } from '@/components/elements/Tag';
import { isSistaminuten } from '@/helpers';
import { TCertificate } from '../salon/Certificates';

type Props = {
  employee: {
    about: {
      settings: {
        hasKlarna: boolean;
        useQliro: boolean;
        acceptsGiftCard: boolean;
      };
      certificates: TCertificate[];
    };
  };
  inModal?: boolean;
};

const EmployeeBadges = ({ employee, inModal = false }: Props) => {
  const acceptsKlarna = employee?.about?.settings?.hasKlarna;
  const acceptsQliro = employee?.about?.settings?.useQliro;
  const hasCertificate = employee?.about?.certificates?.length || false;
  const acceptsGiftcard = !isSistaminuten() && employee?.about?.settings?.acceptsGiftCard;
  const hasBadges = acceptsGiftcard || acceptsKlarna || acceptsQliro || hasCertificate;

  if (!hasBadges) return null;

  return (
    <>
      {acceptsKlarna && <KlarnaTag />}
      {acceptsQliro && <QliroTag />}
      {hasCertificate && !inModal && (
        <a href="#certificates">
          <CertificateTag />
        </a>
      )}
      {hasCertificate && inModal && <CertificateTag />}
      {acceptsGiftcard && <GiftCardTag />}
    </>
  );
};

export default EmployeeBadges;
