import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import Button, { ButtonProps } from '@/components/elements/forms/buttons/Button/Button';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';

import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { ThreeDSComponent } from '@/components/modules/adyen';
import CheckoutDetails from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import CheckoutModal from '@/components/modules/checkout/CheckoutModal/CheckoutModal';
import {
  CheckoutModalProvider,
  useCheckoutModal,
} from '@/components/modules/checkout/CheckoutModal/CheckoutModal.hooks';
import CheckoutTerms from '@/components/modules/checkout/CheckoutTerms/CheckoutTerms';
import PromotedPaymentMethods from '@/components/modules/checkout/PromotedPaymentMethods/PromotedPaymentMethods';
import CreditCardModal from '@/components/modules/modals/CreditCardModal';
import SwishModal from '@/components/modules/modals/SwishModal';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import FloatingCTAWrapper from '@/components/modules/pages/booking/checkout/FloatingCTAWrapper';
import SelectPaymentOptions from '@/components/templates/checkout/SelectPaymentOptions/SelectPaymentOptions';
import UserLogin from '@/components/templates/checkout/UserLogin/UserLogin';
import { EVENT_NAME, SCREEN_NAME } from '@/constants/analytics';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { classnames, promiseWrapper, trackMpEvent, url } from '@/helpers';
import { getThreeDSecureRedirectResultIfAny } from '@/helpers/adyen';
import {
  getBookingSubmitLabel,
  getCheckoutTerms,
  getCheckoutTermsVariant,
  getPaymentMethodsTracking,
  hasPaymentMethod,
} from '@/helpers/checkout';
import {
  mapConfirmedBookingInfoGiftcards,
  mapConfirmedBookingInfoServices,
  mapConfirmedBookingToCheckoutDetailsProps,
} from '@/helpers/confirmation';
import withWalletPayment from '@/hoc/withWalletPayment';
import { useAppSelector } from '@/hooks';
import { UseCardsProvider, useCardsManager } from '@/hooks/adyen/useCards';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import { usePaymentsHistory } from '@/hooks/usePaymentsHistory';
import useExternalScript from '@/hooks/useExternalScript';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { LoginContext } from '@/hooks/useLogin';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView, { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { saveCofCheckoutStateToLocalStorage } from '@/pages/validate-cof-payment-redirect/ValidateCofPaymentRedirect.hooks';
import { bookServices } from '@/services';
import { AppointmentResponse, ConfirmedBooking, appointmentResponseSchema } from '@/types/api/services/booking';
import { CheckoutMissingAction, SelectedPaymentMethod, selectedPaymentMethodSchema } from '@/types/checkout';
import { PaymentCard } from '@/types/paymentcards';
import { BookState } from '@/types/state/book';
import * as Sentry from '@sentry/react';
import { Dispatch, useContext, useEffect, useReducer, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PrepayCheckoutProvider, usePrepayCheckout } from './PrepayCheckout.hooks';

type PrepayCheckoutWrapperAction =
  | {
      type: 'SET_BOOKING';
      payload: AppointmentResponse['booking'];
    }
  | {
      type: 'SET_ERROR';
      payload: boolean;
    };

type PrepayCheckoutWrapperState = {
  booking: AppointmentResponse['booking'];
  loading: boolean;
  error: boolean;
};

const baseTranslationKey = 'pages.booking.checkout.$id.PrepayCheckout';

const PrepayCheckout = ({ booking }: { booking: ConfirmedBooking }) => {
  const {
    selectedPaymentMethod,
    summary,
    missingActions,
    cofThreeDS,
    submitting,
    swish,
    onDismissSwishQRCode,
    onApplyGiftcard,
    onChangePaymentMethod,
    prepayCheckoutAPI,
    onRemoveGiftcard,
  } = usePrepayCheckout();
  // experiment with radio buttons
  const usePromotedPaymentMethods =
    useGetAmplitudeExperimentVariant()('promoted-payment-methods-web')?.value === 'variant-options-visible';
  // experiment with radio buttons and popular badge
  const useHighlightedPaymentMethods =
    useGetAmplitudeExperimentVariant()('highlighted-payment-methods')?.value === 'variant-popular-badge-visible';

  const { onModalClose: onCheckoutModalClose, ...checkoutModalContext } = useCheckoutModal();
  const { isMobileView } = useMobileView();
  const user = useAppSelector((state) => state.users?.user);
  const { email, handleEmailSubmit, updateEmail } = useContext(LoginContext);
  const bookState = useAppSelector((state) => state.book) as BookState;
  const addedGiftcards = bookState.usageReqId?.giftcards;
  const hasCoF = hasPaymentMethod(summary, CHECKOUT_PAYMENT_METHOD.STORED_COF);
  useExternalScript({ src: 'https://pay.google.com/gp/p/js/pay.js', cleanup: false, load: hasCoF });
  const addedGiftcardsList = Object.values(addedGiftcards || {}).map((giftcard) => giftcard);

  const confirmationContainerRef = useRef<HTMLDivElement>(null);
  const isCheckoutCTAInView = useIntersectionObserver(confirmationContainerRef);
  const paymentTracking = getPaymentMethodsTracking({ selectedPaymentMethod, summary, giftcards: addedGiftcardsList });
  const hasSwish = summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.SWISH);

  const payLater =
    !hasSwish &&
    !summary.isOnlinePaymentRequired &&
    (selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE ||
      selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.APPLE_PAY ||
      selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY ||
      selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF);

  const { paymentMethod, ...rest } = mapConfirmedBookingToCheckoutDetailsProps({ booking, addedGiftcards });
  const services = mapConfirmedBookingInfoServices(booking);
  const giftcards = mapConfirmedBookingInfoGiftcards(booking, addedGiftcards, onRemoveGiftcard);
  const checkoutDetailsProps = { ...rest, services, giftcards, payLater };
  const showThreeDSecure = Boolean(cofThreeDS?.adyenActionJson);
  const pageUrl = `${url.getBaseUrl()}/booking/checkout/${booking.id}`;
  const pageTitle = _s(`seo.activateOnlinePaymentTitle`);
  const pageDescription = _s(`seo.activateOnlinePaymentDescription`);

  const showFloatingCTA = isCheckoutCTAInView !== undefined && Boolean(!isCheckoutCTAInView);
  const isKlarnaOrQliro =
    selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.QLIRO ||
    selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.KLARNA;

  const showSwishQrCode = Boolean(swish?.showQrCode);

  const checkoutTerms = getCheckoutTerms(
    'booking',
    getCheckoutTermsVariant(selectedPaymentMethod),
    isKlarnaOrQliro
      ? getBookingSubmitLabel({
          paymentMethod: selectedPaymentMethod.type,
          isMobileView,
          isFloating: !isCheckoutCTAInView,
        })
      : _s('bookCTA.pay'),
  );

  const handleCloseTerms = () => {
    trackScreenView({ name: 'screen_view_checkout_booking_update_payment_method', properties: paymentTracking });
  };

  const handleChangePaymentMethod = (method: SelectedPaymentMethod) => {
    if (method.type === CHECKOUT_PAYMENT_METHOD.NEW_COF) {
      return;
    }
    const updatedPaymentTracking = getPaymentMethodsTracking({
      selectedPaymentMethod: method,
      summary,
      giftcards: addedGiftcardsList,
    });
    onChangePaymentMethod(method);
    onCheckoutModalClose();
    trackScreenView({ name: 'screen_view_checkout_booking_update_payment_method', properties: updatedPaymentTracking });
  };

  const handleCloseCheckoutModal = () => {
    onCheckoutModalClose();
    trackScreenView({ name: 'screen_view_checkout_booking_update_payment_method', properties: paymentTracking });
  };

  const handleOnCreditCardSelect = (card: PaymentCard) => {
    handleChangePaymentMethod({
      type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
      brand: card.brand,
      id: card.id,
      lastFour: card.lastFour,
    });
  };

  const handleOnRemoveCard = (card: PaymentCard) => {
    if (selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF && selectedPaymentMethod.id === card.id) {
      handleChangePaymentMethod({ type: CHECKOUT_PAYMENT_METHOD.NONE });
    }
  };

  const handleBeforeThreeDSRedirect = () => {
    const validatedAdyenPaymentMethod = selectedPaymentMethodSchema.safeParse(selectedPaymentMethod);
    const hasSwish = summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.SWISH);

    if (validatedAdyenPaymentMethod.success === false) {
      Sentry.captureException(validatedAdyenPaymentMethod.error);
      return;
    }

    saveCofCheckoutStateToLocalStorage({
      isAddNewCard: true,
      payLater: !summary.isOnlinePaymentRequired && !hasSwish,
      selectedPaymentMethod: validatedAdyenPaymentMethod.data,
    });
  };

  const handleDismissSwishQRCode = () => {
    trackScreenView({ name: 'screen_view_checkout_booking_update_payment_method', properties: paymentTracking });
    onDismissSwishQRCode();
  };

  useTrackScreenView({ name: 'screen_view_checkout_booking_update_payment_method', properties: paymentTracking });

  useTrackScreenView(
    {
      name: 'screen_view_select_payment_method',
      properties: { trigger_source: 'checkout_booking_update_payment_method' },
    },
    [checkoutModalContext.isOpen, checkoutModalContext.screen],
    [checkoutModalContext.isOpen && checkoutModalContext.screen === 'list'],
  );

  useTrackScreenView(
    {
      name: 'screen_view_checkout_booking_swish_update_payment_method',
      properties: paymentTracking,
    },
    [showSwishQrCode],
    [showSwishQrCode],
  );

  const handleApplyGiftcard = (code: string) => {
    onApplyGiftcard(code)(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      handleCloseCheckoutModal();
    });
  };

  return (
    <PageViewLayout
      type="subView"
      title={_s(`${baseTranslationKey}.page-title`)}
      back
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      <div className="bg-brown-50 lg:bg-transparent">
        <div className="lg:py-xxl lg:container">
          <div className="gap-xxl flex items-start">
            <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
              <CardWrapper className="w-full lg:hidden">
                <CheckoutDetails {...checkoutDetailsProps} payLater={payLater} />
              </CardWrapper>
              {!user && (
                <CardWrapper>
                  <UserLogin email={email} handleEmailSubmit={handleEmailSubmit} updateEmail={updateEmail} />
                </CardWrapper>
              )}
              {user && summary.isPayable && (
                <CardWrapper>
                  {usePromotedPaymentMethods || useHighlightedPaymentMethods ? (
                    <PromotedPaymentMethods
                      summary={summary}
                      selectedPaymentMethod={selectedPaymentMethod}
                      onChangePaymentMethod={handleChangePaymentMethod}
                      triggerSource={SCREEN_NAME.CHECKOUT_BOOKING_UPDATE_PAYMENT_METHOD}
                      useHighlighted={useHighlightedPaymentMethods}
                      error={missingActions.includes('payment-method')}
                      {...checkoutModalContext}
                    />
                  ) : (
                    <SelectPaymentOptions
                      method={selectedPaymentMethod}
                      onClick={() => checkoutModalContext.onModalShow()}
                      error={missingActions.includes('payment-method')}
                      disabled={false}
                    />
                  )}
                </CardWrapper>
              )}
              {user && (
                <>
                  <CardWrapper>
                    <div className="gap-xl p-lg flex flex-col">
                      <SubmitCTA />
                      <CheckoutTerms
                        description={checkoutTerms.description}
                        linkLabel={checkoutTerms.linkLabel}
                        termsPageId={checkoutTerms.termsPageId}
                        onClose={handleCloseTerms}
                        triggerSource={SCREEN_NAME.CHECKOUT_BOOKING_UPDATE_PAYMENT_METHOD}
                      />
                    </div>
                  </CardWrapper>
                  <FloatingCTAWrapper
                    description={checkoutTerms.description}
                    linkLabel={checkoutTerms.linkLabel}
                    termsPageId={checkoutTerms.termsPageId}
                    show={showFloatingCTA}
                    onCloseTerms={handleCloseTerms}>
                    <SubmitCTA floating />
                  </FloatingCTAWrapper>
                </>
              )}
            </div>
            <div className="sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px]">
              <CardWrapper className="w-full">
                <CheckoutDetails {...checkoutDetailsProps} payLater={payLater} />
              </CardWrapper>
            </div>
          </div>
        </div>
      </div>
      {checkoutModalContext.screen === 'cof' ? (
        <CreditCardModal
          onBeforeThreeDSRedirect={handleBeforeThreeDSRedirect}
          isOpen={checkoutModalContext.isOpen}
          onBack={checkoutModalContext.onModalBack}
          onClose={handleCloseCheckoutModal}
          onCardSelect={handleOnCreditCardSelect}
          onRemoveCard={handleOnRemoveCard}
          triggerSource={SCREEN_NAME.CHECKOUT_BOOKING_UPDATE_PAYMENT_METHOD}
          returnPath={`/booking/checkout/${booking.id}/validate-3ds-redirect`}
          {...checkoutModalContext}
        />
      ) : (
        <CheckoutModal
          summary={summary}
          selectedPaymentMethod={selectedPaymentMethod}
          onChangePaymentMethod={handleChangePaymentMethod}
          onModalClose={handleCloseCheckoutModal}
          triggerSource={SCREEN_NAME.CHECKOUT_BOOKING_UPDATE_PAYMENT_METHOD}
          onApplyGiftcard={handleApplyGiftcard}
          {...checkoutModalContext}
        />
      )}
      {showThreeDSecure && (
        <Modal isOpen={!submitting}>
          <Modal.Content floating={!isMobileView}>
            <div className={classnames('gap-xl pb-lg px-lg flex flex-col', isMobileView && 'px-lg')}>
              <ThreeDSComponent
                action={JSON.parse(cofThreeDS?.adyenActionJson)}
                handleOnAdditionalDetails={(data) =>
                  prepayCheckoutAPI().submitThreeDS(
                    data,
                    !hasSwish && !summary.isOnlinePaymentRequired,
                    selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY,
                  )
                }
              />
            </div>
          </Modal.Content>
        </Modal>
      )}
      {showSwishQrCode && <SwishModal onClose={handleDismissSwishQRCode} qrCode={swish.payment.qrCode} />}

      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={pageUrl} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="robots" content="noindex" />
      </Helmet>
    </PageViewLayout>
  );
};
const SubmitCTA = ({ floating = false }: { floating?: boolean }) => {
  const { isMobileView } = useMobileView();
  const { selectedPaymentMethod, submitting, summary, missingActions, prepayCheckoutAPI, onCheckoutMissingAction } =
    usePrepayCheckout();
  const paymentMethod = selectedPaymentMethod.type;
  const isKlarnaOrQliro =
    paymentMethod === CHECKOUT_PAYMENT_METHOD.QLIRO || paymentMethod === CHECKOUT_PAYMENT_METHOD.KLARNA;
  const { final } = summary.availablePaymentMethods.find((method) => method.type === paymentMethod) || {};
  const hasSwish = summary.availablePaymentMethods.find((method) => method.type === CHECKOUT_PAYMENT_METHOD.SWISH);
  const payLater = !summary.isOnlinePaymentRequired && !hasSwish;
  const invalidPaymentMethod = paymentMethod === CHECKOUT_PAYMENT_METHOD.NONE;

  const buttonProps: ButtonProps = {
    size: 'lg',
    block: true,
    disabled: submitting || Boolean(missingActions.length),
    label: isKlarnaOrQliro
      ? getBookingSubmitLabel({ paymentMethod: paymentMethod, isFloating: floating, isMobileView: isMobileView })
      : _s('bookCTA.pay'),
    onClick: () => {
      const missingActions: CheckoutMissingAction[] = [];
      if (invalidPaymentMethod) missingActions.push('payment-method');

      missingActions.forEach((action) => onCheckoutMissingAction(action));

      if (missingActions.length) {
        trackMpEvent(EVENT_NAME.VALIDATION_FAIL, {
          screen_name: SCREEN_NAME.CHECKOUT_BOOKING_SUMMARY,
          field_name: missingActions,
        });
        return;
      }

      switch (paymentMethod) {
        case CHECKOUT_PAYMENT_METHOD.KLARNA:
          return prepayCheckoutAPI().initKlarna();
        case CHECKOUT_PAYMENT_METHOD.QLIRO:
          return prepayCheckoutAPI().initQliro();
        case CHECKOUT_PAYMENT_METHOD.STORED_COF:
          return prepayCheckoutAPI().submitCoF(selectedPaymentMethod.id);
        case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
          return prepayCheckoutAPI().submitGooglePay(+final, payLater);
        case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
          return prepayCheckoutAPI().submitApplePay(+final, payLater);
        case CHECKOUT_PAYMENT_METHOD.SWISH:
          return prepayCheckoutAPI().submitSwish();
        case CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE:
          return prepayCheckoutAPI().submitPayAtPlace();
        default:
          return null;
      }
    },
  };

  return <Button {...buttonProps} />;
};

async function fetchAndSetBooking(bookingId: number, dispatch: Dispatch<PrepayCheckoutWrapperAction>) {
  const { data, error } = await promiseWrapper(bookServices.appointment(bookingId));

  const validateData = appointmentResponseSchema.safeParse(data);

  if (!validateData.success || error) {
    dispatch({ type: 'SET_ERROR', payload: true });
    return null;
  }

  dispatch({ type: 'SET_BOOKING', payload: validateData.data.booking });
  return validateData.data.booking;
}

/**
 * Wrap the checkout page in a component that checks if the book state is valid
 * and other validation that needs to be done before rendering the checkout page.
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default withWalletPayment(function () {
  const routeMatch = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { cards, loading: cardsLoading } = useCardsManager([], true);
  const { bookingId } = routeMatch.params;
  let { paymentHistory, loading: loadingPaymentHistory } = usePaymentsHistory();

  const [state, dispatch] = useReducer(
    (state: PrepayCheckoutWrapperState, action: PrepayCheckoutWrapperAction) => {
      switch (action.type) {
        case 'SET_BOOKING':
          return {
            ...state,
            booking: action.payload,
            error: false,
            loading: false,
          };
        case 'SET_ERROR': {
          return {
            ...state,
            error: action.payload,
            loading: false,
          };
        }
        default:
          return state;
      }
    },
    {
      booking: null,
      loading: true,
      error: false,
    },
  );

  useEffect(() => {
    fetchAndSetBooking(bookingId, dispatch);
  }, [bookingId]);

  useEffect(() => {
    const threeDSecureRedirectResultSuccess = getThreeDSecureRedirectResultIfAny(location, history);
    if (threeDSecureRedirectResultSuccess || threeDSecureRedirectResultSuccess === undefined) return;
    toast(({ closeToast }) => (
      <Snackbar label={_s(`${baseTranslationKey}.threeDSecureRedirectError`)} type="danger" onClose={closeToast} />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.loading || cardsLoading || loadingPaymentHistory) return <LoadingPlaceHolder />;
  if (state.error || state.booking?.extra?.paymentMethod !== CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE) return <GoBack />;

  return (
    <UseCardsProvider initialCards={cards}>
      <PrepayCheckoutProvider booking={state.booking} paymentHistory={paymentHistory}>
        <CheckoutModalProvider>
          <PrepayCheckout booking={state.booking} />
        </CheckoutModalProvider>
      </PrepayCheckoutProvider>
    </UseCardsProvider>
  );
});
