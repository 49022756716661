import { createContext, useContext } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

type UseCheckoutFormContext = ReturnType<typeof useCheckoutFormManager>;

const CheckoutFormContext = createContext<UseCheckoutFormContext>(null);

const useCheckoutFormManager = () => {
  const {
    control,
    formState,
    handleSubmit,
    register,
    unregister,
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm<FieldValues>({
    mode: 'onSubmit',
  });

  const resetErrors = () => {
    reset(
      {
        ...getValues(),
      },
      {
        keepValues: true,
        keepErrors: false,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      },
    );
  };

  return {
    formState,
    control,
    register,
    unregister,
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    resetErrors,
    clearErrors,
  };
};

export const CheckoutFormProvider = ({ children }) => {
  const checkoutForm = useCheckoutFormManager();
  return <CheckoutFormContext.Provider value={checkoutForm}>{children}</CheckoutFormContext.Provider>;
};

export const useCheckoutForm = () => {
  const context = useContext(CheckoutFormContext);

  if (!context) {
    throw new Error('useCheckoutForm must be used within a CheckoutFormProvider');
  }

  return context;
};
