import { modalActions } from '@/actions';
import { Button } from '@/components/elements/forms/buttons';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { TriggerSource } from '@/types/analytics';
import { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import UpdateUserInfo, { UpdateUserInfoFieldKey, UpdateUserInfoFields } from '../UpdateUserInfo/UpdateUserInfo';
import useUpdateUserInfo, { UseUpdateUserInfoCallbackProps } from '../UpdateUserInfo/UpdateUserInfo.hooks';
import Modal, { ModalProps } from './redesign/Modal/Modal';

const baseTranslationKey = `components.modules.modals.UserProfileOnboarding`;

type UserProfileOnboardingProps = ModalProps & {
  onProfleSuccess: () => void;
  onClose?: () => void;
};

const steps: UpdateUserInfoFieldKey[][] = [
  ['givenName', 'familyName', 'gender', 'dob'],
  ['streetAddress', 'postalCode', 'locality', 'mobile'],
];

let name = '';

function displayToast({ success, retry }: UseUpdateUserInfoCallbackProps) {
  toast(
    ({ closeToast }) => (
      <Snackbar
        label={_s(`${baseTranslationKey}.snackbar.label.${success ? 'success' : 'error'}`, { name })}
        type={success ? 'success' : 'danger'}
        {...(!success && {
          action: (
            <button
              onClick={() => {
                retry();
                closeToast();
              }}>
              {_s(`${baseTranslationKey}.snackbar.action.error`)}
            </button>
          ),
        })}
      />
    ),
    { autoClose: 3000 },
  );
}

const UserProfileOnboarding = ({ isOpen, onClose }: UserProfileOnboardingProps) => {
  const { isMobileView } = useMobileView();
  const [currentStep, setCurrentStep] = useState(0);
  const appDispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users?.user);
  const lastStep = currentStep === steps.length - 1;
  const triggerSource: TriggerSource = currentStep === 0 ? 'create_profile_1/2' : 'create_profile_2/2';
  const { handleSubmit, ...updateUserInfoProps } = useUpdateUserInfo({ callback: handleProfileUpdate, triggerSource });
  const modalHeaderRef = useRef<HTMLDivElement>(null);

  name = user?.about?.givenName || '';

  function handleProfileUpdate({ success, retry }: UseUpdateUserInfoCallbackProps) {
    const showToaster = lastStep || !success;

    if (showToaster) displayToast({ success, retry });

    if (!success) return;

    if (lastStep) {
      appDispatch(modalActions.userProfileOnboarding({ show: false }));
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  }

  const title = _s(`${baseTranslationKey}.title`, { currentStep: currentStep + 1, totalSteps: steps.length });
  const step = steps[currentStep];
  const stepProps = step.reduce(
    (acc: UpdateUserInfoFields, curr: UpdateUserInfoFieldKey) => ({ ...acc, [curr]: true }),
    {},
  );

  const handleSkipFieldsClick = () => {
    displayToast({ success: true });
    appDispatch(modalActions.userProfileOnboarding({ show: false }));
  };

  useEffect(() => {
    modalHeaderRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [currentStep]);

  useTrackScreenView(
    {
      name: 'screen_view_create_profile_1/2',
    },
    [currentStep],
    [currentStep === 0],
  );

  useTrackScreenView(
    {
      name: 'screen_view_create_profile_2/2',
    },
    [currentStep],
    [lastStep],
  );

  return (
    <Modal isOpen={isOpen}>
      <Modal.Content floating={!isMobileView}>
        <div ref={modalHeaderRef}>
          <Modal.Header onClose={onClose} title={title} />
        </div>
        <div className={`flex flex-col ${isMobileView ? 'px-lg' : ''}`}>
          <p className="text-black-600 text-md p-lg">
            {currentStep === 0 && (
              <>
                {_s(`${baseTranslationKey}.description.1`)}
                <span className="text-black-900">{user?.contact?.email || ''}</span>
                {_s(`${baseTranslationKey}.description.2`)}
              </>
            )}
            {lastStep && _s(`${baseTranslationKey}.description.3`)}
          </p>
          <Fragment key={currentStep}>
            <UpdateUserInfo
              {...stepProps}
              {...updateUserInfoProps}
              handleSubmit={handleSubmit}
              {...(!lastStep && { submitLabel: _s(`${baseTranslationKey}.saveProfile`) })}
            />
          </Fragment>
          {lastStep && (
            <Button variant="link" size="md" block onClick={handleSkipFieldsClick}>
              {_s(`${baseTranslationKey}.skip`)}
            </Button>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default UserProfileOnboarding;
