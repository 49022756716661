import { SearchForm } from '@/components/modules/search';
import { classnames, isSistaminuten } from '@/helpers';
import { themed } from '@/helpers/theme';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import '@/styles/modules/hero.scss';
import SearchBar from './SearchBar';

const Hero = () => {
  const { isMobileView } = useMobileView();
  return (
    <div
      className={classnames(
        'hero',
        isSistaminuten()
          ? `bg-hero-sm bg-cover bg-bottom bg-no-repeat`
          : `align-center relative flex h-[394px] flex-col justify-end`,
      )}>
      {!isSistaminuten() && (
        <picture>
          <source srcSet="/images/desktop-homepage-background-2xl-v6.jpg" media="(min-width: 1900px)" />
          <source srcSet="/images/desktop-homepage-background-lg-v6.jpg" media="(min-width: 768px)" />
          <img
            src="/images/mobile-homepage-background-v6.jpg"
            className="absolute inset-0 h-full w-full bg-[#ddd2e1] object-cover"
            alt=""
          />
        </picture>
      )}
      <div className="container relative z-10 mx-auto">
        <div className={`${themed(!isMobileView ? 'mx-auto max-w-5xl pb-11 pt-20 md:pb-24' : '', 'py-11 md:py-20')}`}>
          {isSistaminuten() && <div className="mb-4 text-center font-semibold">{_s('40DiscountHome')}</div>}
          <h1
            className={classnames(
              'mb-xxl !mt-0 text-4xl font-semibold md:text-5xl',
              themed('text-white', 'text-black-800'),
              isMobileView ? 'max-w-[250px] p-0 md:max-w-full' : 'mx-auto max-w-[600px] text-center',
              isSistaminuten() && 'my-xl max-w-full text-center',
            )}>
            {isSistaminuten() ? _s('sistaminutenH1') : _s(`bokadirektH1.${isMobileView ? 'mobile' : 'desktop'}`)}
          </h1>

          {isMobileView ? (
            <div className="navigation py-lg flex flex-col">
              <SearchForm source="home" />
            </div>
          ) : (
            <div className="navigation bg-transparent shadow-none">
              <SearchBar />
            </div>
          )}
        </div>
      </div>
      {!isSistaminuten() && <div className="absolute inset-0 bg-black opacity-30 "></div>}
    </div>
  );
};

export default Hero;
