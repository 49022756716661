import { bundleActions } from '@/actions';
import { bundleStateSchema } from '@/types/state/bundle';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

export type BundleFetchStatus = 'loading' | 'error' | 'success';

const useBundleCheckoutFallback = () => {
  const bundleState = useSelector((state: any) => state.bundle);
  const { params } = useRouteMatch();
  const dispatch = useDispatch();
  const place = bundleState?.place;
  const validBundleState = bundleStateSchema.safeParse(bundleState);
  const placeId = params.placeId || place?.id;
  const bundleId = params.bundleId;

  /**
   * make sure to refetch if bundle id changes in params
   */
  const initialStatus =
    validBundleState.success && validBundleState.data.bundle.id === bundleId ? 'success' : 'loading';

  const [status, setStatus] = useState<BundleFetchStatus>(initialStatus);

  useEffect(() => {
    if (status === 'success') return;
    const controller = new AbortController();
    const { signal } = controller;

    if (!placeId || !bundleId) {
      setStatus('error');
      return;
    }

    dispatch(bundleActions.fetchAndSetPlaceBundle(+placeId, +bundleId, signal, setStatus));

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (place?.id) setStatus('success');
    if (place?.fetchError) setStatus('error');
  }, [place]);

  return { status };
};

export default useBundleCheckoutFallback;
