import { userActions } from '@/actions';
import { EVENT_NAME } from '@/constants/analytics';
import { promiseWrapper, trackMpEvent } from '@/helpers';
import { trimObjectValuesWhitespace } from '@/helpers/utilsTS';
import { useAppSelector } from '@/hooks';
import { userService } from '@/services';
import { TriggerSource } from '@/types/analytics';
import { UpdateProfileRequest, updateProfileResponseSchema } from '@/types/api/services/user';

import * as moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { UpdateUserInfoFieldKey } from './UpdateUserInfo';

export type UseUpdateUserInfo = ReturnType<typeof useUpdateUserInfo>;

export type UseUpdateUserInfoCallbackProps = {
  success: boolean;
  retry?: () => void;
};

const errorValidationFieldMap: {
  [key in UpdateUserInfoFieldKey]: string;
} = {
  givenName: 'given_name',
  familyName: 'family_name',
  dob: 'dob',
  gender: 'gender',
  mobile: 'mobile',
  streetAddress: 'street_adress',
  postalCode: 'postal_code',
  locality: 'city',
  email: 'email',
  phone: 'phone',
};

const useUpdateUserInfo = ({
  callback,
  triggerSource,
}: {
  callback: (props: UseUpdateUserInfoCallbackProps) => void;
  triggerSource: TriggerSource;
}) => {
  const user = useAppSelector((state) => state.users.user);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<UpdateProfileRequest>({ mode: 'onSubmit' });
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;

    const dob = (() => {
      if (user.about?.dob && moment(user.about?.dob).isValid()) return moment(user.about?.dob).format('YYYY-MM-DD');
      return '';
    })();

    setValue('about.familyName', user?.about?.familyName ?? '');
    setValue('about.givenName', user?.about?.givenName ?? '');
    setValue('about.gender', user?.about?.gender ?? '');
    setValue('about.dob', dob);
    setValue('contact.phone', user?.contact?.mobile ?? user?.contact?.phone ?? '');
    setValue('contact.mobile', user?.contact?.mobile ?? user?.contact?.phone ?? '');
    setValue('contact.locality', user?.contact?.locality ?? '');
    setValue('contact.postalCode', user?.contact?.postalCode ?? '');
    setValue('contact.streetAddress', user?.contact?.streetAddress ?? '');
  }, [user, setValue]);

  const onSubmit = async (formData: UpdateProfileRequest): Promise<void> => {
    setIsLoading(true);

    if (moment(formData.about?.dob).isValid()) {
      formData.about.dob = new Date(formData.about.dob).toISOString();
    }

    const trimmedFormData = trimObjectValuesWhitespace(formData);

    const { data } = await promiseWrapper(userService.updateProfile(trimmedFormData));

    const validatedResponse = updateProfileResponseSchema.safeParse(data);

    if (validatedResponse.success) {
      dispatch(userActions.updateUser({ ...user, ...validatedResponse.data }, true));
    }

    callback?.({ success: validatedResponse.success, retry: handleSubmit(onSubmit) });
    setIsLoading(false);
  };

  return {
    isValid,
    loading,
    errors,
    watch,
    register,
    handleSubmit: handleSubmit(onSubmit, (error) => {
      const fieldNames = Array.from(
        new Set(
          Object.entries(error).map(([_, value]) =>
            Object.keys(value).map((key) => errorValidationFieldMap[key] ?? `${key}`),
          ),
        ),
      );

      trackMpEvent(EVENT_NAME.VALIDATION_FAIL, {
        screen_name: triggerSource,
        field_name: fieldNames.join(', '),
      });
    }),
  };
};

export default useUpdateUserInfo;
