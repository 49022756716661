import { h2Class } from '@/components/elements/content';
import Tag from '@/components/elements/Tag';

const Header = ({ title, tag, tagText }) => (
  <>
    <h1 className={h2Class}>{title}</h1>
    <div className="flex items-center justify-start">
      <Tag>{tag}</Tag>
      <span className="ml-3">{tagText}</span>
    </div>
  </>
);

export default Header;
