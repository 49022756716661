import Icon, { CloseIconThin } from '@/components/icons';
import { twBorderPrimary } from '@/helpers/theme';
import { SVGProps } from 'react';

type FakeSearchInputProps = {
  onClick: () => void;
  valid: boolean;
  content: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  className?: string;
  onClear: () => void;
  size?: 'sm' | 'md';
};

const FakeSearchInput = ({
  onClick,
  valid,
  content,
  icon,
  className = '',
  onClear,
  size = 'sm',
}: FakeSearchInputProps) => (
  <div
    className={`flex rounded-full border bg-white ${
      valid ? twBorderPrimary() : 'border-black-600'
    } relative my-3 h-11 pl-11 ${className}`}>
    <Icon icon={icon} className="text-black-900 absolute left-4 top-1/2 h-4 w-4 -translate-y-1/2" />
    <div
      className={`h-full flex-grow cursor-pointer whitespace-nowrap ${
        valid ? 'text-black-900 max-w-full overflow-hidden text-ellipsis' : 'text-black-600'
      } ${size === 'sm' ? 'leading-[44px]' : 'leading-[52px]'} pr-2`}
      onClick={onClick}>
      {content}
    </div>
    {valid && (
      <button
        className="!mr-2 flex h-full w-8 flex-shrink-0 flex-grow-0 items-center justify-center outline-none"
        onClick={onClear}>
        <CloseIconThin className="text-black-900 h-3 w-3" />
      </button>
    )}
  </div>
);

export default FakeSearchInput;
