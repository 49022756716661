import useBlogPageContent from '@/hooks/blog/useBlogPage';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { TriggerSource } from '@/types/analytics';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Modal from '../../modals/redesign/Modal/Modal';
import css from './CheckoutTerms.module.scss';

const baseTranslationKey = 'components.modules.checkout.ConfirmationCta';

const CheckoutTerms = ({
  className = '',
  triggerSource,
  termsPageId,
  description,
  linkLabel,
  onClose,
}: {
  className?: string;
  triggerSource: TriggerSource;
  termsPageId: number;
  description: string;
  linkLabel: string;
  onClose: () => void;
}) => {
  const [showTerms, setShowTerms] = useState(false);
  const { isMobileView } = useMobileView();

  useTrackScreenView(
    { name: 'screen_view_general_terms', properties: { trigger_source: triggerSource } },
    [showTerms],
    [showTerms],
  );

  return (
    <div className={twMerge('text-xxs text-center', className)}>
      <span className="text-inherit">
        {description}{' '}
        <button onClick={() => setShowTerms(true)} className="text-information-700 inline text-inherit underline">
          {linkLabel}
        </button>
      </span>
      {showTerms && (
        <Modal isOpen>
          <Modal.Content floating={!isMobileView}>
            <Modal.Header
              title={_s(`${baseTranslationKey}.modal.title`)}
              onClose={() => {
                setShowTerms(false);
                onClose();
              }}
            />
            <Terms pageId={termsPageId} />
          </Modal.Content>
        </Modal>
      )}
    </div>
  );
};

const Terms = ({ pageId }: { pageId: number }) => {
  const content = useBlogPageContent(pageId);
  return <div className={`${css.generalTermsContent}`} dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export default CheckoutTerms;
