import { userActions } from '@/actions';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { isServer, promiseWrapper, trackMpEvent, trackPage } from '@/helpers';
import { useAppSelector } from '@/hooks';
import { bookServices } from '@/services';
import { Dispatch, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppointmentsList from './AppointmentsList';
import BookingFilter, { filterOptions } from './BookingFilter';

async function fetchAndSetAppointments(
  { status, past }: { status: string; past: boolean },
  dispatch: Dispatch<AppointmentAction>,
): Promise<void> {
  dispatch({ type: 'FETCH_APPOINTMENTS' });
  const { data, error } = await promiseWrapper(bookServices.appointments({ status, past: past ? 1 : 0 }));
  error
    ? dispatch({ type: 'FETCH_APPOINTMENTS_ERROR' })
    : dispatch({ type: 'FETCH_APPOINTMENTS_SUCCESS', payload: data });
}

function setActiveBookings(user, appointments, appDispatch) {
  user.activeBookings = appointments.reduce((filtered, item) => {
    if (Number(item.status) === 1) filtered.push(item.start);
    return filtered;
  }, []);
  appDispatch(userActions.updateUser(user, true));
}

const getScreenName = (past: AppointmentsTabProps['past']) => (past ? 'my_bookings_finished' : 'my_bookings_upcoming');

type AppointmentState = {
  appointments: any[];
  loading: boolean;
  status: any;
};

type AppointmentAction =
  | { type: 'FETCH_APPOINTMENTS' }
  | { type: 'FETCH_APPOINTMENTS_SUCCESS'; payload: any[] }
  | { type: 'FETCH_APPOINTMENTS_ERROR' }
  | { type: 'CLEAR_APPOINTMENTS' }
  | { type: 'CHANGE_STATUS'; payload: string };

const appointmentsReducer = (state: AppointmentState, action: AppointmentAction) => {
  switch (action.type) {
    case 'FETCH_APPOINTMENTS':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_APPOINTMENTS_SUCCESS':
      return {
        ...state,
        loading: false,
        appointments: action.payload,
      };
    case 'FETCH_APPOINTMENTS_ERROR':
      return {
        ...state,
        loading: false,
        appointments: [],
      };
    case 'CLEAR_APPOINTMENTS':
      return {
        ...state,
        appointments: [],
      };
    case 'CHANGE_STATUS':
      return {
        ...state,
        status: action.payload,
      };

    default:
      return state;
  }
};

const getInitialStatus = (filter: string) => {
  if (isServer) return filterOptions[0];
  const selected = filterOptions.filter((item) => item.trackingValue === localStorage.getItem(filter));
  return selected[0] || filterOptions[0];
};

type AppointmentsTabProps = {
  filter: string;
  past?: boolean;
};

const AppointmentsTab = ({ filter, past }: AppointmentsTabProps) => {
  const location = useLocation();
  const [state, dispatch] = useReducer(appointmentsReducer, {
    appointments: [],
    loading: true,
    status: getInitialStatus(filter),
  });

  const { user } = useAppSelector((state) => state.users);
  const appDispatch = useDispatch();

  useEffect(() => {
    fetchAndSetAppointments({ status: state.status.value, past: !!past }, dispatch);
    return () => {
      dispatch({ type: 'CLEAR_APPOINTMENTS' });
    };
  }, [state.status, past]);

  useEffect(() => {
    if (!past && state.appointments.length) {
      setActiveBookings(user, state.appointments, appDispatch);
    }
  }, [state.appointments]);

  useEffect(() => {
    trackPage();
    trackMpEvent('screen_shown', {
      screen_name: getScreenName(past),
    });
  }, [past]);

  const handleStatusChange = (option: any) => {
    dispatch({ type: 'CHANGE_STATUS', payload: option });
    localStorage.setItem(filter, option.trackingValue);
    trackMpEvent('my_bookings_filter_applied', {
      screen_name: getScreenName(past),
      filter_option: option.trackingValue,
    });
  };

  return (
    <div className="relative min-h-[100px] w-full">
      {state.loading ? (
        <LoadingPlaceHolder />
      ) : (
        <div>
          {(state.appointments.length > 0 || state.status !== filterOptions[0]) && (
            <div className="mb-lg w-full lg:w-1/3">
              <BookingFilter onChange={handleStatusChange} value={state.status} />
            </div>
          )}
          <AppointmentsList appointments={state.appointments} past={past} />
        </div>
      )}
    </div>
  );
};

export default AppointmentsTab;
