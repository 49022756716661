/* eslint-disable */
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import BlogPageHeading from '@/components/modules/pages/blog/BlogPageHeading';
import { TERMS_NAVIGATION_LINKS } from '@/constants/terms';
import { getHomeDescription, isSistaminuten, url } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import useScrollToTop from '@/hooks/useScrollToTop';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { __, _s } from '@/locale';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const baseTranslationKey = 'pages.articles.terms';

const getSEO = () => {
  const title = __(`${baseTranslationKey}.page-title`);
  const description = getHomeDescription();
  const pageUrl = `${url.getBaseUrl()}articles/terms`;
  const imageUrl = url.getBaseImageUrl();
  const isNoIndex = isSistaminuten();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={pageUrl} />

      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      {isNoIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

const Terms = () => {
  const { isMobileView } = useMobileView();

  useTrackScreenView({ name: 'screen_view_terms_landing' });
  useScrollToTop();

  return (
    <PageViewLayout
      type={isMobileView ? 'subView' : 'mainView'}
      {...(isMobileView && { back: true })}
      title={_s(`${baseTranslationKey}.page-title`)}
      footerDisplayOption={{ from: 'sm', to: 'all' }}>
      <BlogPageHeading title={_s(`${baseTranslationKey}.page-title`)} />

      <div className="container">
        <div className="space-y-sm py-lg flex flex-col">
          {TERMS_NAVIGATION_LINKS.map((term) => (
            <Link key={term.label} className="underline" to={term.to}>
              {term.label}
            </Link>
          ))}
        </div>
      </div>
      {getSEO()}
    </PageViewLayout>
  );
};

export default Terms;
