/**
 * @deprecated Use SCREEN_NAME from @/constants/analytics instead
 * following new tracking naming convention
 */
export const SCREEN_NAME = {
  COMPANY_DETAILS: 'company_details',
  BOOKING_CHOOSE_TIME: 'booking_step_choose_time',
  BOOKING_CHECKOUT: 'booking_step_confirm_booking',
  BOOKING_CONFIRMATION: 'booking_confirmation',
  FAVORITES: 'favorites',
  MY_BOOKINGS_FINISHED: 'my_bookings_finished',
  MY_BOOKINGS_UPCOMING: 'my_bookings_upcoming',
  MY_BOOKINGS_DETAILS: 'my_bookings_booking_detail',
  BOOKING_KLARNA_CHECKOUT: 'booking_step_klarna_payment',
  BOOKING_QLIRO_CHECKOUT: 'booking_step_qliro_payment',
  PORTAL: 'portal',
  GOTO: 'goto',
  TERMS: 'terms_and_conditions',
  FAQ: 'faq',
  PAYMENT_METHODS: 'payment_methods',
  ACCOUNT_SETTINGS: 'account_settings',
  MY_PROFILE: 'my_profile',
  SUPPORT: 'support',
  SUPPORT_FORM: 'support_form',
  BLOG: 'blog',
  HOME: 'home',
  FACTS: 'facts_and_advice',
} as const;
