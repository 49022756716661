import { bookActions } from '@/actions';
import IconRow from '@/components/elements/IconRow';
import Tag from '@/components/elements/Tag';
import { Button, Fab, LinkButton } from '@/components/elements/forms/buttons';
import { CloseIconThin, SuccessRingIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { EmployeePicker } from '@/components/modules/employee';
import { ModalContent, ModalDialog, ReadMoreModal } from '@/components/modules/modals';
import {
  capitalizeFirstLetter,
  classnames,
  getCompanyType,
  getEmployeesWhoPerformServices,
  getPlaceTimezone,
  getServiceCampaignIfAny,
  getServiceDuration,
  getServicePrice,
  isMobile,
  isServer,
  isServiceBookable,
  isSistaminuten,
  makeReadableDayShort,
  shouldPickEmployee,
  trackMpEvent,
  url,
} from '@/helpers';
import { themed } from '@/helpers/theme';
import { __ } from '@/locale';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreInfo: false,
      recommendedShown: false,
    };

    this.removeService = this.removeService.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.serviceSelected() && !this.props.services) {
      this.initiateBookingProcess();
    }
  }

  serviceSelected() {
    const selectedServiceId = url.returnGetParameter('ss');
    if (
      isMobile() &&
      selectedServiceId &&
      this.props.service.id &&
      parseInt(selectedServiceId) === this.props.service.id
    ) {
      return true;
    }

    if (
      url.returnGetParameter('rs') &&
      this.props.service.id &&
      parseInt(url.returnGetParameter('rs')) === this.props.service.id
    ) {
      return true;
    }

    return false;
  }

  initiateBookingProcess() {
    const { dispatch, place, service } = this.props;
    dispatch(bookActions.removeEmployee());
    dispatch(bookActions.clearBook());
    dispatch(bookActions.addPlace(place));
    dispatch(bookActions.addService(service, place));
  }

  goToAppointment = (e) => {
    const { dispatch, user, fromEmployee, append, services, time, employee, place, service, source, section } =
      this.props;
    const recommendedService = url.returnGetParameter('rs');
    const employeeService = url.returnGetParameter('ss');
    const employeePicked = url.returnGetParameter('sr');

    const screenName = source ? source : 'company_details';
    const eventName =
      screenName === 'company_details' || screenName === 'favorites' ? 'choose_service_clicked' : 'service_selected';
    const { campaignService } = getServiceCampaignIfAny(service, place.campaigns, [service.id]);
    const eventProps = {
      screen_name: screenName,
      company_id: place.id,
      campaign: campaignService ? 'yes' : 'no',
      company_type: getCompanyType(place),
    };
    if (section) {
      eventProps['services_section'] = section;
    }

    trackMpEvent(eventName, eventProps);

    if (append) {
      dispatch(bookActions.appendService(service, place, time && time.timestamp, services, employee));

      if (this.props.hidePopupCallback) {
        this.props.hidePopupCallback();
      }

      return;
    }

    const shouldPickAddOn =
      !recommendedService &&
      service &&
      service.extra &&
      service.extra.addOnServices &&
      service.extra.addOnServices.length;

    if (!recommendedService) {
      this.initiateBookingProcess();
    }

    if (fromEmployee) {
      dispatch(bookActions.pickEmployee(fromEmployee.id, fromEmployee.about.priceListId));
      dispatch(bookActions.keepEmployee());
    }

    bookActions.clearTrackingProps();

    if (
      !shouldPickAddOn &&
      shouldPickEmployee(this.props) &&
      !isServer &&
      !employeePicked &&
      (!employeeService || parseInt(employeeService, 10) !== service.id)
    ) {
      this.props.history.push({ search: url.changeGetParameter('ss', service.id) });
      return;
    }

    if (shouldPickAddOn) {
      this.props.history.push({ search: url.changeGetParameter('rs', service.id) });
    } else {
      // this.props.history.replace({search: url.changeGetParameter('rs')})
      this.whenReady();
    }
  };

  whenReady = (e) => {
    const { place, service, fromEmployee } = this.props;

    this.props.history.push({
      pathname:
        '/boka-tjanst/' +
        (place.about.slug ? place.about.slug + '-' : '') +
        place.id +
        '/' +
        (service?.about?.slug ? service.about.slug + '-' : '') +
        service.id,
      state: { fromLanding: true, bookingStartingPoint: fromEmployee ? 'person_first' : 'service_first' },
    });
  };

  appendService(service) {
    const { dispatch, services } = this.props;
    dispatch(bookActions.appendExtraService(service, services));
  }

  highlightServiceName(name, filterQuery) {
    if (!filterQuery) {
      return <span className="service-name">{name}</span>;
    }

    return (
      <span className="service-name">
        <Highlighter
          highlightClassName="highlight"
          searchWords={filterQuery.split(' ')}
          autoEscape={true}
          textToHighlight={name}
        />
      </span>
    );
  }

  getServiceHtml(service, recommended, k) {
    const {
      place = {},
      fromEmployee,
      skipButton,
      firstAvailableTime,
      fakeButton,
      filterQuery,
      onBookClick = () => {},
    } = this.props;
    const showBookButton = recommended || (!skipButton && isServiceBookable(service, place));

    if (!showBookButton) {
      return false;
    }

    let priceListId = null;
    if (fromEmployee && fromEmployee.about) {
      priceListId = fromEmployee.about.priceListId;
    } else {
      const performing = getEmployeesWhoPerformServices(place.employees, [service.id]);
      if (performing.length === 1 && performing[0].about) {
        priceListId = performing[0].about.priceListId;
      }
    }

    const { campaignService, campaign } = getServiceCampaignIfAny(service, place.campaigns, [service.id]);
    let price = getServicePrice(service, priceListId, place);

    let endDate = '';
    if (campaignService) {
      const offerPrice = getServicePrice(service, priceListId, place, campaignService);
      if (offerPrice && offerPrice !== price) {
        price = (
          <span className={themed('text-information', 'text-green-sm')}>
            {offerPrice + ' '}
            <s className="text-black-600">{'(ord. ' + price + ')'}</s>
          </span>
        );
      }
      endDate = makeReadableDayShort(campaign.endDate * 1000, getPlaceTimezone(place));
    }

    const serviceDuration = getServiceDuration(service, priceListId);
    const name = capitalizeFirstLetter(isMobile() ? service.name.split('.').join('. ') : service.name);

    let moreInfoProps = undefined;
    if (service.about && service.about.description) {
      moreInfoProps = {
        title: name,
        button: !recommended && showBookButton && (
          <Button block label={__('book')} onClick={this.goToAppointment} size="sm" variant="primary" />
        ),
        info: service.about.description,
      };
    }

    const showWellnesscardBadge = (() => {
      const placeAcceptsGiftcard = Boolean(place?.about?.settings?.acceptsGiftCard);
      const acceptsWellnesscard = placeAcceptsGiftcard && service.about?.settings?.healthCareCard;

      if (!acceptsWellnesscard || isSistaminuten()) return false;
      const price = getServicePrice(service, priceListId, place, campaignService);

      if (price === '') return false;

      if (price === __('freePrice')) return false;

      if (price === __('variablePrice')) return false;

      return true;
    })();

    const hasTags = campaignService || showWellnesscardBadge;

    return (
      <li
        key={k}
        onClick={showBookButton ? (recommended ? () => this.appendService(service) : this.goToAppointment) : null}
        className={classnames('!last-of-type:border-none ', showBookButton && 'hover:bg-black-50 cursor-pointer')}>
        <div className="gap-md flex w-full flex-col p-[10px]">
          <div className={classnames('gap-md !flex justify-between', hasTags ? 'items-start' : 'items-start')}>
            <div className="flex max-w-sm flex-col">
              {!isSistaminuten() && (
                <div className="space-x-sm mb-xs">
                  {campaignService && (
                    <Tag size="large" variant="campaign">
                      {__('campaignOffer')}
                    </Tag>
                  )}
                  {showWellnesscardBadge && (
                    <Tag size="large" variant="wellness">
                      {__('wellness')}
                    </Tag>
                  )}
                </div>
              )}
              {isSistaminuten() && (
                <div className="mb-4 sm:mb-2">
                  <Tag variant="discount" className="bg-sm_secondary !h-[26px] !w-[70px] !text-white">
                    {place && place.about && place.about.sistaminutenDiscount + '%'}
                  </Tag>
                </div>
              )}

              {this.highlightServiceName(name, !recommended ? filterQuery : undefined)}
              <div className="gap-xs mb-1 mt-1 flex flex-col md:flex-row">
                <span className="text-black-600 text-sm">
                  {serviceDuration}
                  {price && serviceDuration && ', '}
                  {price && <span className="text-inherit">{price}</span>}
                </span>
                {(firstAvailableTime || (moreInfoProps && (price || serviceDuration))) && (
                  <span className="hidden text-sm md:inline-block">&nbsp;&middot;&nbsp;</span>
                )}
                {!firstAvailableTime && moreInfoProps && (
                  <ReadMoreModal className="text-sm" modalProps={{ ...moreInfoProps }} />
                )}
                {firstAvailableTime && <span className="text-success-700 text-sm">{firstAvailableTime}</span>}
              </div>
              {endDate && (
                <span className={`block text-sm leading-6 ${themed('text-information', 'text-green-sm')}`}>
                  {__('appliedUntil') + endDate}
                </span>
              )}
            </div>
            {!!fakeButton && (
              <Button
                label={__(!recommended ? 'book' : 'add')}
                onClick={(e) => {
                  onBookClick();
                  e.preventDefault();
                  e.stopPropagation();
                  this.goToAppointment(e);
                }}
                size="sm"
                variant={recommended ? 'primary' : 'secondary'}
                title={__('book') + ' ' + name}
              />
            )}
            {!fakeButton && showBookButton && (
              <LinkButton
                label={__(!recommended ? 'book' : 'add')}
                onClick={(e) => {
                  e.preventDefault();
                }}
                size="sm"
                variant="primary"
                to={
                  '/boka-tjanst/' +
                  (place.about.slug ? place.about.slug + '-' : '') +
                  place.id +
                  '/' +
                  (service.about?.slug ? service.about.slug + '-' : '') +
                  service.id
                }
                title={__('book') + ' ' + name}
              />
            )}
          </div>
        </div>
      </li>
    );
  }

  close = (e) => {
    const { dispatch } = this.props;
    this.props.history.replace({ search: url.changeGetParameter('ss') });
    dispatch(bookActions.removeEmployee());
  };

  pickEmployeeHml() {
    return (
      <ModalDialog isOpen={true} appElement={document.getElementById('root')} onRequestClose={this.close}>
        <ModalContent size="fullscreen">
          <div>
            <div className="flex w-full justify-end p-4">
              <Fab icon={<Icon variant="close" />} onClick={this.close} size="md" variant="link" aria-label="Close" />
            </div>
            <EmployeePicker noButtons={true} callback={this.whenReady} />
          </div>
        </ModalContent>
      </ModalDialog>
    );
  }

  recommendedServices(key) {
    const { services: propsServices, service, employee, place } = this.props;
    const services = propsServices || [];
    const { addOnServices } = service.extra || {};
    const serviceIds = services.map((se) => se.id);

    const employeeData = employee
      ? place.employees.filter((empl) => empl.id === employee)
      : getEmployeesWhoPerformServices(place.employees, serviceIds);
    const filteredByEmployee = addOnServices.filter(
      (s) =>
        employeeData && employeeData[0] && employeeData[0].services && employeeData[0].services.indexOf(s.id) !== -1,
    );

    const remainingAddOn = (employee ? filteredByEmployee : addOnServices)
      .filter((s) => serviceIds.indexOf(s.id) === -1)
      .sort((a, b) => {
        return ('' + a.name).localeCompare(b.name);
      });

    const closeRecommendedServices = () => {
      const { dispatch } = this.props;
      dispatch(bookActions.removeService(service.id));
      this.props.history.replace({ search: url.changeGetParameter('rs') });
    };

    return (
      <ModalDialog
        isOpen={true}
        className=""
        appElement={document.getElementById('root')}
        onRequestClose={closeRecommendedServices}>
        <ModalContent size="lg">
          <div>
            <span className="mr-3 mt-3 flex justify-end">
              <Fab icon={<Icon variant="close" />} onClick={closeRecommendedServices} size="md" variant="link" />
            </span>
            <h2 className="mb-4 px-6 font-semibold">{__('service', { count: services.length })}</h2>
            <div className="flex flex-col items-start justify-between px-6 pb-4 md:flex-row">
              {this.selectedServices(services)}
              <div className="flex w-full justify-end md:w-auto">
                <Button className="ml-auto" onClick={this.goToAppointment} size="sm" variant="primary">
                  {__('continue')}&nbsp;
                  <span style={{ fontSize: 15, textTransform: 'lowercase', fontWeight: 'normal' }}>
                    ({__('service', { count: services.length })})
                  </span>
                </Button>
              </div>
            </div>
            {remainingAddOn && remainingAddOn.length > 0 && (
              <div
                className="services p-6"
                style={{ margin: 0, borderTop: 1, borderTopColor: '#F4F4F6', borderTopStyle: 'solid' }}>
                <h2 className="font-semibold">{__('recommendedServices')}</h2>
                {remainingAddOn && (
                  <ul>
                    {remainingAddOn.map((recommendedService, k) => {
                      return this.getServiceHtml(recommendedService, true, k);
                    })}
                  </ul>
                )}
              </div>
            )}
          </div>
        </ModalContent>
      </ModalDialog>
    );
  }

  removeService(serviceId) {
    const { dispatch, time, services, employee, place } = this.props;
    trackMpEvent('service_removed', {
      screen_name: 'booking_step_choose_time',
      company_id: place.id,
    });

    dispatch(bookActions.popService(serviceId, time && time.timestamp, services, employee, place, true));
  }

  selectedServices(services) {
    const { place, fromEmployee } = this.props;
    const priceId = fromEmployee && fromEmployee.about && fromEmployee.about.priceListId;
    return (
      <div className="w-full flex-1">
        {services.map((service, key) => {
          let price = getServicePrice(service, priceId, place);
          const { campaignService } = getServiceCampaignIfAny(service, place.campaigns, [service.id]);
          if (campaignService) {
            const offerPrice = getServicePrice(service, priceId, place, campaignService);
            if (offerPrice && offerPrice !== price) {
              price = (
                <span className="offer-price">
                  {offerPrice + ' '}
                  <s>{'(ord. ' + price + ')'}</s>
                </span>
              );
            }
          }

          const serviceDuration = getServiceDuration(service, priceId, 1);
          const showTooltip = service.about && service.about.description;
          const { isAddOn } = service.about.settings || {};

          const shouldShowCancel =
            isAddOn ||
            services.filter((s) => !(s && s.about && s.about.settings && s.about.settings.isAddOn)).length > 1;
          return (
            <div key={key} className="flex flex-col pb-2 last-of-type:pb-4">
              <IconRow
                icon={<SuccessRingIcon className={`h-4 w-4 ${isAddOn ? 'invisible' : ''}`} />}
                iconPosition="top"
                className="w-full"
                tag="div">
                <div className="flex w-full items-start">
                  <div className="flex-1 pr-2">
                    <h6>{capitalizeFirstLetter(service.name)}</h6>
                    <div className="text-black-600 text-sm">
                      <span>{serviceDuration}</span>
                      {price && serviceDuration && ', '}
                      {price && <span>{price}</span>}
                      {showTooltip && (price || serviceDuration) && <span>&nbsp;&middot;&nbsp;</span>}
                      {showTooltip && (
                        <ReadMoreModal modalProps={{ title: service.name, info: service.about.description }} />
                      )}
                    </div>
                  </div>
                  {shouldShowCancel && (
                    <span
                      className="mt-1 cursor-pointer"
                      onClick={() => {
                        this.removeService(service.id);
                      }}>
                      <CloseIconThin className=" text-black-600 h-5 w-5 !border-none p-1" />
                    </span>
                  )}
                </div>
              </IconRow>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { append, service } = this.props;
    const selectedServiceId = url.returnGetParameter('ss');
    const recommendedService = url.returnGetParameter('rs');

    if (!selectedServiceId && recommendedService && parseInt(recommendedService, 10) === this.props.service.id) {
      return this.recommendedServices();
    }

    if (isMobile() && !append && selectedServiceId && parseInt(selectedServiceId, 10) === this.props.service.id) {
      return this.pickEmployeeHml();
    }

    return this.getServiceHtml(service);
  }
}

function mapStateToProps(state) {
  const { user } = state.users;
  const { services, time, employee } = state.book;

  return { user, services, time, employee };
}

const Service = connect(mapStateToProps)(Services);
export default withRouter(Service);
