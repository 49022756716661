/* eslint-disable */
import { BlogPage } from '@/components/modules/pages/blog';
import { getHomeDescription, isSistaminuten, trackMpEvent, trackPage, url } from '@/helpers';
import { __ } from '@/locale';
import { Helmet } from 'react-helmet';

const getSeo = () => {
  const title = __('seo.aboutTitle');
  const description = getHomeDescription();
  const pageUrl = `${url.getBaseUrl()}articles/about`;
  const imageUrl = url.getBaseImageUrl();
  const isNoIndex = isSistaminuten();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={pageUrl} />

      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      {isNoIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

const trackPageVisit = () => {
  trackPage();
  // trackMpEvent('screen_shown', { screen_name: 'about' });
};

const About = () => (
  <BlogPage
    pageId="22"
    pageTitle={__('aboutBokadirekt')}
    pageClassName="aboutContent"
    seo={getSeo()}
    trackPage={trackPageVisit}
  />
);

export default About;
