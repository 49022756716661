import { classnames } from '@/helpers';
import { twMerge } from 'tailwind-merge';

export type HeaderProps = {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  label?: string;
  h?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const SIZE_CLASSES = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-md',
  lg: 'text-lg',
  xl: 'text-xl',
};

const Header = ({ leftIcon, rightIcon, children, label, className, size, h = 'span' }: HeaderProps) => {
  const Tag = h as keyof JSX.IntrinsicElements;
  return (
    <span className="space-x-md inline-flex w-full">
      {leftIcon && <span>{leftIcon}</span>}
      <Tag className={twMerge(classnames('text-black-900 flex-1 font-semibold', SIZE_CLASSES[size]), className)}>
        {label ?? children}
      </Tag>
      {rightIcon && <span>{rightIcon}</span>}
    </span>
  );
};

export default Header;
