export function capitalizeFirstLetter(string) {
  if (!string || !string.length) {
    return '';
  }
  const toString = string.toString();
  return toString.charAt(0).toUpperCase() + toString.slice(1);
}

/**
 * @param {any} val
 * @returns {boolean}
 */
export const isString = (val) => typeof val === 'string' || val instanceof String;
