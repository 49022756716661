import { _s } from '@/locale';
import { getSvgPath } from 'figma-squircle';
import { ChangeEvent, useEffect, useReducer, useRef } from 'react';
import css from './PinInput.module.scss';

const pinContainerStyles = (width: number, height = 78) => {
  const path = getSvgPath({
    width,
    height,
    cornerRadius: 12,
    cornerSmoothing: 1,
  });
  return { width, height, clipPath: `path('${path}')` };
};

const isValidDigit = (input: string | number) => !isNaN(Number(input)) && /^[0-9\b]+$/.test('' + input);

type UsePinInputProps = {
  fields?: 4 | 6;
  validateCallback: (pin: string[]) => Promise<boolean>;
};

type PinInputState = {
  pin: string[];
  activePinIndex: number;
  error: boolean;
  submitting: boolean;
  clipError: boolean;
  styles: React.CSSProperties;
};

type PinInputAction =
  | { type: 'SET_PIN'; payload: { pin: string[] } }
  | { type: 'SET_ACTIVE_PIN_INDEX'; payload: { index: number } }
  | { type: 'SET_ERROR'; payload: { error: boolean } }
  | { type: 'SET_SUBMITTING'; payload: { submitting: boolean } }
  | { type: 'SET_CLIP_ERROR'; payload: { clipError: boolean } }
  | { type: 'SET_STYLES'; payload: { styles: React.CSSProperties } };

const pinInputReducer = (state: PinInputState, action: PinInputAction): PinInputState => {
  switch (action.type) {
    case 'SET_PIN': {
      const { pin } = action.payload;
      return { ...state, pin };
    }
    case 'SET_ACTIVE_PIN_INDEX': {
      const { index } = action.payload;
      const activePinIndex = Math.max(Math.min(index, state.pin.length - 1), 0);
      return { ...state, activePinIndex };
    }
    case 'SET_ERROR': {
      const { error } = action.payload;
      return { ...state, error };
    }
    case 'SET_SUBMITTING': {
      const { submitting } = action.payload;
      return { ...state, submitting };
    }
    case 'SET_CLIP_ERROR': {
      const { clipError } = action.payload;
      return { ...state, clipError };
    }
    case 'SET_STYLES': {
      const { styles } = action.payload;
      return { ...state, styles };
    }
  }
};

export type UsePinInputResult = ReturnType<typeof usePinInput>;

export const usePinInput = ({ fields = 4, validateCallback }: UsePinInputProps) => {
  const pinContainerRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>();
  const [state, dispatch] = useReducer(pinInputReducer, {
    pin: Array.from({ length: fields }, () => ''),
    activePinIndex: 0,
    error: false,
    submitting: false,
    clipError: false,
    styles: {},
  });

  useEffect(() => {
    const handleValidate = async () => {
      dispatch({ type: 'SET_SUBMITTING', payload: { submitting: true } });

      const isValid = await validateCallback(state.pin);
      if (!isValid) {
        dispatch({ type: 'SET_ERROR', payload: { error: true } });
        dispatch({ type: 'SET_SUBMITTING', payload: { submitting: false } });
      }
    };

    if (state.pin.every((input) => input)) {
      handleValidate();
    }
  }, [state.pin]);

  useEffect(() => {
    dispatch({ type: 'SET_PIN', payload: { pin: Array.from({ length: fields }, () => '') } });
  }, [fields]);

  useEffect(() => {
    if (pinContainerRef.current) {
      const { width } = pinContainerRef.current.getBoundingClientRect();
      dispatch({ type: 'SET_STYLES', payload: { styles: pinContainerStyles(width) } });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleRezise);
    return () => {
      window.removeEventListener('resize', handleRezise);
    };
  }, []);

  useEffect(() => {
    if (state.activePinIndex === fields) {
      dispatch({ type: 'SET_ACTIVE_PIN_INDEX', payload: { index: fields - 1 } });
    }
    if (state.activePinIndex < 0) {
      dispatch({ type: 'SET_ACTIVE_PIN_INDEX', payload: { index: 0 } });
    }

    inputRef.current?.focus();
  }, [state.activePinIndex]);

  const handleRezise = () => {
    const pinRef = pinContainerRef.current;
    if (!pinRef) return;
    const { width } = pinRef.getBoundingClientRect();
    dispatch({ type: 'SET_STYLES', payload: { styles: pinContainerStyles(width) } });
  };

  const handlePinChange = ({ key }: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const updated = [...state.pin];
    if (key === 'Backspace') {
      handleBackspace(index);
      return;
    }

    if (!isValidDigit(key)) return;

    updated[index] = key;
    dispatch({ type: 'SET_PIN', payload: { pin: updated } });
    dispatch({ type: 'SET_ACTIVE_PIN_INDEX', payload: { index: index + 1 } });
    dispatch({ type: 'SET_ERROR', payload: { error: false } });
  };

  const handleBackspace = (index) => {
    dispatch({ type: 'SET_ACTIVE_PIN_INDEX', payload: { index: index - 1 } });
    const updated = [...state.pin];
    updated[index] = '';
    dispatch({ type: 'SET_PIN', payload: { pin: updated } });
  };

  const handleFocus = (index: number) => {
    dispatch({ type: 'SET_ACTIVE_PIN_INDEX', payload: { index } });
    dispatch({ type: 'SET_ERROR', payload: { error: false } });
    dispatch({ type: 'SET_CLIP_ERROR', payload: { clipError: false } });
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length >= 6) {
      handlePaste(value);
      e.target.value = '';
    }
  };

  const handlePaste = (clipboard: string) => {
    if (!clipboard) return;

    const splitClipboard = clipboard.split('');

    if (splitClipboard.length !== fields || !splitClipboard.every(isValidDigit)) {
      dispatch({ type: 'SET_CLIP_ERROR', payload: { clipError: true } });
      return;
    }

    dispatch({ type: 'SET_PIN', payload: { pin: splitClipboard } });
    dispatch({ type: 'SET_ACTIVE_PIN_INDEX', payload: { index: state.pin.length } });
  };

  return {
    pin: state.pin,
    activePinIndex: state.activePinIndex,
    error: state.error,
    submitting: state.submitting,
    clipError: state.clipError,
    styles: state.styles,
    pinContainerRef,
    inputRef,
    handlePinChange,
    handleFocus,
    handleOnChange,
    handlePaste,
  };
};

const PinInput = ({
  activePinIndex,
  clipError,
  error,
  handleFocus,
  handlePaste,
  handleOnChange,
  handlePinChange,
  inputRef,
  pin,
  pinContainerRef,
  styles,
  submitting,
}: ReturnType<typeof usePinInput>) => {
  return (
    <div>
      <div className="w-full" ref={pinContainerRef}>
        <div className="flex w-full flex-row gap-[2px] bg-white" style={styles}>
          {pin.map((_, index) => (
            <div
              key={index}
              className={`${css.pin} border-black-600 bg-black-100 relative flex h-[78px] flex-1 items-center justify-center text-[58px]`}>
              <input
                className={`spin-button-none relative m-0 flex h-full w-full max-w-[80px] items-center justify-center bg-transparent text-transparent caret-transparent outline-none`}
                id={`pin-${index}`}
                ref={index === activePinIndex ? inputRef : null}
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength={1}
                autoComplete="one-time-code"
                disabled={submitting}
                max={9}
                data-valid={!!pin[index]}
                onKeyDown={(e) => handlePinChange(e, index)}
                onFocus={() => handleFocus(index)}
                onPasteCapture={(e) => handlePaste(e.clipboardData.getData('text'))}
                onChange={handleOnChange}
                defaultValue={pin[index] || ''}
              />
              <label
                className={`pointer-events-none absolute m-0 flex h-full w-full items-center justify-center ${
                  error || clipError ? 'text-danger' : 'text-black-900'
                }`}
                htmlFor={`pin-${index}`}>
                {pin[index] || ''}
              </label>
            </div>
          ))}
        </div>
      </div>
      {(error || clipError) && <span className="text-danger text-xxs">{_s('PinInput.error')}</span>}
    </div>
  );
};

export default PinInput;
