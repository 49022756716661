import Icon from '@/components/icons/Icon';
import { IconProps } from '@/components/icons/types';
import { twMerge } from 'tailwind-merge';

export type LabelProps = {
  icon?: Pick<IconProps, 'variant' | 'color'>;
  label: string;
  className?: string;
};

const Label = ({ icon, label, className }: LabelProps) => {
  return (
    <div
      className={twMerge(
        'bg-secondary-500 space-x-xs py-xs px-sm inline-flex items-center rounded-sm text-white',
        className,
      )}>
      {icon && <Icon variant={icon.variant} size="xs" color={icon.color || 'black-50'} />}
      {label && <span className="text-xxs text-current">{label}</span>}
    </div>
  );
};
export default Label;
