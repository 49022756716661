export const EVENT_NAME = {
  LOGIN_CONTINUE_CLICK: 'login_continue_click',
  LOGIN_CONTINUE_AS_GUEST_CLICK: 'login_continue_as_guest_click',
  CHECKOUT_SHOW_MORE_PAYMENT_OPTIONS_CLICK: 'checkout_show_more_payment_options_click',
  VALIDATION_FAIL: 'validation_fail',
  TYPEFORM_FIRST_STEP_CLICK: 'typeform_first_step_click',
  TYPEFORM_SUBMIT_CLICK: 'typeform_submit_click',
  CHANGE_BOOKING_TIME_SUCCESS: 'change_booking_time_success',
  CONFIRM_DELETE_PAYMENT_CARD_CLICK: 'confirm_delete_payment_card_click',
  ADD_PAYMENT_CARD_CLICK: 'add_payment_card_click',
  SAVE_PAYMENT_CARD_DETAILS_CLICK: 'save_payment_card_details_click',
  NEW_DOWNLOAD_APP_BANNER_CLICK: 'new_download_app_banner_click',
} as const;

/**
 * When a value is absent, it is represented by BLANK_VALUE.
 */
export const BLANK_VALUE = 'blank' as const;

export const CHARGE_ADJUSTMENT = {
  UNIVERSAL_GIFTCARD: 'universal_giftcard',
  WELLNESS_CARD: 'wellness_card',
  VALUE_CARD: 'value_card',
  BUNDLE: 'bundle',
} as const;

export const PAYMENT_METHOD = {
  PAY_IN_SALON: 'pay_in_salon',
  KLARNA: 'klarna',
  QLIRO: 'qliro',
  CARD: 'card',
  GOOGLE_PAY: 'google_pay',
  APPLE_PAY: 'apple_pay',
  SWISH: 'swish',
} as const;

export const SCREEN_NAME = {
  CHECKOUT_BOOKING_SUMMARY: 'checkout_booking_summary',
  CHECKOUT_BUNDLE_SUMMARY: 'checkout_bundle_summary',
  CHECKOUT_BUNDLE_SWISH_SUMMARY: 'checkout_bundle_swish_summary',
  CHECKOUT_BOOKING_KLARNA_SUMMARY: 'checkout_booking_klarna_summary',
  CHECKOUT_BOOKING_QLIRO_SUMMARY: 'checkout_booking_qliro_summary',
  CHECKOUT_BOOKING_SWISH_SUMMARY: 'checkout_booking_swish_summary',
  CHECKOUT_BUNDLE_QLIRO_SUMMARY: 'checkout_bundle_qliro_summary',
  MY_PROFILE: 'my_profile',
  UPDATE_PROFILE: 'update_profile',
  UPDATE_PROFILE_SUCCESS: 'update_profile_success',
  UPDATE_PROFILE_FAIL: 'update_profile_fail',
  SELECT_PAYMENT_METHOD: 'select_payment_method',
  SELECT_PAYMENT_CARD: 'select_payment_card',
  CHECKOUT_ADD_GIFT_CARD: 'checkout_add_gift_card',
  CHECKOUT_ADD_WELLNESS_CARD: 'checkout_add_wellness_card',
  CHECKOUT_ADD_VALUE_CARD: 'checkout_add_value_card',
  ADD_PAYMENT_CARD_DETAILS: 'add_payment_card_details',
  MANAGE_PAYMENT_CARD: 'manage_payment_card',
  DELETE_PAYMENT_CARD: 'delete_payment_card',
  DELETE_PAYMENT_CARD_SUCCESS: 'delete_payment_card_success',
  DELETE_PAYMENT_CARD_ERROR: 'delete_payment_card_error',
  CHECKOUT_BOOKING_CONFIRMATION: 'checkout_booking_confirmation',
  CHECKOUT_BUNDLE_CONFIRMATION: 'checkout_bundle_confirmation',
  PRACTITIONER_TERMS: 'practitioner_terms',
  GENERAL_TERMS: 'general_terms',
  CHECKOUT_ADD_TO_CALENDAR: 'checkout_add_to_calendar',
  OTP_LOGIN_VERIFY: 'otp_login_verify',
  LOGIN_START: 'login_start',
  LOGIN_FAIL: 'login_fail',
  LOGIN_SUCCESS: 'login_success',
  CREATE_PROFILE_STEP_1: 'create_profile_1/2',
  CREATE_PROFILE_STEP_2: 'create_profile_2/2',
  ACTIVATE_LOCATION: 'activate_location',
  CHECKOUT_BOOKING_UPDATE_PAYMENT_METHOD: 'checkout_booking_update_payment_method',
  CHECKOUT_BOOKING_KLARNA_UPDATE_PAYMENT_METHOD: 'checkout_booking_klarna_update_payment_method',
  CHECKOUT_BOOKING_QLIRO_UPDATE_PAYMENT_METHOD: 'checkout_booking_qliro_update_payment_method',
  CHECKOUT_BOOKING_SWISH_UPDATE_PAYMENT_METHOD: 'checkout_booking_swish_update_payment_method',
  CHECKOUT_BOOKING_UPDATE_PAYMENT_METHOD_CONFIRMATION: 'checkout_booking_update_payment_method_confirmation',
  PROMPT_OPTIONAL_EMAIL_FIELD: 'prompt_optional_email_field',
  EMAIL_REQUIRED_LOGIN: 'email_required_login',
  BOOKING_DETAILS: 'booking_details',
  BUNDLE_DETAILS: 'bundle_details',
  PARTICIPANT_DETAILS: 'participant_details',
  MY_BUNDLE_OVERVIEW: 'my_bundles_overview',
  CANCEL_BOOKING: 'cancel_booking',
  TERMS_LANDING: 'terms_landing',
  PAYMENT_METHODS: 'payment_methods',
  NEW_DOWNLOAD_APP_BANNER: 'new_download_app_banner',
  HOME: 'home',
  FAVORITES: 'favorites',
  MY_BOOKINGS_UPCOMING: 'my_bookings_upcoming',
  MY_BOOKINGS_FINISHED: 'my_bookings_finished',
  PLACE_LANDING: 'place_landing',
} as const;

export const BANNER_TYPE = {
  GENERAL_BANNER: 'general_banner',
} as const;
