import { ContentBlock, ContentObjectBlock, isHeadingBlock, parseBlock } from '@/helpers/content';
import { _a } from '@/locale';

const bottomMargin = (block: ContentBlock): string =>
  isHeadingBlock(block) && (block as ContentObjectBlock).type !== 'h1' ? 'mb-1' : 'mb-8';

/**
 * adding additional associations here will require adding the content to the translation files
 */
export const associationsWithContent = ['rebs'];
export const associationTranslationKeyName = (name: string) => name.toLowerCase().replace(/\s/g, '-');

const AssociationContent = ({ name }: { name: string }) => {
  const blocks = _a(`associationContent.${associationTranslationKeyName(name)}.modal.blocks`) as ContentBlock[];
  if (!Array.isArray(blocks)) return null;
  return (
    <>
      {blocks.map((block, i) => (
        <div key={`block-${i}`} className={bottomMargin(block)}>
          {parseBlock(block, i)}
        </div>
      ))}
    </>
  );
};

export default AssociationContent;
