import { __ } from '@/locale';
import { withRouter } from 'react-router-dom';
import { WarningIcon } from '@/components/icons';

function ReviewUserCard(props) {
  const {
    name,
    email,
    isAdmin,
  } = props.user;

  const { searchEmail } = props;

  const getStatusLabel = (isAdmin) => {
    switch (isAdmin) {
      case 1:
        return 'Admin';
      case 2:
        return 'Manager';
      default:
        return 'None';
    }
  };

  return (
    <div itemProp="user" itemScope className='bg-black-50 block w-full rounded-lg p-6'>
      <div className="flex">
        {!!email ? (
          <div className='flex flex-col'>
            <span itemProp="name" className= 'font-semibold'>
              {name || __('Anonymous')}
            </span>
            <span itemProp="email">email: {email}</span>
            <span itemProp="role">role: {getStatusLabel(isAdmin)}</span>
          </div>
        ) :
          <div className='flex flex-row'>
            <WarningIcon className='relative z-10 right-3 w-5 h-5' />
            <span>User: '<b>{searchEmail}</b>' not found !!!</span>
          </div>
        }
      </div>
    </div>
  );
}
const ReviewUserCardWithRouter = withRouter(ReviewUserCard);
export default ReviewUserCardWithRouter;
