import LoadingSpinner from '@/components/animations/LoadingSpinner/LoadingSpinner';
import PinInput, { UsePinInputResult } from '@/components/elements/forms/PinInput/PinInput';
import { LoginContextType } from '@/hooks/useLogin';
import { _s } from '@/locale';
import { LoginTriggerSource } from '@/types/analytics';
import { useEffect, useState } from 'react';

type VerifyOTPProps = UsePinInputResult &
  Pick<LoginContextType, 'email' | 'handleEmailResendClicked'> & { source: LoginTriggerSource };

const translationKey = 'VerifyOTP';

let abortLoadingSpinner: boolean = false;

const VerifyOTP = ({
  email,
  pin,
  activePinIndex,
  error,
  clipError,
  submitting,
  styles,
  pinContainerRef,
  inputRef,
  handleFocus,
  handlePaste,
  handleOnChange,
  handlePinChange,
  handleEmailResendClicked,
}: VerifyOTPProps) => {
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (submitting) {
      abortLoadingSpinner = false;
      timeout = setTimeout(() => {
        if (abortLoadingSpinner) return;
        setShowLoadingSpinner(true);
      }, 300);
    } else {
      abortLoadingSpinner = true;
      timeout && clearTimeout(timeout);
      setShowLoadingSpinner(false);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [submitting]);

  return (
    <>
      <p className="text-black-600">
        {_s(`${translationKey}.checkInbox`)} 📥 {_s(`${translationKey}.instructions`)}
        <span className="text-black-900 break-words">{email.value}</span>
      </p>
      {showLoadingSpinner && (
        <div className="py-lg relative flex flex-col items-center justify-center">
          <LoadingSpinner loop style={{ width: 80, height: 80 }} />
        </div>
      )}
      {!showLoadingSpinner && (
        <>
          <PinInput
            inputRef={inputRef}
            pinContainerRef={pinContainerRef}
            pin={pin}
            activePinIndex={activePinIndex}
            error={error}
            clipError={clipError}
            submitting={submitting}
            styles={styles}
            handleFocus={handleFocus}
            handlePaste={handlePaste}
            handlePinChange={handlePinChange}
            handleOnChange={handleOnChange}
          />
          <p className="text-black-600 text-xs">
            {_s(`${translationKey}.noEmail`)}{' '}
            <button className="text-black-900 inline underline" onClick={handleEmailResendClicked}>
              {_s(`${translationKey}.resend`)}
            </button>
          </p>
        </>
      )}
    </>
  );
};

export default VerifyOTP;
