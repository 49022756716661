import { Button } from '@/components/elements/forms/buttons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import TopTabBar from '@/components/modules/navigation/TopTabBar/TopTabBar';
import AppointmentsTab from '@/components/modules/pages/bokningar/AppointmentsTab';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import EmptyState from '@/components/templates/EmptyState';
import { isLanding, isServer } from '@/helpers';
import { useAppSelector } from '@/hooks';
import { _s } from '@/locale';
import { getScreenName, handleLoginClick } from '@/services/navigationServices';
import { NavItemIdentifier } from '@/types/navigation';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

const bookingsMenu = [
  {
    label: _s('upcomingShort'),
    to: '/bokningar',
  },
  {
    label: _s('pastShort'),
    to: '/bokningar/tidigare',
  },
];

const Appointments = () => {
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.users);
  const location = useLocation();

  useEffect(() => {
    if (isServer) return;
    window.scrollTo(0, 0);
  }, []);

  const isLogged = Boolean(user && user.token);

  if (!isLogged) {
    return (
      <Fragment key="no-user">
        <PageViewLayout type="mainView" wrapperClass="bg-gradient" footerDisplayOption={{ from: 'md', to: 'all' }}>
          <EmptyState
            title={_s('appointments.emptyState.title')}
            body={_s('appointments.emptyState.body')}
            icon={<img src="/images/illustrations/bookings2.svg" alt="" />}
            cta={
              <Button
                label={_s('appointments.emptyState.button')}
                size="sm"
                onClick={() => handleLoginClick(dispatch, NavItemIdentifier.LogIn, getScreenName(undefined))}
              />
            }
          />
        </PageViewLayout>
      </Fragment>
    );
  }

  return (
    <Fragment key="user">
      <PageViewLayout
        type="mainView"
        headerSlot={
          <div className="mx-auto max-w-md">
            <TopTabBar
              items={bookingsMenu}
              defaultSelected={bookingsMenu.findIndex((item) => item.to === location.pathname)}
            />
          </div>
        }
        footerDisplayOption={{ from: 'md', to: 'all' }}
        wrapperClass="bg-gradient"
        {...(isLanding() && { source: 'landing' })}>
        <div className="pt-lg container max-w-screen-md">
          <Switch>
            <Route exact path="/bokningar" children={<AppointmentsTab key="upcoming" filter="uFilterActive" />} />
            <Route path="/bokningar/tidigare" children={<AppointmentsTab key="past" past filter="uFilterCanceled" />} />
          </Switch>
        </div>
        <SEO {...seoPropsFromBaseString('appointments', '/bokningar')} image={'/images/home-background.jpeg'} />
      </PageViewLayout>
    </Fragment>
  );
};

export default Appointments;
