import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import AssociationContent, {
  associationsWithContent,
} from '@/components/modules/pages/associations/AssociationContent';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import { url } from '@/helpers';
import { Redirect, useParams } from 'react-router-dom';

const AssociationsPage = () => {
  const { name } = useParams();
  if (!associationsWithContent.includes(name)) return <Redirect to="/" />;
  const seo = seoPropsFromBaseString(`associations.${name}`, `${url.getBaseUrl()}associations/${name}`);
  return (
    <PageViewLayout type="subView" back={false} title={seo.title} footerDisplayOption={{ from: 'sm', to: 'all' }}>
      <div className="container py-4">
        <AssociationContent name={name} />
      </div>
      <SEO {...seo} />
    </PageViewLayout>
  );
};

export default AssociationsPage;
