import { bundleActions } from '@/actions';
import { isEmpty, promiseWrapper, trackMpEvent } from '@/helpers';
import { bundleCheckoutTrackingManager } from '@/helpers/checkout';
import { trackScreenView } from '@/hooks/useTrackScreenView';
import useWindowOnUnload from '@/hooks/useWindowOnUnload';
import { userService } from '@/services';
import { CheckoutTracking } from '@/types/analytics';
import { ClientBundle, clientBundleSchema } from '@/types/api/services/users/schema';
import { Dispatch, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';

type BundleConfirmationState = {
  bundle: ClientBundle | null;
  loading: boolean;
  error: boolean;
  trackingProps: CheckoutTracking.Bundle | null;
};

type BundleConfirmationAction =
  | {
      type: 'SET_BUNDLE';
      payload: ClientBundle;
    }
  | {
      type: 'SET_LOADING';
    }
  | {
      type: 'SET_ERROR';
    };

async function fetchAndSetBundle(bundleId: number, dispatch: Dispatch<BundleConfirmationAction>) {
  dispatch({ type: 'SET_LOADING' });

  const { data, error } = await promiseWrapper(userService.getBundleById(bundleId));

  const validateResponse = clientBundleSchema.safeParse(data);

  if (error || !validateResponse.success) {
    dispatch({ type: 'SET_ERROR' });
    return;
  }

  dispatch({ type: 'SET_BUNDLE', payload: validateResponse.data });
}

function getBundleTrackingProps(bundle: ClientBundle): CheckoutTracking.Bundle {
  const trackingProps = bundleCheckoutTrackingManager().getTrackingProps();

  if (isEmpty(trackingProps)) return null;

  trackingProps['booking_bundles_id'] = bundle.id;
  return trackingProps;
}

function bundleConfirmationReducer(
  state: BundleConfirmationState,
  action: BundleConfirmationAction,
): BundleConfirmationState {
  switch (action.type) {
    case 'SET_BUNDLE': {
      return {
        ...state,
        bundle: action.payload,
        loading: false,
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'SET_ERROR': {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}

const useBundleConfirmation = () => {
  const location = useLocation();
  const selectedPaymentMethod = location.state?.selectedPaymentMethod;
  const query = new URLSearchParams(location.search);
  const bundleId = query.get('bundleId') || '';

  const [state, dispatch] = useReducer(bundleConfirmationReducer, {
    bundle: null,
    loading: true,
    error: false,
    trackingProps: null,
  });

  useEffect(() => {
    if (!bundleId) return;
    fetchAndSetBundle(+bundleId, dispatch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!state.bundle) return;

    const trackingProps = getBundleTrackingProps(state.bundle);
    bundleCheckoutTrackingManager().clearTrackingProps();

    if (!trackingProps) return;

    const { booking_bundles_id, bundleProps, paymentMethodsProps, extraProps } = trackingProps;

    trackScreenView({
      name: 'screen_view_checkout_bundle_confirmation',
      properties: { ...bundleProps, ...paymentMethodsProps, ...extraProps },
    });

    trackMpEvent('checkout_success', {
      ...bundleProps,
      ...paymentMethodsProps,
      ...extraProps,
      booking_bundles_id,
      checkout_type: 'bundle',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.bundle]);

  useWindowOnUnload({ fn: () => (() => bundleActions.clearBundleCheckout())(), callOnCleanup: true });

  return {
    bundle: state.bundle,
    loading: state.loading,
    error: state.error,
    selectedPaymentMethod,
  };
};

export default useBundleConfirmation;
