import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { isAndroidPhone, isIPhone, promiseWrapper, url } from '@/helpers';
import { createSession, mapApplePayLineItem } from '@/helpers/applepay';
import { bundleCheckoutTrackingManager, getBundleCheckoutSummary } from '@/helpers/checkout';
import {
  buildBrowserInfo,
  clientInstance,
  getGoogleIsReadyToPayRequest,
  getGooglePayPaymentDataRequest,
} from '@/helpers/googlepay';
import { useAppSelector } from '@/hooks';
import { _s } from '@/locale';
import {
  getValidateRedirectPathname,
  saveCofCheckoutStateToLocalStorage,
} from '@/pages/validate-cof-payment-redirect/ValidateCofPaymentRedirect.hooks';
import { saveSwishCheckoutStateToLocalStorage } from '@/pages/validate-swish-payment-redirect/ValidateSwishPaymentRedirect.hooks';
import { adyenServices, qliroServices, swishServices } from '@/services';
import { CofPaymentData } from '@/types/adyen';
import { CheckoutTracking } from '@/types/analytics';
import {
  CreatePaymentAcceptedResponse as AdyenCreatePaymentAccepted,
  CreatePaymentActionRequiredResponse,
  createPaymentAcceptedResponseSchema,
  createPaymentActionRequiredResponseSchema,
  createPaymentRefusedResponseSchema,
} from '@/types/api/services/adyen';
import { PaymentHistoryResponse } from '@/types/api/services/booking';
import { cofPaymentResponseThreeDSSchema } from '@/types/api/services/booking';
import {
  CreatePaymentResponse as QliroCreatePaymentResponse,
  createPaymentResponseSchema,
} from '@/types/api/services/qliro';
import { ErrorResponse, errorResponseSchema } from '@/types/api/services/schema';
import { SwishPaymentResponse, swishPaymentResponseSchema } from '@/types/api/services/swish';
import {
  CheckoutAPI,
  CheckoutAction,
  CheckoutMissingAction,
  CheckoutSummary,
  SelectedPaymentMethod,
  adyenSelectedPaymentMethodSchema,
  baseCheckoutStateSchema,
} from '@/types/checkout';
import { PaymentCard } from '@/types/paymentcards';
import { BundleState } from '@/types/state/bundle';
import * as Sentry from '@sentry/react';
import { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { baseTranslationKey } from './BundleCheckout';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';

const swishStateSchema = z.object({
  payment: swishPaymentResponseSchema.nullable(),
  notificationChannel: z.any().optional(),
  showQrCode: z.boolean().optional(),
});

type SwishState = z.infer<typeof swishStateSchema>;

export const bundleCheckoutStateSchema = baseCheckoutStateSchema.merge(
  z.object({
    cofThreeDS: cofPaymentResponseThreeDSSchema.nullable().optional(),
    swish: swishStateSchema.nullable().optional(),
    hasActiveCoF: z.boolean(),
    termsAccepted: z.boolean(),
  }),
);

export type BundleCheckoutState = z.infer<typeof bundleCheckoutStateSchema>;

export type CofPaymentSuccessRedirectData = {
  paymentMethod:
    | typeof CHECKOUT_PAYMENT_METHOD.STORED_COF
    | typeof CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY
    | typeof CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
  responseData: AdyenCreatePaymentAccepted;
};

type InitQliroSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.QLIRO;
  responseData: QliroCreatePaymentResponse;
};

type SwishPaymentSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.SWISH;
  responseData: SwishPaymentResponse;
};

type SuccessRedirectData =
  | CofPaymentSuccessRedirectData
  | InitQliroSuccessRedirectData
  | SwishPaymentSuccessRedirectData;

export type SubmitThreeDSContext = SubmitBundlePaymentContext & {
  cofThreeDS: CreatePaymentActionRequiredResponse;
  paymentMethod?:
    | typeof CHECKOUT_PAYMENT_METHOD.STORED_COF
    | typeof CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY
    | typeof CHECKOUT_PAYMENT_METHOD.APPLE_PAY;
};

type SubmitBundlePaymentContext = {
  bundleId: number;
  placeId: number;
  trackingProps?: CheckoutTracking.Bundle;
  successCallback: ({ paymentMethod, responseData }: SuccessRedirectData) => void;
  errorCallback: (error: ErrorResponse) => void;
};

type SubmitCofContext = SubmitBundlePaymentContext;
type SubmitApplePayContext = SubmitBundlePaymentContext;
type SubmitGooglePayContext = SubmitBundlePaymentContext;
type InitQliroContext = SubmitBundlePaymentContext;

type CompleteWalletPaymentContext = SubmitBundlePaymentContext & {
  type: 'googlepay' | 'applepay';
  paymentData: CofPaymentData;
};

type UseBundleBookingsCheckoutManagerResult = {
  cofThreeDS?: CreatePaymentActionRequiredResponse | null;
  bundleCheckoutAPI: () => CheckoutAPI;
  onChangePaymentMethod: (method: SelectedPaymentMethod) => void;
  onDismissSwishQRCode: () => void;
  onCheckoutMissingAction: (action: CheckoutMissingAction) => void;
} & BundleCheckoutState;

type BundleCheckoutAction =
  | CheckoutAction<CheckoutSummary>
  | {
      type: 'ONHOLD_COF';
      payload: {
        cofThreeDS: CreatePaymentActionRequiredResponse;
        trackingProps: CheckoutTracking.Bundle;
      };
    }
  | {
      type: 'ONHOLD_SWISH';
      payload: { swish: SwishState; trackingProps: CheckoutTracking.Bundle };
    }
  | {
      type: 'ERROR_SWISH';
      payload: boolean;
    }
  | {
      type: 'CLOSE_SWISH_QRCODE_MODAL';
    };

function bundleCheckoutReducer(state: BundleCheckoutState, action: BundleCheckoutAction) {
  switch (action.type) {
    case 'UPDATE_SUMMARY': {
      return {
        ...state,
        summary: action.payload,
      };
    }
    case 'SET_PAYMENT_METHOD':
      return {
        ...state,
        selectedPaymentMethod: action.payload,
        missingActions: state.missingActions.filter((a) => a !== 'payment-method'),
      };
    case 'SUCCESS_REDIRECT': {
      return {
        ...state,
        submitting: false,
      };
    }
    case 'SUBMITTING': {
      return {
        ...state,
        submitting: true,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        submitting: false,
        error: action.payload,
        cofThreeDS: null,
        swish: null,
      };
    }
    case 'ONHOLD_COF': {
      const validatedAdyenPaymentMethod = adyenSelectedPaymentMethodSchema.safeParse(state.selectedPaymentMethod);
      const updatedState = { ...state, cofThreeDS: action.payload.cofThreeDS };
      const hasSwish = state.summary.availablePaymentMethods.find(
        (method) => method.type === CHECKOUT_PAYMENT_METHOD.SWISH,
      );

      if (validatedAdyenPaymentMethod.success === false) {
        Sentry.captureException(validatedAdyenPaymentMethod.error);
        return {
          ...state,
          submitting: false,
          error: true,
        };
      }

      saveCofCheckoutStateToLocalStorage({
        cofThreeDS: action.payload.cofThreeDS,
        isAddNewCard: false,
        payLater: !state.summary.isOnlinePaymentRequired && !hasSwish,
        selectedPaymentMethod: validatedAdyenPaymentMethod.data,
      });

      return {
        ...updatedState,
        submitting: false,
      };
    }
    case 'ONHOLD_SWISH': {
      saveSwishCheckoutStateToLocalStorage({
        payment: action.payload.swish.payment,
        trackingProps: action.payload.trackingProps,
      });
      return {
        ...state,
        submitting: false,
        swish: action.payload.swish,
      };
    }
    case 'ERROR_SWISH': {
      return {
        ...state,
        submitting: false,
        swish: null,
      };
    }
    case 'CLOSE_SWISH_QRCODE_MODAL': {
      return {
        ...state,
        submitting: false,
        swish: { ...state.swish, showQrCode: false },
      };
    }
    case 'SET_MISSING_ACTION': {
      const missingActions = Array.from(new Set([...state.missingActions, action.payload]));
      return {
        ...state,
        missingActions,
      };
    }
    default:
      const never: never = action;
      throw new Error(`Unhandled action type: ${never}`);
  }
}

async function handleInitQliroPayment(context: InitQliroContext, dispatch: Dispatch<BundleCheckoutAction>) {
  dispatch({ type: 'SUBMITTING', payload: true });
  const { bundleId: id, placeId } = context;

  const { data, error } = await promiseWrapper(qliroServices.createPayment({ type: 'bundle', id, placeId }));

  const validateResponse = createPaymentResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    context.errorCallback(error);
    return;
  }

  bundleCheckoutTrackingManager().saveTrackingProps(context.trackingProps);
  context.successCallback({ paymentMethod: CHECKOUT_PAYMENT_METHOD.QLIRO, responseData: validateResponse.data });
}

function handleSubmitGooglePay(context: SubmitGooglePayContext, dispatch: Dispatch<BundleCheckoutAction>) {
  return async (final: number) => {
    try {
      const googlePaymentsClient = clientInstance();
      const isReadyToPay = googlePaymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest());

      if (!isReadyToPay) throw new Error('Google pay is not ready to pay');

      dispatch({ type: 'SUBMITTING', payload: true });

      const response = await googlePaymentsClient.loadPaymentData(
        getGooglePayPaymentDataRequest({
          totalPrice: `${final.toFixed(2)}`,
          totalPriceStatus: 'FINAL',
          totalPriceLabel: _s('googlepay.totalPriceLabel.paynow'),
          currencyCode: 'SEK',
          countryCode: 'SE',
          displayItems: [
            ...((): google.payments.api.DisplayItem[] => {
              return [
                {
                  label: _s('googlepay.totalPriceLabel.paynow'),
                  type: 'SUBTOTAL',
                  price: `${final.toFixed(2)}`,
                  status: 'PENDING',
                },
              ];
            })(),
          ],
        }),
      );

      await completeWalletBooking(
        {
          ...context,
          paymentData: {
            paymentMethod: {
              type: 'googlepay',
              googlePayToken: response.paymentMethodData.tokenizationData.token,
              googlePayCardNetwork: response.paymentMethodData.info.cardNetwork,
            },
            browserInfo: buildBrowserInfo(),
            redirectInfo: { returnPath: getValidateRedirectPathname(context.bundleId, 'bundle', context.placeId) },
          },
          type: 'googlepay',
        },
        dispatch,
      );
    } catch (error) {
      // Google pay throws "CANCELED statusCode when user closes the payments sheet"
      if (error.statusCode !== 'CANCELED') {
        context.errorCallback(error);
      }
      dispatch({ type: 'ERROR', payload: true });
    }
  };
}

function handleSubmitApplePay(context: SubmitApplePayContext, dispatch: Dispatch<BundleCheckoutAction>) {
  return async (final: number) => {
    const applePaySession = createSession({
      total: mapApplePayLineItem({ amount: `${final}`, label: 'Bokadirekt', payLater: false }),
    });

    applePaySession.begin();

    applePaySession.onpaymentauthorized = async (payment) => {
      try {
        await completeWalletBooking(
          {
            ...context,
            paymentData: {
              paymentMethod: {
                type: 'applepay',
                applePayToken: payment.payment.token.paymentData,
              },
              redirectInfo: { returnPath: getValidateRedirectPathname(context.bundleId, 'bundle', context.placeId) },
            },
            type: 'applepay',
          },
          dispatch,
          applePaySession,
        );
      } catch (error) {
        context.errorCallback(error);

        applePaySession.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
          errors: [],
        });
        dispatch({ type: 'ERROR', payload: true });
      }
    };
  };
}

async function completeWalletBooking(
  context: CompleteWalletPaymentContext,
  dispatch: Dispatch<BundleCheckoutAction>,
  applePaySession?: ApplePaySession,
) {
  const { type, paymentData, trackingProps } = context;
  const paymentMethod = type === 'googlepay' ? CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY : CHECKOUT_PAYMENT_METHOD.APPLE_PAY;

  const { data, error } = await promiseWrapper(
    adyenServices.createPayment({
      type: 'bundle',
      id: context.bundleId,
      placeId: context.placeId,
      paymentData: {
        ...(() => {
          if (paymentData.paymentMethod.type === 'googlepay') {
            return {
              paymentMethod: {
                type: 'googlepay',
                googlePayToken: paymentData.paymentMethod.googlePayToken,
                googlePayCardNetwork: paymentData.paymentMethod.googlePayCardNetwork,
              },
              browserInfo: paymentData.browserInfo,
              redirectInfo: paymentData.redirectInfo,
            };
          }

          if (paymentData.paymentMethod.type === 'applepay') {
            return {
              paymentMethod: { type: 'applepay', applePayToken: paymentData.paymentMethod.applePayToken },
              browserInfo: paymentData.browserInfo,
              redirectInfo: paymentData.redirectInfo,
            };
          }
        })(),
      },
    }),
  );

  const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

  if (paymentAccepted.success) {
    bundleCheckoutTrackingManager().saveTrackingProps(context.trackingProps);
    applePaySession?.completePayment?.({ status: ApplePaySession.STATUS_SUCCESS });
    context.successCallback({ paymentMethod, responseData: paymentAccepted.data });
    return;
  }

  const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

  if (paymentActionRequired.success) {
    dispatch?.({ type: 'ONHOLD_COF', payload: { cofThreeDS: paymentActionRequired.data, trackingProps } });
    return;
  }

  const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);

  context.errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
  applePaySession?.completePayment?.({ status: ApplePaySession.STATUS_FAILURE });
}

function handleSubmitCoF(context: SubmitCofContext, dispatch: Dispatch<BundleCheckoutAction>) {
  return async (storedPaymentMethodId: string) => {
    const { bundleId, placeId, trackingProps } = context;
    const paymentMethod = CHECKOUT_PAYMENT_METHOD.STORED_COF;

    dispatch?.({ type: 'SUBMITTING', payload: true });

    const { data, error } = await promiseWrapper(
      adyenServices.createPayment({
        type: 'bundle',
        id: bundleId,
        placeId,
        paymentData: {
          paymentMethod: {
            type: 'storedCard',
            storedPaymentMethodId,
          },
          browserInfo: buildBrowserInfo(),
          redirectInfo: { returnPath: getValidateRedirectPathname(context.bundleId, 'bundle', placeId) },
        },
      }),
    );

    const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

    if (paymentAccepted.success) {
      bundleCheckoutTrackingManager().saveTrackingProps(trackingProps);
      context.successCallback({ paymentMethod, responseData: paymentAccepted.data });
      return;
    }

    const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

    if (paymentActionRequired.success) {
      dispatch?.({
        type: 'ONHOLD_COF',
        payload: { cofThreeDS: paymentActionRequired.data, trackingProps },
      });
      return;
    }

    const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);

    context.errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
  };
}

export function handleSubmitThreeDSPayment(context: SubmitThreeDSContext, dispatch?: Dispatch<BundleCheckoutAction>) {
  return async (_state: any) => {
    const { paymentMethod, trackingProps } = context;
    dispatch?.({ type: 'SUBMITTING', payload: true });

    const { data, error } = await promiseWrapper(
      adyenServices.submitDetails({
        type: 'bundle',
        details: _state.data.details,
        paymentData: context.cofThreeDS.adyenPaymentData,
        paymentMethod,
      }),
    );

    const paymentAccepted = createPaymentAcceptedResponseSchema.safeParse(data);

    if (paymentAccepted.success) {
      bundleCheckoutTrackingManager().saveTrackingProps(trackingProps);
      context.successCallback({ paymentMethod, responseData: paymentAccepted.data });
      return;
    }

    const paymentActionRequired = createPaymentActionRequiredResponseSchema.safeParse(data);

    if (paymentActionRequired.success) {
      dispatch?.({
        type: 'ONHOLD_COF',
        payload: { cofThreeDS: paymentActionRequired.data, trackingProps },
      });
      return;
    }

    const paymentRefused = createPaymentRefusedResponseSchema.safeParse(data);

    context.errorCallback(paymentRefused.success ? { clientError: paymentRefused.data.userError } : error);
  };
}

export async function handleSubmitSwishPayment(
  context: SubmitBundlePaymentContext,
  dispatch: Dispatch<BundleCheckoutAction>,
) {
  const { successCallback, errorCallback, trackingProps } = context;
  const paymentMethod = CHECKOUT_PAYMENT_METHOD.SWISH;

  dispatch({ type: 'SUBMITTING', payload: true });

  const { data, error } = await promiseWrapper(
    swishServices.swishPayment({
      type: 'bundle',
      id: context.bundleId,
      placeId: context.placeId,
      ...(isIPhone() || isAndroidPhone()
        ? (() => {
            const validateUrl = new URL(
              `${url.getBaseUrl()}validate-swish-payment-redirect/bundle/${context.bundleId}/${context.placeId}`,
            );
            return { callbackUrl: validateUrl.toString() };
          })()
        : {}),
    }),
  );

  const validateResponse = swishPaymentResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    errorCallback(error);
    return;
  }

  const { qrCode } = validateResponse.data;

  if (!qrCode && !isIPhone() && !isAndroidPhone()) {
    errorCallback({
      clientError: _s(`${baseTranslationKey}.swishModal.snackbar.message`),
      message: 'Qr-code is missing',
      status: 500,
    });
    return;
  }

  const eventSource = swishServices.waitForSwishNotification(validateResponse.data.channel);
  dispatch({
    type: 'ONHOLD_SWISH',
    payload: {
      trackingProps,
      swish: {
        payment: validateResponse.data,
        notificationChannel: eventSource,
        showQrCode: !isIPhone() && !isAndroidPhone(),
      },
    },
  });

  if (isIPhone() || isAndroidPhone()) {
    window.location.href = validateResponse.data.url;
  }

  if (eventSource) {
    eventSource.onmessage = (e) => {
      const swishNotification = JSON.parse(e.data);

      if (swishNotification.success) {
        bundleCheckoutTrackingManager().saveTrackingProps(trackingProps);
        successCallback({ paymentMethod, responseData: { ...validateResponse.data, id: swishNotification.id } });
        eventSource.close();
      } else {
        if (swishNotification.keepAlive) {
          return;
        }

        eventSource.close();
        dispatch({ type: 'ERROR', payload: true });
        toast(
          ({ closeToast }) => (
            <Snackbar
              type="danger"
              label={_s(`${baseTranslationKey}.swishModal.snackbar.message`)}
              action={<button onClick={closeToast}>{_s(`${baseTranslationKey}.swishModal.snackbar.cta`)}</button>}
            />
          ),
          {
            autoClose: 3000,
          },
        );
      }
    };

    eventSource.onerror = () => {
      dispatch({ type: 'ERROR', payload: true });
      errorCallback({ message: 'Could not subscribe to swish notification events', status: 500 });
    };
  }
}

const useBundleCheckoutManager = (
  bundle: BundleState,
  cards: PaymentCard[],
  paymentHistory: PaymentHistoryResponse,
): UseBundleBookingsCheckoutManagerResult => {
  const location = useLocation();
  const history = useHistory();
  const savedCheckoutState = location.state?.savedCheckoutState;

  // experiment with radio buttons
  const usePromotedPaymentMethods =
    useGetAmplitudeExperimentVariant()('promoted-payment-methods-web')?.value === 'variant-options-visible';

  const summary = getBundleCheckoutSummary({
    bundleState: bundle,
    cards,
    paymentHistory: usePromotedPaymentMethods ? paymentHistory : undefined,
  });

  const selectedPaymentMethod = ((): SelectedPaymentMethod => {
    if (savedCheckoutState?.selectedPaymentMethod) {
      const card = cards.find((card) => card.id === savedCheckoutState?.selectedPaymentMethod?.id);
      if (card) {
        return {
          type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
          brand: card.brand,
          id: card.id,
          lastFour: card.lastFour,
        };
      }
      return savedCheckoutState.selectedPaymentMethod;
    }

    if (usePromotedPaymentMethods && summary.preSelectedPaymentMethod) {
      return summary.preSelectedPaymentMethod;
    }

    return { type: CHECKOUT_PAYMENT_METHOD.NONE };
  })();

  const [state, dispatch] = useReducer(
    bundleCheckoutReducer,
    savedCheckoutState
      ? { ...savedCheckoutState, selectedPaymentMethod, submitting: false, cofThreeDS: null, error: false }
      : {
          selectedPaymentMethod,
          summary,
          missingActions: [],
          submitting: false,
          error: false,
          cofThreeDS: null,
          swish: null,
        },
  );

  const handleChangePaymentMethod = (method: SelectedPaymentMethod) => {
    dispatch({ type: 'SET_PAYMENT_METHOD', payload: method });
  };

  const handleDismissSwishQRCode = () => {
    dispatch({ type: 'CLOSE_SWISH_QRCODE_MODAL' });
  };

  const handleOnCheckoutMissingAction = (action: CheckoutMissingAction) => {
    dispatch({ type: 'SET_MISSING_ACTION', payload: action });
  };

  const handleError = (error: unknown) => {
    const errorData = errorResponseSchema.safeParse(error);
    dispatch({ type: 'ERROR', payload: true });

    const displayClientError = (errorMessage?: string) => {
      toast(
        ({ closeToast }) => (
          <Snackbar
            label={errorMessage ?? _s('serverError')}
            type="danger"
            onClose={() => {
              dispatch({ type: 'ERROR', payload: false });
              closeToast();
            }}
          />
        ),
        {
          autoClose: 5000,
        },
      );
    };

    if (!errorData.success) {
      displayClientError();
      return;
    }

    const { clientError } = errorData.data;

    displayClientError(clientError);
  };

  const bundleCheckoutAPI = (): CheckoutAPI => {
    const context: SubmitBundlePaymentContext = {
      bundleId: bundle.bundle.id,
      placeId: bundle.place.id,
      trackingProps: bundleCheckoutTrackingManager().buildTrackingProps(
        bundle,
        state.summary,
        state.selectedPaymentMethod,
      ),
      errorCallback: handleError,
      successCallback: ({ paymentMethod, responseData }) => {
        switch (paymentMethod) {
          case CHECKOUT_PAYMENT_METHOD.STORED_COF:
          case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
          case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
            const selectedPaymentMethod =
              state.selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF
                ? {
                    type: state.selectedPaymentMethod.type,
                    id: state.selectedPaymentMethod.id,
                    brand: state.selectedPaymentMethod.brand,
                    lastFour: state.selectedPaymentMethod.lastFour,
                  }
                : state.selectedPaymentMethod;

            history.push({
              pathname: `/bundles/confirmation`,
              search: `?bundleId=${responseData.id}`,
              state: { ...(selectedPaymentMethod && { selectedPaymentMethod }) },
            });
            break;
          case CHECKOUT_PAYMENT_METHOD.QLIRO: {
            history.push({
              pathname: '/bundles/checkout/qliro',
              state: { qliroOrder: responseData, savedCheckoutState: state },
            });
            break;
          }
          case CHECKOUT_PAYMENT_METHOD.SWISH: {
            history.push({
              pathname: `/bundles/confirmation`,
              search: `?bundleId=${responseData.id}`,
              state: { selectedPaymentMethod: { type: CHECKOUT_PAYMENT_METHOD.SWISH } },
            });
            break;
          }

          default:
            break;
        }
      },
    };
    return {
      submitCoF: handleSubmitCoF(context, dispatch),
      submitGooglePay: handleSubmitGooglePay({ ...context }, dispatch),
      submitApplePay: handleSubmitApplePay({ ...context }, dispatch),
      submitThreeDS: handleSubmitThreeDSPayment({ ...context, cofThreeDS: state.cofThreeDS }, dispatch),
      initQliro: () => handleInitQliroPayment(context, dispatch),
      submitSwish: () => handleSubmitSwishPayment(context, dispatch),
    };
  };

  return {
    summary: state.summary,
    submitting: state.submitting,
    selectedPaymentMethod: state.selectedPaymentMethod,
    missingActions: state.missingActions,
    cofThreeDS: state.cofThreeDS,
    swish: state.swish,
    error: state.error,
    onChangePaymentMethod: handleChangePaymentMethod,
    onDismissSwishQRCode: handleDismissSwishQRCode,
    onCheckoutMissingAction: handleOnCheckoutMissingAction,
    bundleCheckoutAPI,
  };
};

const CheckoutContext = createContext<UseBundleBookingsCheckoutManagerResult>({
  selectedPaymentMethod: { type: 0 },
  summary: null,
  submitting: false,
  error: undefined,
  cofThreeDS: null,
  hasActiveCoF: false,
  termsAccepted: false,
  bundleCheckoutAPI: () => ({
    submitCoF: async () => {},
    submitGooglePay: async () => {},
    submitApplePay: async () => {},
    submitThreeDS: async () => {},
    initQliro: async () => {},
  }),
  onChangePaymentMethod: () => {},
  onDismissSwishQRCode: () => {},
  onCheckoutMissingAction: () => {},
});

export const useBundleCheckout = () => {
  const context = useContext(CheckoutContext);

  if (!context) {
    throw new Error('useCheckout must be used within a CheckoutProvider');
  }

  return context;
};

export const BundleCheckoutProvider = ({
  children,
  cards = [],
  paymentHistory,
}: {
  children: ReactNode;
  cards?: PaymentCard[];
  paymentHistory: PaymentHistoryResponse;
}) => {
  const bundle = useAppSelector((state) => state.bundle);
  return (
    <CheckoutContext.Provider value={useBundleCheckoutManager(bundle, cards, paymentHistory)}>
      {children}
    </CheckoutContext.Provider>
  );
};
