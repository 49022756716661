import { Rating } from '@/components/modules/rating';
import { isMobile, shouldDisplayReviews } from '@/helpers';
import { GiftCardButton, WelcomeText } from './';
import AssociationBadge from './AssociationBadge';

function SalonInfo(props) {
  const { place, noTitle, noAddress, noRating, noGiftcard, inSidebar } = props;
  const {
    about: { associations },
    reviews,
  } = place;
  const { stats: { count, score } = {}, reviewCount = 0 } = reviews || {};

  if (place && place.id) {
    const address = place.contact && place.contact.address ? place.contact.address : null;
    return (
      <div className="py-6">
        {!noTitle && (
          <div className="flex justify-between">
            <h1 className={`text-black-900 mb-2 font-semibold ${inSidebar ? 'text-lg' : 'text-2xl'}`} itemProp="name">
              {place.about.name}
            </h1>
          </div>
        )}
        {shouldDisplayReviews(place) && !noRating && (
          <Rating 
            count={count} 
            rating={score} 
            reviewCount={reviewCount} 
            showRatingText={false} 
            details 
          />
        )}
        {!noAddress && (
          <div
            className="text-black-600 text-sm"
            itemProp="address"
            itemScope
            itemType="http://schema.org/PostalAddress">
            <span itemProp="streetAddress">{address && address.street ? address.street.trim() : ''}</span>
            {address && address.zipcode ? ', ' : ''}
            <span itemProp="postalCode">{address && address.zipcode ? address.zipcode.trim() : ''}</span>
            {address && address.city ? ', ' : ''}
            <span itemProp="addressLocality">{address && address.city ? address.city.trim() : ''}</span>
          </div>
        )}
        <div className="mt-2 block md:hidden">
          {place.about && place.about.welcomeText && <WelcomeText text={place.about.welcomeText} />}
        </div>
        {isMobile() && associations && associations.length > 0 && (
          <div className="my-2 flex flex-col space-y-2">
            {associations.map((association, key) => (
              <div key={key}>
                <AssociationBadge association={association} />
              </div>
            ))}
          </div>
        )}
        <div className="flex justify-center py-4 md:hidden md:justify-start">
          {!noGiftcard && <GiftCardButton place={place} />}
        </div>
      </div>
    );
  }
  return null;
}

export default SalonInfo;
