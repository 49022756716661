import { LinkButton } from '@/components/elements/forms/buttons';
import { BlogLayout } from '@/components/layouts/blog';
import { CategoryNav, FeaturedPost, GiftCardBanner, Heading, Hero, LatestPosts } from '@/components/modules/pages/blog';
import useScrollToTop from '@/hooks/useScrollToTop';
import { _s } from '@/locale';

const BlogHome = () => {
  useScrollToTop();
  return (
    <BlogLayout>
      <Hero />
      <div className="container mb-32 mt-10 md:mt-20">
        <Heading />
        <CategoryNav />
        <FeaturedPost />
        <GiftCardBanner className="my-10 mb-8" />
        <LatestPosts />
        <LinkButton variant="primary" to="/blogg/list">
          {_s('blogPage.viewAllPosts')}
        </LinkButton>
      </div>
    </BlogLayout>
  );
};

export default BlogHome;
