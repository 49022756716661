import Icon from '@/components/icons/Icon';
import { ReactHookFormRegister } from '@/components/modules/checkout/CheckoutFormFields/CheckoutFormFields';
import { LegacyRef, OptionHTMLAttributes, SelectHTMLAttributes, createContext, useContext } from 'react';
import { FieldValues } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

const SelectContext = createContext<{} | undefined>(undefined);

export type SelectProps = {
  children: React.ReactNode;
  innerRef?: LegacyRef<HTMLSelectElement>;
} & SelectHTMLAttributes<HTMLSelectElement> &
  ReactHookFormRegister<FieldValues>;

const Select = ({ id, value, className, innerRef, children, ...selectProps }: SelectProps) => {
  return (
    <SelectContext.Provider value={{}}>
      <div className="relative w-full">
        <select
          className={twMerge(
            `${
              !value || (Array.isArray(value) && value.length === 0) ? 'text-black-600' : 'text-black-900'
            } py-md w-full appearance-none bg-transparent text-sm outline-none disabled:bg-transparent`,
            className,
          )}
          ref={innerRef}
          {...selectProps}>
          {children}
        </select>
        <div className="text-xxs pointer-events-none absolute right-[4px] top-1/2 -translate-y-1/2">
          <Icon variant="chevron-s-down" />
        </div>
      </div>
    </SelectContext.Provider>
  );
};

const Option = ({
  children,
  ...optionProps
}: {
  children: React.ReactNode;
} & OptionHTMLAttributes<HTMLOptionElement>) => {
  const context = useContext(SelectContext);
  if (!context) throw new Error('Option must be used within a Select Component');

  return <option {...optionProps}>{children}</option>;
};

Select.Option = Option;

export default Select;
