import { bookActions, loadingActions, shareActions } from '@/actions';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import ProgressTracker from '@/components/elements/ProgressTracker';
import { StatusMessage } from '@/components/elements/StatusMessage';
import { Button, Fab } from '@/components/elements/forms/buttons';
import { ChevronIcon, LogoIcon, PlusAltIcon, SuccessRingIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { NewCustomerDiscountBanner } from '@/components/modules/banner';
import Breadcrumbs from '@/components/modules/breadcrumbs/Breadcrumbs';
import { EmployeeBox } from '@/components/modules/employee';
import { ModalHeader } from '@/components/modules/mobile';
import { CancelBooking, ModalContent, ModalDialog, ReadMoreModal } from '@/components/modules/modals';
import {
  EmployeePicker,
  LastMinuteWeekPicker,
  MonthPicker,
  ServicePicker,
  WeekPicker,
} from '@/components/modules/pages/bookService';
import { ReadMore } from '@/components/modules/readmore';
import {
  activeUsersCount,
  allowMarketing,
  capitalizeFirstLetter,
  exportIdSlug,
  findEmployee,
  getBookingStartingPoint,
  getCampaignOfferTotal,
  getCompanyType,
  getEmployeesWhoPerformServices,
  getServiceBreadcrumbs,
  getServiceCampaignIfAny,
  getServiceDuration,
  getServicePrice,
  getTotalPrice,
  hasFeatureSetting,
  isMobile,
  isServer,
  isSistaminuten,
  limitString,
  performedServices,
  restoreBodyScroll,
  salonTracking,
  saveBodyScroll,
  server,
  setBookingStartingPoint,
  shouldShowFrom,
  showPrices,
  startOfDay,
  trackMpEvent,
  trackPage,
  trackPageCio,
  url,
} from '@/helpers';
import { themed } from '@/helpers/theme';
import { LoginContext } from '@/hooks/useLogin';
import { __ } from '@/locale';
import { NotFound } from '@/pages/_exports';
import React from 'react';
import { Helmet } from 'react-helmet';
import QRCode from 'react-qr-code';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';

class Book extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showServices: false,
      toCheckout: __('selectHour'),
      changeEmpl: false,
      changeEmplModal: false,
      activeEmployees: props.place ? activeUsersCount(props.place.employees, props) : {},
      showMonthView: false,
      is404: props.is404 || false,
      shared: {
        showModal: false,
      },
    };

    this.removeService = this.removeService.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
  }

  seo() {
    const service = this.props.services && this.props.services[0] ? this.props.services[0] : null;
    const place = this.props.place;
    const serviceName = service && service.name ? service.name : '';
    const salonName = place && place.about && place.about.name ? place.about.name : '';
    const salonAddress =
      (place?.contact?.address?.city || '') +
      (place?.contact?.address?.district ? ' ' + place.contact.address.district : '');
    const title = limitString(__('book') + ' ' + serviceName + ' - ' + salonName + ' - Bokadirekt', 60);
    const description = limitString(
      service && service.about && service.about.description
        ? service.about.description
        : __('seo.serviceDescription') +
            serviceName +
            ' ' +
            __('seo.serviceAt') +
            ' ' +
            salonName +
            ', ' +
            salonAddress,
      155,
    );
    const serviceUrl =
      url.getBaseUrl() +
      'boka-tjanst/' +
      place.about.slug +
      '-' +
      place.id +
      '/' +
      service?.about?.slug +
      '-' +
      service.id;
    const placeUrl = url.getBaseUrl() + 'places/' + place.about.slug + '-' + place.id;
    const isNoIndex =
      hasFeatureSetting(place, 'seo_noindex') || hasFeatureSetting(place, 'seo_noindex_portal') || isSistaminuten();
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={placeUrl} />
        <meta property="og:url" content={serviceUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Bokadirekt" />
        <meta property="og:locale" content="sv_SE" />
        {!isSistaminuten() && <meta name="twitter:site" content="@bokadirekt" />}
        <meta name="robots" content={isNoIndex ? 'noindex' : 'index, follow'} />
      </Helmet>
    );
  }

  componentDidMount() {
    const { dispatch, place, services, employee } = this.props || {};

    const serviceSlugId = exportIdSlug(this.props.match.params.serviceSlugId);
    if (this.checkRetry()) {
      const evProps = bookActions.getTrackingProps();
      delete evProps.transactionProps;

      trackMpEvent('error_shown', {
        error_message: __('retryBooking'),
        error_type: 'retry',
        ...evProps,
      });
    }

    const shared = url.returnGetParameter('shared');
    const serviceIds = url.returnGetParameter('sIds')?.split('-') || [];

    if (
      !place ||
      !services ||
      !services.filter((service) => parseInt(service.id, 10) === parseInt(serviceSlugId.id, 10)).length
    ) {
      if (shared && serviceIds.length > 0) {
        this.addSharedServices();
      } else {
        // There is no service . take the one from url and call the server for details
        this.getService(serviceSlugId);
      }
    } else {
      this.setState({ waiting: false });
      if (employee && !(this.props.location && this.props.location.state && this.props.location.state.retry)) {
        setTimeout(() => {
          const elem = document.querySelector('div.calendar');
          if (elem) {
            elem.parentNode.scrollTop = elem.offsetTop;
          } else {
            window.scrollTo(0, 0);
          }
        }, 1000);
      } else {
        window.scrollTo(0, 0);
      }

      dispatch(loadingActions.show('loadHours'));

      trackMpEvent('screen_shown', this.getTrackingProps('booking_step_choose_time'), false);
      salonTracking(place);

      if (this.sendMarketingData()) {
        trackPage();
        trackPageCio('booking_step_choose_time', this.getCioTrackingProps());
      }

      if (!getBookingStartingPoint()) {
        setBookingStartingPoint('booking_step_choose_time');
      }

      const activeEmployees = activeUsersCount(place.employees, this.props);
      const { employeeCount } = activeEmployees;
      this.setState({ changeEmpl: !isMobile() && employeeCount > 1 ? true : false, activeEmployees });
      this.selectEmployeeIfOne(activeEmployees);

      if (shared && serviceIds.length > 0) {
        this.addSharedServices();
      }
    }
  }

  selectEmployeeIfOne(activeEmployees) {
    const { dispatch, employee } = this.props || {};

    const { employeeCount, users } = activeEmployees;
    const selectedEmployee = this.getEmployee(employeeCount, users);
    if (employeeCount === 1 || (employee && employee === selectedEmployee.id)) {
      dispatch(bookActions.pickEmployee(selectedEmployee.id, selectedEmployee.about.priceListId));
      dispatch(bookActions.keepEmployee());
    }
    const oldEmployeeId = url.returnGetParameter('sr');
    if (oldEmployeeId) {
      const employeeData = this.props.place.employees.filter((e) => e.about.externalId === oldEmployeeId).pop();
      if (employeeData && employeeData.id && employeeData.about && employeeData.about.priceListId) {
        dispatch(bookActions.pickEmployee(employeeData.id, employeeData.about.priceListId));
        dispatch(bookActions.keepEmployee());
      }
    }
  }

  getService(serviceSlugId) {
    const { dispatch, place, appliedBundle, changeTimeBookingId, employee: employeeId } = this.props || {};

    this.setState({ waiting: true });
    dispatch(loadingActions.show('loadHours'));

    // Don't have the details or we have other service/place set
    server.request
      .get('/service/' + serviceSlugId.id + '/' + serviceSlugId.slug)
      .then((result) => {
        if (result.data && result.data.service) {
          dispatch(bookActions.removeEmployee());
          dispatch(bookActions.clearBook());
          dispatch(bookActions.addPlace(result.data.place));
          dispatch(bookActions.addService(result.data.service, result.data.place));

          if (appliedBundle) {
            dispatch(bookActions.applyBundle(appliedBundle));
          }

          if (changeTimeBookingId) {
            const employee = findEmployee(employeeId, result.data.place.employees);

            dispatch(bookActions.changeBookingTime(changeTimeBookingId));
            dispatch(bookActions.pickEmployee(employee?.id || 0, employee?.about?.priceListId));
            dispatch(bookActions.keepEmployee());
          }

          trackMpEvent('screen_shown', this.getTrackingProps('booking_step_choose_time', result.data.place), false);
          salonTracking(place);

          if (this.sendMarketingData(result.data.service)) {
            trackPage();
            trackPageCio('booking_step_choose_time', this.getCioTrackingProps(result.data.place, result.data.service));
          }
        }

        dispatch(loadingActions.hide());
        this.setState({ waiting: false });
      })
      .catch((error) => {
        dispatch(loadingActions.hide());
        this.setState({ is404: true, waiting: false });
      });
  }

  sendMarketingData(service) {
    let services = [];
    if (service?.id) {
      services.push(service);
    } else {
      services = this.props.services || [];
    }
    return allowMarketing(services);
  }

  getTrackingProps(screen, place) {
    if (!place) {
      place = this.props.place || {};
    }

    return {
      screen_name: screen,
      company_id: place?.id,
      company_type: getCompanyType(place),
    };
  }

  getCioTrackingProps(place, service) {
    if (!place) {
      place = this.props.place || {};
    }

    let serviceName = service?.name;
    if (!serviceName) {
      serviceName = (this.props.services || []).map((item) => item.name).join(', ');
    }

    return {
      merchant_id: place?.id,
      merchant_name: place?.about?.name,
      service_name: serviceName,
    };
  }

  getCancelTrackingProps() {
    return {
      ...this.getTrackingProps('booking_step_choose_time'),
      ...{
        number_of_services: this.props.services.length,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { place, availability } = this.props;
    const context = this.context; // LoginContext

    if (prevProps.availability !== availability && place) {
      const activeEmployees = activeUsersCount(place.employees, this.props);
      const { employeeCount } = activeEmployees;

      this.setState({ changeEmpl: !isMobile() && employeeCount > 1 ? true : false, activeEmployees });
      this.selectEmployeeIfOne(activeEmployees);
    }

    const currentIDs = this.props.services ? this.props.services.map((service) => service.id) : [];
    const oldIds = prevProps.services ? prevProps.services.map((service) => service.id) : [];
    if (currentIDs.join('-') !== oldIds.join('-')) {
      this.setState({ showServices: false });
    }
  }

  addRecommendedServices = (e) => {
    this.setState({ showRecommended: true });
  };

  addService = (e) => {
    const { dispatch, employee } = this.props;
    const selectedEmployee = this.getEmployee();
    if (employee) {
      dispatch(bookActions.pickEmployee(selectedEmployee.id, selectedEmployee.about.priceListId));
      dispatch(bookActions.keepEmployee());
    }

    this.setState({ showServices: true });

    // trackMpEvent('screen_shown', this.getTrackingProps('booking_choose_service_prompt'), false);
    if (this.sendMarketingData()) {
      trackPageCio('booking_choose_service_prompt', this.getCioTrackingProps());
    }
  };

  removeService(serviceId) {
    const { dispatch, time, services, employee, place } = this.props;
    trackMpEvent('service_removed', {
      screen_name: 'booking_step_choose_time',
      company_id: place.id,
    });
    dispatch(bookActions.popService(serviceId, time && time.timestamp, services, employee, place));
  }

  getEmployeeName() {
    const { employee, place } = this.props;
    let performerName = __('anyEmployee');
    place.employees.forEach((people) => {
      if (employee === people.id) {
        performerName = people.about.name;
      }
    });
    return performerName;
  }

  hidePopup() {
    this.setState({ showServices: false });
  }

  handleCheckoutClick = (e) => {};

  openBottom = (e) => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  checkRetry = () => {
    let retry = null;
    if (!isServer) {
      if (this.props.location.state && this.props.location.state.retry) {
        window.history.pushState(null, '');

        retry = (
          <div className="my-4">
            <StatusMessage
              type="error"
              message={
                this.props.location.state.retryKlarna
                  ? __('retryBookingKlarna')
                  : this.props.location.state.retryQliro
                  ? __('retryBookingQliro')
                  : __('retryBooking')
              }
            />
          </div>
        );
      }
    }

    return retry;
  };

  handleBackClick = (e) => {
    e.preventDefault();
    this.trackBackButton();
    if (this.props.history.length === 0) {
    } else {
      this.props.history.goBack();
    }
  };

  trackBackButton() {}

  // For alert
  promptAlert = (e) => {
    this.setState({ showAlert: true });
  };

  handleClose = (e) => {
    this.setState({ showAlert: false });
  };

  editEmployee = (e) => {
    if (isMobile()) {
      // trackMpEvent('screen_shown', this.getTrackingProps('booking_choose_person_prompt'), false);
      if (this.sendMarketingData()) {
        trackPageCio('booking_choose_person_prompt', this.getCioTrackingProps());
      }
    }
    saveBodyScroll();
    this.setState({ changeEmplModal: true });
  };
  hideEmployees = (e) => {
    this.setState({ changeEmpl: false, changeEmplModal: false }, () => {
      restoreBodyScroll();
    });
  };

  getHeader(type = 'pickTime', hideSteps = false) {
    return (
      <div className="steps-container">
        <h2 className="font-semibold" style={{ textAlign: 'center' }}>
          {__(type)}
        </h2>
        {!hideSteps && this.getSteps()}
      </div>
    );
  }

  hasDiscount() {
    if (isServer) {
      return null;
    }
    const { place } = this.props;
    const offer = sessionStorage.getItem('ClickedOffer');
    if (offer && place && parseInt(place.id, 10) === parseInt(offer, 10)) {
      return (
        <div className="my-4">
          <NewCustomerDiscountBanner onRemove={this.removeDiscount} variant="simple" />
        </div>
      );
    }
    return null;
  }

  removeDiscount = (e) => {
    sessionStorage.removeItem('ClickedOffer');
    this.setState({ render: !this.state.render });
  };

  getEmployee(count, employeeIds) {
    const { place, services, employee } = this.props;
    let selectedEmployee = null;

    const selected =
      place && place.employees
        ? place.employees.filter(
            (theEmpl) => theEmpl.id === employee || (count === 1 && employeeIds.indexOf(theEmpl.id) !== -1),
          )
        : [];

    if (selected.length > 0) {
      selectedEmployee = selected[0];
    }

    if (!selectedEmployee) {
      selectedEmployee = { id: 0, about: { name: __('anyEmployee'), avatar: '', isBookable: true } };
      selectedEmployee.services = performedServices(services, place.employees);
    }

    return selectedEmployee;
  }

  getLastWorkingDays() {
    const { place } = this.props;
    const { users } = this.state.activeEmployees || {};
    const { bookAhead: placeBookAhead } = place.about.book || 16;
    const lastDays = { last: 0, bookAhead: 0, byEmployee: {} };
    place.employees.forEach((employee) => {
      if (users.indexOf(employee.id) !== -1) {
        const { settings = {} } = employee.about || {};
        const lastWorkingDay = settings.lastDay || 0;
        const bookAhead = settings.bookAhead || placeBookAhead;
        if (lastDays.last < lastWorkingDay) {
          lastDays.last = lastWorkingDay;
        }
        if (lastDays.bookAhead < bookAhead) {
          lastDays.bookAhead = bookAhead;
        }
        lastDays.byEmployee[employee.id] = { lastWorkingDay, bookAhead };
      }
    });

    return lastDays;
  }
  selectedServices(services, inModal = false) {
    // Needed props
    const { place, priceId } = this.props;

    const servicesIds = services.map((service) => service.id);
    const servicesHtml = [];
    let start = Infinity,
      end = 0;
    // Loop each service
    services.forEach((service, key) => {
      const { campaignService, campaign } = getServiceCampaignIfAny(service, place.campaigns, servicesIds);
      let price = getServicePrice(service, priceId, place);
      if (campaignService) {
        const offerPrice = getServicePrice(service, priceId, place, campaignService);
        if (offerPrice && offerPrice !== price) {
          price = (
            <span className={themed('text-information', 'text-green-sm')}>
              {offerPrice + ' '}
              <s>{'(ord. ' + price.replace(__('from'), '') + ')'}</s>
            </span>
          );
        }

        start = Math.min(start, campaign.startDate);
        end = Math.max(end, campaign.endDate);
      }

      const serviceDuration = getServiceDuration(service, priceId, 1);
      const showTooltip = service.about && service.about.description;
      const { isAddOn } = service?.about?.settings || {};
      const shouldShowCancel =
        isAddOn || services.filter((s) => s.about && s.about.settings && !s.about.settings.isAddOn).length > 1;

      servicesHtml.push(
        <div key={key} className="flex flex-col pb-4">
          <div className="flex items-start">
            {inModal && (
              <div className="mr-2 mt-2 h-4 w-4">
                {!isAddOn && <SuccessRingIcon className="text-black-600 h-full w-full" />}
              </div>
            )}
            <div className="flex-1 pr-2">
              <h6>{capitalizeFirstLetter(service.name)}</h6>
              <div className="text-black-600 text-sm">
                <span>{serviceDuration}</span>
                {price && serviceDuration && ', '}
                {price && <span>{price}</span>}
                {showTooltip && (
                  <>
                    &nbsp;&middot;&nbsp;
                    <ReadMoreModal modalProps={{ title: service.name, info: service.about.description }} />
                  </>
                )}
              </div>
            </div>
            {shouldShowCancel && (
              <Fab
                icon={<Icon variant="close" />}
                onClick={() => this.removeService(service.id)}
                size="md"
                variant="link"
              />
            )}
          </div>
        </div>,
      );
    });

    return { servicesHtml, start, end };
  }

  appendService(service) {
    const { dispatch, time, services, employee } = this.props;
    dispatch(bookActions.appendExtraService(service, services));
    dispatch(bookActions.getAvailability([...services, service], time && time.timestamp, employee || 0));
  }

  recommendedServices(selectedEmployee) {
    const { services = [], place } = this.props;
    const mainService =
      services.filter((s) => !(s && s.about && s.about.settings && s.about.settings.isAddOn)).pop() || {};
    const { addOnServices } = mainService.extra || {};
    const serviceIds = services.map((se) => se.id);

    const remainingAddOn = addOnServices
      .filter((s) => serviceIds.indexOf(s.id) === -1)
      .sort((a, b) => {
        return ('' + a.name).localeCompare(b.name);
      });
    const { servicesHtml } = this.selectedServices(services, true);
    return (
      <ModalDialog
        isOpen={true}
        appElement={document.getElementById('root')}
        onRequestClose={() => this.setState({ showRecommended: false })}>
        <ModalContent size="lg">
          <div>
            <span className="mr-3 mt-3 flex justify-end">
              <Fab
                icon={<Icon variant="close" />}
                onClick={() => this.setState({ showRecommended: false })}
                size="md"
                variant="link"
              />
            </span>
          </div>
          <h2 className="mb-4 px-6 font-semibold">{__('service', { count: services.length })}</h2>
          <div className="flex flex-col items-start justify-between px-6 pb-4 md:flex-row">
            <div className="w-full flex-1">{servicesHtml}</div>
            <div className="flex w-full justify-end md:w-auto">
              <Button onClick={() => this.setState({ showRecommended: false })} size="sm" className="ml-auto">
                {__('continue')}&nbsp;
                <span className="text-sm font-normal lowercase">({__('service', { count: services.length })})</span>
              </Button>
            </div>
          </div>
          {remainingAddOn && remainingAddOn.length > 0 && (
            <div
              className="services p-6"
              style={{ margin: 0, borderTop: 1, borderTopColor: '#F4F4F6', borderTopStyle: 'solid' }}>
              <h2 className="mb-4 font-semibold">{__('recommendedServices')}</h2>
              <ul>
                {remainingAddOn.map((recommendedService, k) => {
                  let priceListId = null;
                  if (selectedEmployee && selectedEmployee.about) {
                    priceListId = selectedEmployee.about.priceListId;
                  } else {
                    const performing = getEmployeesWhoPerformServices(place.employees, [mainService.id]);
                    if (performing.length === 1 && performing[0].about) {
                      priceListId = performing[0].about.priceListId;
                    }
                  }
                  const serviceDuration = getServiceDuration(recommendedService, priceListId);
                  let price = getServicePrice(recommendedService, priceListId, place);
                  const name = isMobile()
                    ? capitalizeFirstLetter(recommendedService.name.split('.').join('. '))
                    : capitalizeFirstLetter(recommendedService.name);

                  return (
                    <li
                      key={k}
                      onClick={() => this.appendService(recommendedService)}
                      className="!last-of-type:border-none hover:bg-black-100 !flex cursor-pointer items-start justify-between rounded-lg p-[10px]">
                      <div className="flex flex-1 flex-col pr-2">
                        <span>{name}</span>
                        <span className="text-black-600 flex items-center text-sm">
                          {serviceDuration}
                          {price && serviceDuration && ', '}
                          {price && price}
                        </span>
                      </div>
                      <Button variant="secondary" size="sm" title={__('add') + ' ' + name}>
                        {__('add')}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </ModalContent>
      </ModalDialog>
    );
  }

  getParams() {
    const { place, services, priceId, campaigns, show, loadSource, time = {}, changeTimeBookingId } = this.props;
    const { waiting, changeEmpl, activeEmployees, changeEmplModal } = this.state;
    let selectedTimestamp = parseInt((time.timestamp || startOfDay()) / 1000, 10);
    if (waiting === false && (!place || !services || !services.length)) {
      return { redirect: true };
    }
    if (!isServer && waiting !== false) {
      return {
        loader: <LoadingPlaceHolder text={__('loadingHours')} />,
      };
    }

    const { employeeCount, users } = activeEmployees || {};
    const selectedEmployee = this.getEmployee(employeeCount, users);
    const prompt = this.state.showAlert ? (
      <CancelBooking
        place={place}
        cancel={this.handleClose}
        steps="-1"
        cancelTrackingProps={this.getCancelTrackingProps()}
        source={Boolean(this.props.location.state && this.props.location.state.fromLanding)}
      />
    ) : null;

    let total = getTotalPrice(services, place, priceId, {}, 0, 1, true);
    let offerTotal = getTotalPrice(services, place, priceId, {}, 0, 1, true, campaigns);

    const isPriceVariable =
      shouldShowFrom(services, place) && total !== __('variablePrice') && total !== __('freePrice');
    const shouldShowPrices = showPrices(place, services);

    if (offerTotal !== total) {
      offerTotal = getCampaignOfferTotal(offerTotal, total);
      total = (
        <span className={`text-sm font-semibold ${themed('text-information', 'text-green-sm')}`}>
          {offerTotal + ' '}
          <s className="text-black-600">{'(ord. ' + total + ')'}</s>
        </span>
      );
    }
    const cost = this.getCost(total, isPriceVariable);
    const campaignTotal = shouldShowPrices ? (
      <span className="mb-4 flex items-center">
        {/* fake radio button */}
        <div
          className={`mr-3 h-[16px] w-[16px] min-w-[16px] rounded-full p-1 ${
            isSistaminuten() ? 'bg-primary-50' : 'bg-information-50'
          }`}>
          <div className={`h-full w-full rounded-full ${themed('bg-information', 'bg-sm_secondary')}`}></div>
        </div>
        {/* # fake radio button */}
        <span className="flex-1 text-sm">
          {__('campaignPrice')}&nbsp;{isPriceVariable ? __('from').toLowerCase() : ''}&nbsp;{total}
        </span>
      </span>
    ) : null;
    const servicesRelated = this.pickedServices(selectedEmployee);
    const pickedServicesHtml = servicesRelated.html;
    const campaignStart = servicesRelated.campaignStart;
    const campaignEnd = servicesRelated.campaignEnd;
    const hasCampaign =
      campaignStart && campaignEnd && selectedTimestamp >= campaignStart && selectedTimestamp <= campaignEnd;
    const loader = show && !loadSource ? <LoadingPlaceHolder /> : null;
    const hideEmployees =
      place && place.about && place.about.settings && place.about.settings.hideEmployees ? true : false;
    const getSelectedEmployeeBox = (stickToBottom = false) => (
      <EmployeeBox
        avatarSize={stickToBottom ? 'sm' : 'md'}
        showChecked={true}
        employee={selectedEmployee}
        showNote={!isMobile()}
        showEdit={employeeCount !== 1 && !hideEmployees ? this.editEmployee : null}
      />
    );
    const originalServiceName = services && services.length > 0 ? services[0].name || '' : null;
    const lastWorkingDays = this.getLastWorkingDays();

    const servLength = (selectedEmployee.services && selectedEmployee.services.length) || 0;
    const pickedAddOn = services.filter((f) => f.about && f.about.settings && f.about.settings.isAddOn).length;

    const mainServices = services.filter((s) => !(s && s.about && s.about.settings && s.about.settings.isAddOn));
    const possibleAddOnServices =
      mainServices.length === 1 &&
      mainServices[0].extra &&
      mainServices[0].extra.addOnServices &&
      mainServices[0].extra.addOnServices.length
        ? mainServices[0].extra.addOnServices.filter((s) => services.map((a) => (a ? a.id : null)).indexOf(s.id) === -1)
        : [];

    const showAddMoreService = !pickedAddOn && servLength > 1 && servLength > services.length;

    return {
      place,
      changeTimeBookingId,
      getSelectedEmployeeBox,
      prompt,
      campaignTotal,
      cost,
      possibleAddOnServices,
      isPriceVariable,
      shouldShowPrices,
      selectedEmployee,
      pickedServicesHtml,
      loader,
      hideEmployees,
      changeEmpl,
      changeEmplModal,
      lastWorkingDays,
      originalServiceName,
      showAddMoreService,
      campaignStart,
      campaignEnd,
      hasCampaign,
    };
  }

  // Show picked services so far
  pickedServices(selectedEmployee) {
    const { place, services = [] } = this.props;
    const { servicesHtml, end, start } = this.selectedServices(services);
    const top = this.getHeader('chooseService', true);
    const result = {
      html: (
        <div className="selectEmployeeIfOned-services">
          {servicesHtml}
          <ServicePicker
            employeeData={selectedEmployee}
            source="booking_choose_service_prompt"
            placeData={place}
            showServices={this.state.showServices}
            append={true}
            hidePopup={this.hidePopup}
            top={top}
          />
        </div>
      ),
    };

    if (start < Infinity && end > 0) {
      result.campaignStart = start;
      result.campaignEnd = end;
    }

    return result;
  }

  // Get the cost for all services
  getCost(total, isPriceVariable) {
    return (
      <span className={'inline-block'}>
        {isPriceVariable ? __('from') : __('total')}&nbsp;
        <span className="font-semibold">{total}</span>
      </span>
    );
  }

  getSteps() {
    return (
      <div className="flex justify-center">
        <ProgressTracker currentStep={2} steps={3} abortAction={this.handleAbort} abortSteps={[1]} />
      </div>
    );
  }

  employeePicker(employeeData) {
    const { changeEmplModal } = this.state;

    const top = this.getHeader('selectProfessional', true);

    return (
      <ModalDialog
        isOpen={changeEmplModal}
        appElement={document.getElementById('root')}
        onRequestClose={this.hideEmployees}>
        <ModalContent>
          <div className="sm:hidden">
            <ModalHeader action={this.hideEmployees} cancel top={top} />
          </div>
          <div className="hidden sm:block">
            <div className="flex w-full justify-end">
              <span className="mr-3 mt-3">
                <Fab icon={<Icon variant="close" />} onClick={this.hideEmployees} size="md" variant="link" />
              </span>
            </div>
          </div>
          <div className="p-4">
            <EmployeePicker close={this.hideEmployees} />
          </div>
        </ModalContent>
      </ModalDialog>
    );
  }

  toggleMonthView = (e) => {
    const currentState = this.state.showMonthView || false;
    this.setState({ showMonthView: !currentState });
  };

  getMonthButton() {
    return (
      <div className="flex justify-end px-4">
        <button
          className={`cursor-pointer rounded px-1 py-1 text-center outline-none ${
            this.state.showMonthView ? 'bg-primary text-white' : 'text-primary'
          }`}
          onClick={this.toggleMonthView}>
          {__('monthView')}
        </button>
      </div>
    );
  }

  handleAbort = (e) => {
    const { place, history } = this.props;
    const steps = '-1';
    const source = Boolean(this.props.location.state && this.props.location.state.fromLanding);
    trackMpEvent('booking_canceled', this.getCancelTrackingProps());
    if (!source) {
      history.replace('/places/' + place.about.slug + '-' + place.id);
      return;
    }

    if (steps && parseInt(history.length + steps, 10) >= 0) {
      history.go(steps);
    } else {
      if (place && place.id) {
        history.replace('/places/' + place.about.slug + '-' + place.id); // we can also do push instead of replace
      } else {
        history.replace('/');
      }
    }
  };

  appendSharedServices(service) {
    const { dispatch, services } = this.props;
    dispatch(bookActions.appendExtraService(service, services || []));
  }

  addSharedServices = async () => {
    const { place, services, dispatch, time, match } = this.props;

    const shared = url.returnGetParameter('shared');
    const serviceIds = url.returnGetParameter('sIds')?.split('-') || [];
    const employeeId = url.returnGetParameter('eId');
    let placeData = place;

    if (!placeData) {
      const serviceSlugId = exportIdSlug(match.params.serviceSlugId);

      const response = await server.request.get('/service/' + serviceSlugId.id + '/' + serviceSlugId.slug);
      if (response.data?.place && response.data?.service) {
        placeData = response.data?.place || undefined;
        dispatch(bookActions.addPlace(placeData));
        dispatch(bookActions.addService(response.data.service, placeData));
      }
    }

    const getSelectedEmployee = (employees) => {
      let em = { id: 0, about: { name: __('anyEmployee'), avatar: '', isBookable: true } };
      employees &&
        employees.forEach((empl) => {
          if (empl.id == employeeId) {
            em = empl;
          }
        });
      return em;
    };

    let selectedServicesIds = [];
    services &&
      services.forEach((service, key) => {
        selectedServicesIds.push(service.id);
      });

    if (placeData && shared && serviceIds?.length > 0 && selectedServicesIds.length < serviceIds?.length) {
      let servicesToAdd = [];
      let employeeToAdd = { id: 0, about: { name: __('anyEmployee'), avatar: '', isBookable: true } };

      placeData.services.forEach((serviceGroup) => {
        serviceGroup.services.forEach((service) => {
          if (serviceIds.includes(service.id.toString()) && !selectedServicesIds.includes(service.id)) {
            servicesToAdd.push(service);

            this.appendSharedServices(service);
          }
        });
      });
      employeeToAdd = getSelectedEmployee(placeData.employees);
      if ((services && services[0]) || false) {
        servicesToAdd.push(services[0]);
      }

      dispatch(
        bookActions.getAvailability(
          servicesToAdd.filter((a) => a),
          time && time.timestamp,
          employeeToAdd?.id || 0,
          'pickEmployee',
        ),
      );
      if (employeeToAdd) {
        dispatch(bookActions.pickEmployee(employeeToAdd?.id || 0, employeeToAdd.about.priceListId || 0));
      }

      shareActions.share({ placeId: placeData.id, serviceIds, employeeId }).then((result) => {
        this.setState({
          shared: {
            showModal: true,
            link: result.link,
          },
        });
      });
    }

    dispatch(loadingActions.hide());
    this.setState({ waiting: false });
  };

  hideSharedModal = (e) => {
    this.setState({ shared: { showModal: false } });
  };

  sharedModal() {
    return (
      <ModalDialog
        isOpen={this.state?.shared?.showModal}
        appElement={document.getElementById('root')}
        onRequestClose={this.hideSharedModal}>
        <ModalContent>
          <div className="mt-[34px] sm:hidden"></div>
          <div className="hidden sm:block">
            <div className="flex w-full justify-end">
              <span className="mr-3 mt-3">
                <Fab icon={<Icon variant="close" />} onClick={this.hideSharedModal} size="md" variant="link" />
              </span>
            </div>
          </div>

          <Link to="/">
            <LogoIcon className="h-[22px] w-full  text-center" />
          </Link>
          <img src="/images/share-bg.png" className="mb-[40px] mt-[40px] flex w-full" alt="bokadirekt logo" />

          {isMobile() && (
            <>
              <div className="flex flex-col text-center">
                <span className="p-[40px] pb-[25px] pt-[25px] text-lg font-bold leading-8 text-[#2A2E43]">
                  {__('share.modal.mobile_title')}
                </span>
                <div className="flex  p-[25px] text-center">
                  <a
                    className="flex w-full flex-col items-center"
                    href={this.state.shared.link}
                    onClick={(e) => this.hideSharedModal(e)}>
                    <img
                      className="mb-[25px] w-[155px]"
                      src="/images/apple_download.png"
                      alt={__('availableAppStore')}
                    />
                    <img className="w-[177px] " src="/images/google_download.png" alt={__('availablePlayStore')} />
                  </a>
                </div>
              </div>
              <div className="flex flex-col text-center">
                <a
                  className="absolute bottom-[25px] w-full"
                  href="#"
                  onClick={(e) => {
                    this.hideSharedModal(e);
                  }}>
                  <span className="p-[40px] pt-[16px] text-base font-normal italic">
                    {__('share.modal.mobile_description')}
                  </span>
                </a>
              </div>
            </>
          )}

          {!isMobile() && (
            <div className="flex flex-col text-center">
              <span className="p-[40px] pt-[20px] text-2xl font-bold leading-8 text-[#2A2E43]">
                {__('share.modal.web_title')}
              </span>
              <div className="flex h-[120px] p-[20px] ">
                <QRCode
                  size={120}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={this.state.shared.link}
                  viewBox={`0 0 120 120`}
                />
              </div>
              <span className="p-[40px] pt-[16px] text-base font-normal italic text-[#2A2E43]">
                {__('share.modal.web_description')}
              </span>
            </div>
          )}
        </ModalContent>
      </ModalDialog>
    );
  }

  render() {
    if (this.state.is404) return <NotFound staticContext={this.props.staticContext} />;
    const params = this.getParams();
    const {
      redirect,
      place,
      changeTimeBookingId,
      getSelectedEmployeeBox,
      prompt,
      shouldShowPrices,
      cost,
      changeEmpl,
      changeEmplModal,
      hideEmployees,
      pickedServicesHtml,
      loader,
      lastWorkingDays,
      showAddMoreService,
      campaignStart,
      campaignEnd,
      hasCampaign,
      selectedEmployee,
      possibleAddOnServices,
      campaignTotal,
    } = params;

    if (redirect) {
      return <Redirect to="/" />;
    }

    if (!isServer && (this.state.waiting || typeof this.state.waiting === 'undefined')) {
      return loader;
    }

    const changeBookingTime = Boolean(changeTimeBookingId);

    const service = this.props.services && this.props.services[0] ? this.props.services[0] : null;
    const serviceName = service && service.name ? service.name : '';
    const salonName = place && place.about && place.about.name ? place.about.name : '';
    const salonAddress =
      place && place.contact && place.contact.address
        ? (place.contact.address.city || '') + ' ' + (place.contact.address.district || '')
        : '';

    const title = __('book') + ' ' + serviceName + ' - ' + salonName + ', ' + salonAddress + ' - Bokadirekt';
    const description = service && service.about && service.about.description ? service.about.description : '';
    const navigationTitle = changeBookingTime ? __('changeTime') : __('pickTime');

    return (
      <PageViewLayout
        type="subView"
        back
        onSubActionClose={this.handleAbort}
        title={navigationTitle}
        onCloseButtonClick={this.handleAbort}
        footerDisplayOption={{ from: 'md', to: 'all' }}>
        <>
          {this.state?.shared?.showModal && this.sharedModal()}
          {prompt && prompt}
          <div className="py-8 md:container md:mx-auto">
            <div className="flex flex-col gap-16 md:flex-row">
              <div className="sticky w-full md:w-7/12">
                {this.checkRetry()}
                {this.hasDiscount()}
                {!isSistaminuten() && this.getMonthButton()}
                <div style={{ position: 'relative' }}>
                  {loader}
                  {!isSistaminuten() && !this.state.showMonthView && (
                    <WeekPicker
                      lastWorkingDays={lastWorkingDays}
                      campaignStart={campaignStart}
                      campaignEnd={campaignEnd}
                    />
                  )}
                  {!isSistaminuten() && this.state.showMonthView && (
                    <MonthPicker
                      lastWorkingDays={lastWorkingDays}
                      showWeekView={this.toggleMonthView}
                      campaignStart={campaignStart}
                      campaignEnd={campaignEnd}
                    />
                  )}
                  {isSistaminuten() && <LastMinuteWeekPicker campaignStart={campaignStart} campaignEnd={campaignEnd} />}
                </div>
              </div>
              {!changeBookingTime && (
                <div className="md:hidden">
                  {!hideEmployees && changeEmplModal && this.employeePicker(selectedEmployee)}
                  <div className="flex flex-1 flex-col px-4 pb-16 md:pb-0">
                    <h1 className="mb-1 font-semibold leading-5">{title}</h1>
                    <ReadMore text={description} />
                    {!isSistaminuten() && this.hasDiscount()}
                    <div className="border-black-100 border-b-[1px] py-2">
                      <Breadcrumbs className="text-sm" items={getServiceBreadcrumbs(place, service)} />
                    </div>
                    <div className="flex justify-center">
                      <Button
                        block
                        size="sm"
                        variant="secondary"
                        onClick={() => (!isServer ? window.scrollTo(0, 0) : null)}
                        className="mx-5 my-4">
                        {__('chooseTime')}
                      </Button>
                    </div>
                  </div>
                  {this.state.isOpen && (
                    <div className="fixed bottom-0 left-0 right-0 bg-white shadow">
                      <div className="px-4 pb-3 pt-2">
                        <div className="border-black-100 mb-3 flex h-11 w-full items-center justify-between border-b-[1px] py-2">
                          <span className="font-semibold">{__('service', { count: this.props.services.length })}</span>
                          <button
                            className={`flex items-center outline-none ${themed(
                              'text-primary',
                              'text-black-800 underline',
                            )}`}
                            onClick={this.openBottom}>
                            <ChevronIcon className="mr-2 h-3 w-3" />
                            {__('close')}
                          </button>
                        </div>
                        {!hideEmployees && !changeEmplModal && <>{getSelectedEmployeeBox(true)}</>}
                        <div className="services pt-4">{pickedServicesHtml}</div>
                        {this.state.showRecommended && this.recommendedServices(selectedEmployee)}
                        <div className="flex flex-col items-start">
                          {possibleAddOnServices && possibleAddOnServices.length > 0 && (
                            <Button
                              variant="link"
                              onClick={this.addRecommendedServices}
                              size="sm"
                              className="!p-0 !outline-none focus:!ring-0"
                              leftIcon={
                                <span className="bg-primary-50 rounded-full p-1">
                                  <PlusAltIcon className="text-primary h-2  w-2 " />
                                </span>
                              }>
                              {__('recommendedServices')}
                            </Button>
                          )}
                          {showAddMoreService && (
                            <Button
                              variant="link"
                              onClick={this.addService}
                              size="sm"
                              className="!p-0 !outline-none focus:!ring-0 "
                              leftIcon={
                                <span className={`rounded-full p-1 ${themed('bg-primary-50', 'bg-sm_primary')}`}>
                                  <PlusAltIcon className={`h-2 w-2 ${themed('text-primary', 'text-black-800')}`} />
                                </span>
                              }>
                              {__('addAnotherService')}
                            </Button>
                          )}
                        </div>
                        <div className="flex justify-end">{shouldShowPrices && cost}</div>
                      </div>
                    </div>
                  )}
                  {!this.state.isOpen && (
                    <div className="fixed bottom-0 left-0 right-0 h-auto bg-white shadow">
                      <div className="px-4 pb-3 pt-2">
                        <div className="flex w-full items-center justify-between">
                          <span className="font-semibold">{__('service', { count: this.props.services.length })}</span>
                          <Button
                            variant="link"
                            onClick={this.openBottom}
                            size="sm"
                            className="!p-0 !outline-none focus:!ring-0"
                            leftIcon={
                              <span className={`rounded-full p-1 ${themed('bg-primary-50', 'bg-sm_primary')}`}>
                                <PlusAltIcon className={`h-2 w-2 ${themed('text-primary', 'text-black-800')}`} />
                              </span>
                            }>
                            {__('addOrChange')}
                          </Button>
                        </div>
                        {hasCampaign && (
                          <div className="flex flex-col items-center">
                            <span className="mb-2 font-semibold">{__('chooseTimeContinue')}</span>
                            {campaignTotal}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {!changeBookingTime && (
                <div className="hidden w-5/12 md:block md:max-w-[290px]">
                  <div className="sticky top-36">
                    <div>
                      <div className="splitter font-semibold">{__('youHaveChoosen')}: </div>
                      {pickedServicesHtml}
                      <div className="flex flex-col items-baseline">
                        {possibleAddOnServices && possibleAddOnServices.length > 0 > 0 && (
                          <Button
                            variant="link"
                            onClick={this.addRecommendedServices}
                            size="sm"
                            className="!px-0 !outline-none focus:!ring-0"
                            leftIcon={
                              <span className={`rounded-full p-1 ${themed('bg-primary-50', 'bg-sm_primary')}`}>
                                <PlusAltIcon className={`h-2 w-2 ${themed('text-primary', 'text-black-800')}`} />
                              </span>
                            }>
                            {__('recommendedServices')}
                          </Button>
                        )}
                        {showAddMoreService && (
                          <Button
                            variant="link"
                            onClick={this.addService}
                            size="sm"
                            className="!p-0 !outline-none focus:!ring-0"
                            leftIcon={
                              <span className={`rounded-full p-1 ${themed('bg-primary-50', 'bg-sm_primary')}`}>
                                <PlusAltIcon className={`h-2 w-2 ${themed('text-primary', 'text-black-800')}`} />
                              </span>
                            }>
                            {__('addAnotherService')}
                          </Button>
                        )}
                      </div>
                      {!hideEmployees && <div className="py-2"></div>}
                      {this.state.showRecommended && this.recommendedServices(selectedEmployee)}
                      {hasCampaign && campaignTotal}
                      {!hideEmployees && !changeEmpl && getSelectedEmployeeBox(true)}
                      {!hideEmployees && changeEmpl && (
                        <EmployeePicker
                          noButtons={true}
                          hide={this.hideEmployees}
                          showCheckbox={true}
                          selectedEmployee={selectedEmployee}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.seo()}
        </>
      </PageViewLayout>
    );
  }
}

Book.contextType = LoginContext;

function mapStateToProps(state) {
  const { show, loadSource, is404 } = state.loading;
  const { services, place, employee, time, priceId, availability, campaigns, appliedBundle, changeTimeBookingId } =
    state.book;
  return {
    is404,
    place,
    services,
    employee,
    availability,
    priceId,
    time,
    campaigns,
    loadSource,
    show,
    appliedBundle,
    changeTimeBookingId,
  };
}

const connectedBook = withRouter(connect(mapStateToProps)(Book));
export default connectedBook;
