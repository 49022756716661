import CardIcon from '@/components/elements/CardIcon';
import { Button } from '@/components/elements/forms/buttons';
import { classnames } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { PaymentCard } from '@/types/paymentcards';
import Modal from '../../modals/redesign/Modal/Modal';

const CardOptionsModal = ({
  isOpen,
  onClose,
  onRemove,
  selectedCard,
}: {
  isOpen: boolean;
  onClose: () => void;
  onRemove: (id: string) => void;
  selectedCard: PaymentCard;
}) => {
  const { isMobileView } = useMobileView();
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Content floating={!isMobileView}>
        <div className={classnames(isMobileView && 'px-lg')}>
          <div className="border-black-100 pb-md border-b">
            <div className="flex justify-center">
              <span className="mr-2 block w-11 rounded">
                <CardIcon card={selectedCard} size="medium" />
              </span>
              <span className="text-lg font-semibold">
                {selectedCard.brand.toUpperCase()} <span className="relative bottom-[3px] text-xs">••••</span>{' '}
                {selectedCard.lastFour}
              </span>
            </div>
          </div>
          <p className="mb-4 mt-3 text-center">{_s('removeCard.dialog')}</p>
          <div className="flex flex-col items-center">
            <Button variant="primary" block size="md" onClick={onClose}>
              {_s('removeCard.cancel')}
            </Button>
            <Button
              size="md"
              variant="link"
              block
              className="!text-danger outline-danger font-normal"
              onClick={() => onRemove(selectedCard.id)}>
              {_s('removeCard.confirm')}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default CardOptionsModal;
