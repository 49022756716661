/**
 * References to the location of the banner and
 * the flag/experiment name for the banner in amplitude
 */
export const BANNER_SLOT_LOCATION = {
  ABOVE_ALL_BANNER_HOME_SCREEN: 'above_all_banner_home_screen',
  ABOVE_ALL_BANNER_PLACE_SCREEN: 'above_all_banner_place_screen',
  TOP_BANNER_HOME_WEB: 'top_banner_home_web',
  MIDDLE_BANNER_HOME: 'middle_banner_home',
  BOTTOM_BANNER_HOME: 'bottom_banner_home',
  TOP_BANNER_SEARCH: 'top_banner_search',
  MIDDLE_BANNER_SEARCH: 'middle_banner_search',
  MIDDLE_BANNER_SEARCH_WEB: 'middle_banner_search_web',
};
