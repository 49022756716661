import { useEffect } from 'react';

const MESSAGE_TYPE = {
  OPENED: 'in-app:message-opened',
  DISMISSED: 'in-app:message-dismissed',
  ACTION: 'in-app:message-action',
  ERROR: 'in-app:message-error',
} as const;

const useCustomerIOInAppMessaging = () => {
  const onMessageOpened = (event: CustomerIOInAppMessage.Event.Opened) => {};
  const onMessageDismissed = (event: CustomerIOInAppMessage.Event.Dismissed) => {};
  const onMessageAction = (event: CustomerIOInAppMessage.Event.Action) => {};
  const onMessageError = (event: CustomerIOInAppMessage.Event.Error) => {};

  useEffect(() => {
    if (!window._cio) return;

    window._cio.on(MESSAGE_TYPE.ACTION, onMessageOpened);
    window._cio.on(MESSAGE_TYPE.DISMISSED, onMessageDismissed);
    window._cio.on(MESSAGE_TYPE.ACTION, onMessageAction);
    window._cio.on(MESSAGE_TYPE.ERROR, onMessageError);

    return () => {
      window._cio.off(MESSAGE_TYPE.OPENED, onMessageOpened);
      window._cio.off(MESSAGE_TYPE.DISMISSED, onMessageDismissed);
      window._cio.off(MESSAGE_TYPE.ACTION, onMessageAction);
      window._cio.off(MESSAGE_TYPE.ERROR, onMessageError);
    };
  }, []);
};

export default useCustomerIOInAppMessaging;
