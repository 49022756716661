import { isServer, trackMpEvent } from '@/helpers';
import { __ } from '@/locale';
import { useEffect, useState } from 'react';

function FilterServicesInput(props) {
  const [queryString, setQueryString] = useState('');
  const [sendEvent, setSendEvent] = useState(false);
  const [showFilter, setShowFilter] = useState((!isServer && window.showFilterServices) || false);
  const { setFilterServicesQuery } = props;

  useEffect(() => {
    if (!isServer) {
      window.dataLayer.push({ event: 'optimize.showFilterServices' });

      let counter = 0;
      const interval = setInterval(() => {
        if (window.showFilterServices) {
          setShowFilter(true);
          clearInterval(interval);
        }
        counter++;
        if (counter > 5) {
          clearInterval(interval);
        }
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (setFilterServicesQuery) {
      setFilterServicesQuery(prepareFilterQuery());
    }
  }, [queryString]);

  const handleChange = (e) => {
    const { value = '' } = e.target;
    setQueryString(value);
  };

  const handleClear = () => {
    setQueryString('');
  };

  const prepareFilterQuery = () => {
    if (queryString && queryString.trim().length > 1) {
      if (!sendEvent) {
        trackMpEvent('filter_services_started', {
          screen_name: props.source,
        });
        setSendEvent(true);
      }

      return queryString.trim().toLowerCase();
    }

    return '';
  };

  if (showFilter) {
    return (
      <div className="form-group filter-services">
        <div className="field relative flex w-full md:w-7/12">
          <input
            type="text"
            name="filter-services"
            className="form-control"
            placeholder={__('searchService')}
            value={queryString}
            onChange={handleChange}
          />
          {queryString && (
            <span
              style={{
                backgroundImage: 'url("/images/clear.svg")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                position: 'absolute',
                verticalAlign: 'middle',
                right: '0px',
                width: '38px',
                height: '100%',
                cursor: 'pointer',
              }}
              onClick={handleClear}></span>
          )}
        </div>
      </div>
    );
  }

  return null;
}

export default FilterServicesInput;
