import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { IconProps } from '@/components/icons/types';
import { getPlaceTimezone, getSalonDate } from '@/helpers';
import { _s } from '@/locale';
import { ConfirmedBooking } from '@/types/api/services/booking';
import { atcb_action } from 'add-to-calendar-button';
import { useState } from 'react';
import Modal from './redesign/Modal/Modal';

type CalendarType = 'Apple' | 'Google' | 'Yahoo' | 'Outlook.com';

type CalendarEvent = {
  name: string;
  description: string;
  startTime: string;
  startDate: string;
  endTime: string;
  location: string;
  options: CalendarType[];
  timeZone: string;
};

type UseAddToCalendarModal = ReturnType<typeof useAddToCalendarModal>;

export const useAddToCalendarModal = (
  booking: ConfirmedBooking | null,
): {
  event: CalendarEvent | null;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
} => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  if (!booking) return { event: null, isOpen, onClose: handleClose, onOpen: handleOpen };

  const { start, duration, place, services } = booking;
  const hideDuration = (services || []).some((app) => app?.service?.about?.settings?.hideDuration === true);
  const { street, zipcode, city } = place.contact.address;
  const timezone = getPlaceTimezone(place);
  const event: CalendarEvent = {
    name: place.about.name,
    description: '',
    location: place.about.name + ', ' + street + ' ' + zipcode + ' ' + city,
    startTime: getSalonDate(start, timezone).format('HH:mm'),
    endTime: getSalonDate(!hideDuration && duration > 0 ? start + duration : start + 15 * 60, timezone).format('HH:mm'),
    options: ['Apple', 'Google', 'Outlook.com', 'Yahoo'],
    startDate: getSalonDate(start, timezone).format('YYYY-MM-DD'),
    timeZone: 'Europe/Stockholm',
  };

  return { event, isOpen, onClose: handleClose, onOpen: handleOpen };
};

const getCalendarIconProps = (type: CalendarType): IconProps => {
  switch (type) {
    case 'Apple':
      return { variant: 'apple-alt' };
    case 'Google':
      return { variant: 'google-alt', noFilter: true };
    case 'Outlook.com':
      return { variant: 'outlook', noFilter: true };
    case 'Yahoo':
      return { variant: 'yahoo', noFilter: true };
    default:
      const never: never = type;
      return never;
  }
};

const baseTranslationKey = 'components.modules.modals.AddToCalendarModal';

const AddToCalendarModal = ({ event, isOpen, onClose }: Omit<UseAddToCalendarModal, 'onOpen'>) => {
  const calendarButtons = event.options.map((option) => ({
    option,
    onClick: () => {
      atcb_action({ ...event, timeZone: 'Europe/Stockholm', options: [option] });
    },
  }));

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Content floating>
        <Modal.Header title={_s(`${baseTranslationKey}.header`)} onClose={onClose} />
        {calendarButtons?.map(({ onClick, option }, index) => (
          <ListItem
            underline={index !== calendarButtons.length - 1}
            key={option}
            onClick={onClick}
            leftSlot={<Icon {...getCalendarIconProps(option)} />}
            rightSlot={<Icon variant="plus-circle" />}>
            <ListItemContent title={option} />
          </ListItem>
        ))}
      </Modal.Content>
    </Modal>
  );
};

export default AddToCalendarModal;
