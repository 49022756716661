import { themed } from '@/helpers/theme';
import { _s } from '@/locale';
import { KlarnaBadgeIcon, RocketIcon } from '../icons';
import Icon from '@/components/icons/Icon';

type TagVariant =
  | 'novelty'
  | 'klarna'
  | 'qliro'
  | 'rocket'
  | 'giftcard'
  | 'wellness'
  | 'discount'
  | 'campaign'
  | 'attention'
  | 'certificate'
  | 'information';
type TagSize = 'small' | 'large';

type TagProps = {
  variant?: TagVariant;
  size?: TagSize;
  children: React.ReactNode;
  className?: string;
};

const commonStyles = `text-xxs font-semibold select-none`;
const smBase = `${commonStyles} rounded h-6 inline-flex items-center justify-center`;

const getStylesForVariant = (variant: TagVariant) => {
  switch (variant) {
    case 'novelty':
      return `${commonStyles} inline-flex items-center !h-6 px-[6px] rounded-xs text-black-900 bg-warning`;
    case 'klarna':
      return `${smBase} bg-[#ffb3cb]`;
    case 'qliro':
      return `${smBase} bg-black-50`;
    case 'giftcard':
      return `${smBase} bg-secondary text-white`;
    case 'wellness':
      return `${smBase} bg-brand-900 text-white`;
    case 'discount':
      return `${smBase} bg-black-200 text-black-900`;
    case 'campaign':
      return `${smBase} ${themed('bg-information-50 text-information', 'bg-sm_secondary text-white')}`;
    case 'attention':
      return `${smBase} bg-[#f8dde7] text-[#ea5786]`;
    case 'rocket':
      return `${smBase} bg-warning text-black-800`;
    case 'certificate':
      return `${smBase} bg-secondary-50 text-black-900`;
    case 'information':
      return `${smBase} text-[#336CA8] bg-white`;
  }
};

const getStylesForSize = (size: TagSize) => {
  switch (size) {
    case 'small':
      return 'px-1';
    case 'large':
      return ' px-4 py-4';
  }
};

const Tag = ({ children, variant = 'novelty', size = 'small', className = '' }: TagProps) => (
  <span className={`${getStylesForVariant(variant)} ${getStylesForSize(size)} ${className}`}>{children}</span>
);

export const KlarnaTag = () => {
  return (
    <Tag variant="klarna">
      <KlarnaBadgeIcon className="w-8" /> <span className="absolute h-0 w-0 indent-[-10000px]">{_s('klarna')}</span>
    </Tag>
  );
};

export const QliroTag = () => {
  return (
    <Tag variant="qliro">
      <img src="https://assets.qliro.com/shared/se/sv/logo/1/logo_text_black.png" className="h-full" alt="qliro" />
    </Tag>
  );
};

export const GiftCardTag = () => {
  return (
    <Tag variant="giftcard">
      <Icon variant="gift" color="white" className="mr-1 h-4 w-4" />
      <span>{_s('giftCardsLabel')}</span>
    </Tag>
  );
};

export const WellnessCardTag = () => {
  return (
    <Tag variant="wellness">
      <Icon variant="wellness-card" color="white" className="mr-1 h-4 w-4" />
      <span>{_s('wellnessCardsLabel')}</span>
    </Tag>
  );
};

export const RocketTag = () => {
  return (
    <Tag variant="rocket">
      <RocketIcon className="mr-1 h-3 w-3" />
      <span>{_s('rocket')}</span>
    </Tag>
  );
};

export const CertificateTag = () => {
  return (
    <Tag variant="certificate">
      <Icon variant="certificate" className="mr-1 h-4 w-4" />
      <span>{_s('certificate')}</span>
    </Tag>
  );
};

export default Tag;
