/* eslint-disable */

import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { CookiesContent } from '@/components/modules/pages/blog';
import {
  allowTracking,
  getHomeDescription,
  isSistaminuten,
  setCookiePreferences,
  trackMpEvent,
  trackPage,
  url,
} from '@/helpers';
import { _s } from '@/locale';
import { userService } from '@/services';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

function Cookies(props) {
  const [checked, setChecked] = useState(allowTracking());

  useEffect(() => {
    window.scrollTo(0, 0);
    trackPage();
    // trackMpEvent('screen_shown', { screen_name: 'cookies' });
  }, []);

  const seo = () => {
    const title = _s('seo.cookiesTitle');
    const description = getHomeDescription();
    const pageUrl = `${url.getBaseUrl()}articles/cookies`;
    const imageUrl = url.getBaseImageUrl();
    const isNoIndex = isSistaminuten();

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={pageUrl} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        {isNoIndex && <meta name="robots" content="noindex" />}
      </Helmet>
    );
  };

  const handleLabelClick = (e) => {
    const input = e.target.parentNode.querySelector('input');
    const value = input && input.checked ? false : true;
    setChecked(value);
  };

  const handleChange = (e) => {
    setChecked(Boolean(e.target.checked));
  };

  const save = async (e) => {
    if (!checked) {
      setCookiePreferences('Mandatory');
      // Count Up user tracking rejection
      await userService.declineTracking();
    } else {
      setCookiePreferences();
    }

    if (props.location && props.location.state && props.location.state.fromAllowCookiesPopup) {
      window.location = props.location.state.fromAllowCookiesPopup;
    } else {
      window.location.reload();
    }
  };

  return (
    <PageViewLayout
      type="subView"
      back
      title={_s('cookiesPage.cookiesTitle')}
      footerDisplayOption={{ from: 'sm', to: 'all' }}>
      <CookiesContent
        onClickSave={save}
        onTrackingCheckedChange={handleChange}
        onTrackingLabelClick={handleLabelClick}
        trackingChecked={checked}
      />
      {seo()}
    </PageViewLayout>
  );
}

export default Cookies;
