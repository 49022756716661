import { getAppRoutes } from '@/constants/routes';
import { isSentry } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

// Custom Route if sentry exists
const AppRoute = isSentry ? Sentry.withSentryRouting(Route) : Route;

const AppRouter = ({ redirect = null }: { redirect?: JSX.Element }) => {
  const { isMobileView } = useMobileView();
  const [appRoutes, setAppRoutes] = useState(getAppRoutes(isMobileView));

  useEffect(() => {
    setAppRoutes(getAppRoutes(isMobileView));
  }, [isMobileView]);

  return (
    <Switch>
      {redirect}
      {appRoutes.map((path, i) => (
        <AppRoute {...path} key={i} />
      ))}
    </Switch>
  );
};
export default AppRouter;
