import { loadingConstants } from '../constants';
const initialState = { show: false };
export function loading(state = initialState, action) {
  switch (action.type) {
    case loadingConstants.SHOW:
      return { show: true, loadSource: action.data };
    case loadingConstants.HIDE:
      return { show: false };
    default:
      return state;
  }
}
