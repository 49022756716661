import { ListItem, ListItemContent } from '@/components/elements/lists';
import BookingListItem from '@/components/elements/lists/BookingListItem/BookingListItem';
import DataGrid from '@/components/elements/lists/DataGrid';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Icon from '@/components/icons/Icon';
import { CheckoutDetailsProps } from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import SelectedPaymentMethod from '@/components/templates/checkout/SelectedPaymentMethod/SelectedPaymentMethod';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { TERMS_NAVIGATION_LINKS, TermLinkIdentifier } from '@/constants/terms';
import { getCanCancelAppointmentProps, isBookingInThePast } from '@/helpers';
import {
  mapConfirmedBookingInfoGiftcards,
  mapConfirmedBookingInfoServices,
  mapConfirmedBookingToCheckoutDetailsProps,
} from '@/helpers/confirmation';
import { _s } from '@/locale';
import { ConfirmedBooking } from '@/types/api/services/booking';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const baseTranslationKey = 'components.modules.appointment.AppointmentDetailsSummary';

const AppointmentDetailsSummary = ({ appointment }: { appointment: ConfirmedBooking }) => {
  const { cancelInformation, canCancel } = getCanCancelAppointmentProps(appointment);
  const isUpcoming = !isBookingInThePast(appointment);

  const checkoutDetailsProps: CheckoutDetailsProps = {
    ...mapConfirmedBookingToCheckoutDetailsProps({ booking: appointment }),
    services: mapConfirmedBookingInfoServices(appointment, true),
    giftcards: mapConfirmedBookingInfoGiftcards(appointment),
  };

  const showCancelInformation = isUpcoming && cancelInformation ? cancelInformation : undefined;
  const status = appointment.status + (!isUpcoming ? 100 : 0);

  const termsVariant = ((): TermLinkIdentifier => {
    if (Boolean(checkoutDetailsProps.giftcards.length > 0)) {
      return TermLinkIdentifier.COUPON;
    }

    switch (checkoutDetailsProps.paymentMethod?.type) {
      case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
      case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
      case CHECKOUT_PAYMENT_METHOD.STORED_COF:
      case CHECKOUT_PAYMENT_METHOD.NEW_COF:
      case CHECKOUT_PAYMENT_METHOD.QLIRO:
        return TermLinkIdentifier.PAYMENT;
    }

    return TermLinkIdentifier.USAGE;
  })();

  const termDescription = _s(`${baseTranslationKey}.terms.description.${termsVariant}`);
  /**
   * We want the link to the terms to be to the general usage terms instread of the giftcard terms for this page
   */
  const termIdentifier = termsVariant === TermLinkIdentifier.COUPON ? TermLinkIdentifier.USAGE : termsVariant;
  const { label: termLabel, to: termLink } = TERMS_NAVIGATION_LINKS.find((term) => term.identifier === termIdentifier);

  return (
    <>
      <BookingListItem
        image={{ src: checkoutDetailsProps.logo }}
        {...checkoutDetailsProps}
        {...(showCancelInformation && { underline: canCancel })}
        status={status}
        services=""
        src={`/places/${appointment.place?.about?.slug}-${appointment.place?.id}`}
      />
      {showCancelInformation && canCancel && (
        <ListItem type="div" underline leftSlot={<Icon variant="info-circle" />}>
          <ListItemContent content={cancelInformation} />
        </ListItem>
      )}
      {showCancelInformation && !canCancel && (
        <div className="px-lg pb-md">
          <Alert
            variant="cation"
            leftSlot={<Icon variant="info-circle" color="warning-900" />}
            body={cancelInformation}
          />
        </div>
      )}
      <div className="px-lg">
        <DataGrid ariaLabel={_s(`${baseTranslationKey}.ariaLabel.title`)}>
          {checkoutDetailsProps.services?.map((service, index) => {
            const isLastService = index === checkoutDetailsProps.services.length - 1;
            const discount = service.discount;
            return (
              <Fragment key={service.name + index}>
                <DataGrid.Row key={index} hasPaddingTop>
                  <DataGrid.Cell
                    title={service.name}
                    type="muted"
                    ariaLabel={_s(`${baseTranslationKey}.ariaLabel.service.name`)}
                  />
                  <DataGrid.Cell
                    title={
                      discount ? (
                        <span className="text-highlight-700">
                          {discount} <span className="text-black-600 line-through">({service.price})</span>
                        </span>
                      ) : (
                        service.price
                      )
                    }
                    align="right"
                    ariaLabel={_s(`${baseTranslationKey}.ariaLabel.service.price`)}
                  />
                </DataGrid.Row>
                {service.duration && (
                  <DataGrid.Row {...(isLastService && { undeline: true })}>
                    <DataGrid.Cell
                      title={service.duration}
                      type="description"
                      ariaLabel={_s(`${baseTranslationKey}.ariaLabel.service.duration`)}
                    />
                  </DataGrid.Row>
                )}
              </Fragment>
            );
          })}
          {checkoutDetailsProps.giftcards?.map(({ code, amount, expiryDate, label }) => (
            <Fragment key={code}>
              <DataGrid.Row hasPaddingTop>
                <DataGrid.Cell title={label} type="muted" />
                <DataGrid.Cell
                  title={amount}
                  align="right"
                  type="discount"
                  ariaLabel={_s(`${baseTranslationKey}.ariaLabel.giftcard.discount`)}
                />
              </DataGrid.Row>
              <DataGrid.Row undeline>
                <DataGrid.Cell
                  title={`${code}${expiryDate ? ` ${_s('expires')}: ${expiryDate}` : ''}`}
                  type="description"
                  ariaLabel={_s(`${baseTranslationKey}.ariaLabel.giftcard.code`)}
                />
              </DataGrid.Row>
            </Fragment>
          ))}
          {!checkoutDetailsProps.payLater && (
            <DataGrid.Row hasPaddingTop>
              <DataGrid.Cell title={checkoutDetailsProps.priceToPay.label} type="header" />
              <DataGrid.Cell
                title={checkoutDetailsProps.priceToPay.price}
                type="header"
                align="right"
                ariaLabel={_s(`${baseTranslationKey}.ariaLabel.subtotal.amount`)}
              />
            </DataGrid.Row>
          )}
          {checkoutDetailsProps.payLater && (
            <>
              <DataGrid.Row hasPaddingTop>
                <DataGrid.Cell title={checkoutDetailsProps.priceToPay.label} type="muted" />
                <DataGrid.Cell
                  title={checkoutDetailsProps.priceToPay.price}
                  align="right"
                  ariaLabel={_s(`${baseTranslationKey}.ariaLabel.subtotal.amount`)}
                />
              </DataGrid.Row>
              <DataGrid.Row>
                <DataGrid.Cell title={_s(`${baseTranslationKey}.subtotal.payTodayLabel`)} type="header" />
                <DataGrid.Cell
                  title="0 kr"
                  align="right"
                  type="header"
                  ariaLabel={_s(`${baseTranslationKey}.ariaLabel.subtotal.amount`)}
                />
              </DataGrid.Row>
            </>
          )}
        </DataGrid>
      </div>
      {checkoutDetailsProps.paymentMethod && <SelectedPaymentMethod method={checkoutDetailsProps.paymentMethod} />}
      <Alert
        variant="information"
        body={
          <>
            {termDescription}{' '}
            <Link className="underline" to={termLink}>
              {termLabel}
            </Link>
          </>
        }
      />
    </>
  );
};

export default AppointmentDetailsSummary;
