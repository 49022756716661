import useCustomerIOInAppMessaging from '@/hooks/useCustomerIOInAppMessaging';

function withCustomerIOInAppMessaging<P>(WrappedComponent: React.ComponentType<P>) {
  const WithCustomerIOInAppMessaging = (props: P) => {
    useCustomerIOInAppMessaging();
    return <WrappedComponent {...props} />;
  };
  return WithCustomerIOInAppMessaging;
}

export default withCustomerIOInAppMessaging;
