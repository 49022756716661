import { LabelProps } from '@/components/elements/Label/Label';
import { _s } from '@/locale';
import { BundleActionIdentifier, BundleActionItem } from '@/types/bundle';

const baseTranslationKey = 'constants.bundle';

/**
 * Feature flag for booking bundles (amplitude flag)
 */
export const BUNDLES_FEATURE_FLAG = 'bundles_kickoff';

export const BOOK_TIME_ACTION_ITEM: BundleActionItem = {
  variant: 'primary',
  icon: 'calendar-plus',
  label: _s(`${baseTranslationKey}.BOOK_TIME_ACTION_ITEM.label`),
  identifier: BundleActionIdentifier.BOOK_TIME,
};

export const BUY_AGAIN_ACTION_ITEM: BundleActionItem = {
  variant: 'secondary',
  icon: 'shopping-basket',
  label: _s(`${baseTranslationKey}.BUY_AGAIN_ACTION_ITEM.label`),
  identifier: BundleActionIdentifier.BUY_AGAIN,
};

export const VALID_BUNDLE_LABEL: LabelProps = {
  label: _s(`${baseTranslationKey}.VALID_BUNDLE_LABEL.label`),
  className: 'bg-brand-700',
};

export const EXPIRED_BUNDLE_LABEL: LabelProps = {
  label: _s(`${baseTranslationKey}.EXPIRED_BUNDLE_LABEL.label`),
  className: 'bg-black-100 text-black-900',
};

export const CONSUMED_BUNDLE_LABEL: LabelProps = {
  label: _s(`${baseTranslationKey}.CONSUMED_BUNDLE_LABEL.label`),
  className: 'bg-black-100 text-black-900',
};
