import { CHECKOUT_PAYMENT_METHOD, COUPON_PAYMENT_METHOD } from '@/constants/checkout';
import { PAYMENT_ICON_FILENAME, PAYMENT_METHODS_ICON_FILENAME, UI_ICON_FILENAME } from '@/constants/icons';
import { z } from 'zod';
import { CofPaymentData } from './adyen';
import { paymentCardBrandSchema } from './paymentcards';
import { BookState } from './state/book';
import { PlaceEmployee } from './state/shared';

export type PayAtPlaceIcon = (typeof PAYMENT_ICON_FILENAME)['WALLET'];
export type OnlinePaymentIcon = (typeof PAYMENT_METHODS_ICON_FILENAME)[keyof typeof PAYMENT_METHODS_ICON_FILENAME];
type PaymentSelectIcon = (typeof UI_ICON_FILENAME)['PEN'];

export type SelectPaymentOptionIcon = OnlinePaymentIcon | PaymentSelectIcon | PayAtPlaceIcon;

const paymentMethodOptionsSchema = z.object({
  total: z.union([z.number(), z.string()]),
  final: z.number(),
  totalLabel: z.string(),
  hidden: z.boolean().optional(),
  highlighted: z.boolean().optional(),
});

export const storedCofPaymentMethodSchema = paymentMethodOptionsSchema.extend({
  type: z.literal(CHECKOUT_PAYMENT_METHOD.STORED_COF),
});

const qliroPaymentMethodSchema = paymentMethodOptionsSchema.extend({
  type: z.literal(CHECKOUT_PAYMENT_METHOD.QLIRO),
});

const klarnaPaymentMethodSchema = paymentMethodOptionsSchema.extend({
  type: z.literal(CHECKOUT_PAYMENT_METHOD.KLARNA),
});

const payAtPlaceMethodSchema = paymentMethodOptionsSchema.extend({
  type: z.literal(CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE),
});

export type PayAtPlaceMethod = z.infer<typeof payAtPlaceMethodSchema>;

const swishPaymentMethodSchema = paymentMethodOptionsSchema.extend({
  type: z.literal(CHECKOUT_PAYMENT_METHOD.SWISH),
});

const applePayPaymentMethodSchema = paymentMethodOptionsSchema.extend({
  type: z.literal(CHECKOUT_PAYMENT_METHOD.APPLE_PAY),
});

const googlePayPaymentMethodSchema = paymentMethodOptionsSchema.extend({
  type: z.literal(CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY),
});

const newCofPaymentMethodSchema = paymentMethodOptionsSchema.extend({
  type: z.literal(CHECKOUT_PAYMENT_METHOD.NEW_COF),
});

const unselectedPaymentMethodSchema = paymentMethodOptionsSchema.extend({
  type: z.literal(CHECKOUT_PAYMENT_METHOD.NONE),
});

const giftcardMethodSchema = z.object({
  type: z.literal(COUPON_PAYMENT_METHOD.GIFTCARD),
  hidden: z.boolean().optional(),
  highlighted: z.boolean().optional(),
});

const valuecardMethodSchema = z.object({
  type: z.literal(COUPON_PAYMENT_METHOD.VALUECARD),
  hidden: z.boolean().optional(),
  highlighted: z.boolean().optional(),
});

const wellnesscardMethodSchema = z.object({
  type: z.literal(COUPON_PAYMENT_METHOD.WELLNESSCARD),
  hidden: z.boolean().optional(),
  highlighted: z.boolean().optional(),
});

const couponMethodSchema = z.union([giftcardMethodSchema, valuecardMethodSchema, wellnesscardMethodSchema]);

export const selectedStoredCofPaymentMethodSchema = paymentMethodOptionsSchema.extend({
  type: z.literal(CHECKOUT_PAYMENT_METHOD.STORED_COF),
  id: z.string(),
  brand: paymentCardBrandSchema,
  lastFour: z.string(),
});

export type SelectedStoredCofPaymentMethod = z.infer<typeof selectedStoredCofPaymentMethodSchema>;

export const adyenSelectedPaymentMethodSchema = z.union([
  selectedStoredCofPaymentMethodSchema,
  applePayPaymentMethodSchema,
  googlePayPaymentMethodSchema,
]);

export type AdyenPaymentMethod = z.infer<typeof adyenSelectedPaymentMethodSchema>;

export const selectedPaymentMethodSchema = z.union([
  qliroPaymentMethodSchema,
  klarnaPaymentMethodSchema,
  payAtPlaceMethodSchema,
  swishPaymentMethodSchema,
  adyenSelectedPaymentMethodSchema,
  newCofPaymentMethodSchema,
  unselectedPaymentMethodSchema,
]);

export type UnselectedPaymentMethod = z.infer<typeof unselectedPaymentMethodSchema>;

export type CouponMethod = z.infer<typeof couponMethodSchema>;

export type SelectedPaymentMethod = z.infer<typeof selectedPaymentMethodSchema>;

export type CheckoutPaymentMethod = (typeof CHECKOUT_PAYMENT_METHOD)[keyof typeof CHECKOUT_PAYMENT_METHOD];

export type PaymentMethodListItem = (SelectedPaymentMethod | CouponMethod | UnselectedPaymentMethod) & {
  title?: string;
  subTitle?: string;
  hidden?: boolean;
  highlighted?: boolean;
  isSelected?: boolean;
};

export const availablePaymentMethodSchema = z.union([
  storedCofPaymentMethodSchema,
  qliroPaymentMethodSchema,
  klarnaPaymentMethodSchema,
  googlePayPaymentMethodSchema,
  applePayPaymentMethodSchema,
  payAtPlaceMethodSchema,
  swishPaymentMethodSchema,
  newCofPaymentMethodSchema,
  unselectedPaymentMethodSchema,
]);

export type AvailablePaymentMethod = z.infer<typeof availablePaymentMethodSchema>;

export const availablePrePaymentMethodSchema = z.union([
  adyenSelectedPaymentMethodSchema,
  qliroPaymentMethodSchema,
  klarnaPaymentMethodSchema,
  swishPaymentMethodSchema,
]);

export type AvailablePrePaymentMethod = z.infer<typeof availablePrePaymentMethodSchema>;

export const availablePrePaymentRadioButtonsMethodSchema = z.union([
  adyenSelectedPaymentMethodSchema,
  klarnaPaymentMethodSchema,
  swishPaymentMethodSchema,
  payAtPlaceMethodSchema,
]);

export type AvailablePrePaymentRadioButtonsMethod = z.infer<typeof availablePrePaymentRadioButtonsMethodSchema>;

export type AggressiveCheckoutPaymentMethod =
  | typeof CHECKOUT_PAYMENT_METHOD.APPLE_PAY
  | typeof CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY;

export const checkoutSummarySchema = z.object({
  isOnlinePaymentRequired: z.boolean(),
  availablePaymentMethods: z.array(availablePaymentMethodSchema),
  acceptsGiftcard: z.boolean(),
  acceptsValuecard: z.boolean(),
  acceptsWellnesscard: z.boolean(),
  preSelectedPaymentMethod: availablePrePaymentMethodSchema.or(payAtPlaceMethodSchema).optional(),
  payLater: z.boolean().optional(),
  finalWithoutGiftcards: z.number(),
  isPayable: z.boolean(),
});

export type CheckoutSummary = z.infer<typeof checkoutSummarySchema>;

export const bookingCheckoutSummarySchema = z.object({
  canPayOnline: z.boolean(),
  cancelableUntilTs: z.number(),
  canCancel: z.boolean(),
  finalPrice: z.number(),
  ...checkoutSummarySchema.shape,
});

export type BookingCheckoutSummary = z.infer<typeof bookingCheckoutSummarySchema>;

export const checkoutMissingActionSchema = z.union([
  z.literal('practitioner-terms'),
  z.literal('payment-method'),
  z.literal('participants'),
]);

export type CheckoutMissingAction = z.infer<typeof checkoutMissingActionSchema>;

export const baseCheckoutStateSchema = z.object({
  summary: checkoutSummarySchema,
  selectedPaymentMethod: selectedPaymentMethodSchema,
  missingActions: z.array(checkoutMissingActionSchema),
  submitting: z.boolean(),
  error: z.boolean().optional(),
});

export type BaseCheckoutState = z.infer<typeof baseCheckoutStateSchema>;

export type CheckoutAction<Summary extends CheckoutSummary> =
  | {
      type: 'UPDATE_SUMMARY';
      payload: Summary;
    }
  | {
      type: 'SET_PAYMENT_METHOD';
      payload: SelectedPaymentMethod;
    }
  | {
      type: 'SUBMITTING';
      payload: boolean;
    }
  | {
      type: 'SUCCESS_REDIRECT';
      payload: (typeof CHECKOUT_PAYMENT_METHOD)[keyof typeof CHECKOUT_PAYMENT_METHOD];
    }
  | {
      type: 'ERROR';
      payload: boolean;
    }
  | {
      type: 'SET_MISSING_ACTION';
      payload: CheckoutMissingAction;
    };

export type CheckoutAPI = {
  submitPayAtPlace?: () => Promise<void>;
  submitSwish?: (bookState?: BookState) => Promise<void>;
  submitGuestCoF?: (paymentData: CofPaymentData) => Promise<void>;
  submitCoF: (storedPaymentMethodId: string) => Promise<void>;
  submitGooglePay: (final: number, payLater: boolean) => Promise<void>;
  submitApplePay: (final: number, payLater: boolean) => Promise<void>;
  submitThreeDS: (_state: any, payLater: boolean, isGooglePay?: boolean) => Promise<void>;
  initKlarna?: () => Promise<void>;
  initQliro: () => Promise<void>;
};

export type CheckoutEmployee = {
  random: boolean;
  anyEmployeeSelected: boolean;
  actual: PlaceEmployee;
  shown: PlaceEmployee;
  selectedEmployeeId: number;
  showSelected: boolean;
  terms: string;
};
