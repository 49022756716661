import { CloseIcon } from '@/components/icons';
import { getImageSize } from '@/helpers';

import { Button } from '@/components/elements/forms/buttons';
import { _s } from '@/locale';
import { useRef, useState } from 'react';
import { ShadowModal } from '../modals';
import AssociationContent, {
  associationTranslationKeyName,
  associationsWithContent,
} from '../pages/associations/AssociationContent';

type AssociationBadgeProps = {
  association: {
    description: string;
    imgSrc: string;
    name: string;
    text?: string;
  };
};

const AssociationBadge = ({ association }: AssociationBadgeProps) => {
  const [showModal, setShowModal] = useState(false);
  const readMoreRef = useRef<HTMLButtonElement>(null);

  const handleShowModalClick = () => {
    setShowModal(true);
    readMoreRef.current?.blur();
  };

  const imageSource = getImageSize(association.imgSrc, 'x44');
  const associationName = associationTranslationKeyName(association.name);
  return (
    <>
      <div className="bg-black-50 flex items-center rounded-sm p-4">
        <img
          width={24}
          height={24}
          className="mr-3 max-h-[24px] w-auto"
          src={imageSource}
          alt={association.description}
          loading="lazy"
          itemProp="image"
          itemType="http://schema.org/ImageObject"
        />
        <span className="text-black-600 text-sm">
          {association.description}
          {associationsWithContent.includes(associationName) && (
            <>
              &nbsp;
              <button ref={readMoreRef} onClick={handleShowModalClick} className="text-primary text-link">
                {_s('readMore')}
              </button>
            </>
          )}
        </span>
      </div>
      {associationsWithContent.includes(associationName) && (
        <ShadowModal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          contentClass="!rounded-none p-10 relative w-full h-full md:!pt-16 overflow-scroll no-scrollbar"
          className="no-scrollbar !m-0 h-full w-full !max-w-full self-start overflow-scroll md:!m-4 md:!h-[inherit] md:!max-w-xl">
          <div className="pb-[112px] text-left md:pb-0">
            <>
              <button className="absolute right-8 top-8 hidden md:block" onClick={() => setShowModal(false)}>
                <CloseIcon className="h-5 w-5" />
              </button>
              <AssociationContent name={associationName} />
              <div className="fixed bottom-0 left-0 w-full bg-white px-6 pb-12 pt-4 shadow-sm md:hidden">
                <Button variant="primary" size="lg" block onClick={() => setShowModal(false)}>
                  {_s('close')}
                </Button>
              </div>
            </>
          </div>
        </ShadowModal>
      )}
    </>
  );
};

export default AssociationBadge;
