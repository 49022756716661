import { classnames } from '@/helpers';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type ListItemType = 'li' | 'div';

type LocationProps = {
  state: any;
  pathname: string;
};

export type ListItemProps = {
  children: ReactNode;
  leftSlot?: JSX.Element;
  rightSlot?: JSX.Element;
  underline?: boolean;
  leftPadding?: boolean;
  rightPadding?: boolean;
  className?: string;
  to?: LocationProps | string;
  targetBlank?: boolean;
  onClick?: () => void;
  type?: ListItemType;
  verticalAlign?: 'center' | 'top';
};

const ListItemWrapper = ({ className, children, to, onClick, targetBlank = false }) => {
  const isLink = to;
  if (isLink) {
    return to.state || to.startsWith('/') ? (
      <Link className={className} to={to} onClick={onClick}>
        {children}
      </Link>
    ) : (
      <a className={className} href={to} {...(targetBlank && { target: 'blank' })} onClick={onClick}>
        {children}
      </a>
    );
  }

  if (onClick) {
    return (
      <button className={className} type="button" onClick={onClick}>
        {children}
      </button>
    );
  }

  return <div className={className}>{children}</div>;
};

const ListItem = ({
  to,
  onClick,
  children,
  leftSlot,
  rightSlot,
  leftPadding = true,
  rightPadding = true,
  underline,
  className = '',
  verticalAlign = 'center',
  type = 'li',
  targetBlank,
}: ListItemProps) => {
  return (
    <>
      {type === 'li' && (
        <li className="relative w-full list-none">
          <ListItemWrapper
            to={to}
            targetBlank={targetBlank}
            onClick={onClick}
            className={classnames(
              'focus:outline-black-900 block w-full text-current outline-none',
              leftPadding && 'pl-lg',
            )}>
            <span
              className={twMerge(
                classnames(
                  'border-black-100 py-md gap-md flex w-full text-start',
                  underline && 'border-b',
                  verticalAlign === 'top' ? 'items-start' : 'items-center',
                  rightPadding && 'pr-lg',
                ),
                className,
              )}>
              {leftSlot && <div className="min-w-[24px] flex-shrink-0">{leftSlot}</div>}
              <div className="flex-1 overflow-hidden">{children}</div>
              {rightSlot && <div className="flex-shrink-0">{rightSlot}</div>}
            </span>
          </ListItemWrapper>
        </li>
      )}
      {type === 'div' && (
        <div className="relative w-full list-none">
          <ListItemWrapper
            to={to}
            targetBlank={targetBlank}
            onClick={onClick}
            className={classnames('focus:outline-black-900 block w-full text-current', leftPadding && 'pl-lg')}>
            <span
              className={twMerge(
                classnames(
                  'border-black-100 py-md gap-md flex w-full text-start',
                  underline && 'border-b',
                  verticalAlign === 'top' ? 'items-start' : 'items-center',
                  rightPadding && 'pr-lg',
                ),
                className,
              )}>
              {leftSlot && <div className="min-w-[24px] flex-shrink-0">{leftSlot}</div>}
              <div className="flex-1 overflow-hidden">{children}</div>
              {rightSlot && <div className="flex-shrink-0">{rightSlot}</div>}
            </span>
          </ListItemWrapper>
        </div>
      )}
    </>
  );
};

export default ListItem;
