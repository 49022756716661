import { bookActions } from '@/actions';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { usePinInput } from '@/components/elements/forms/PinInput/PinInput';
import { Button } from '@/components/elements/forms/buttons';
import { ButtonProps } from '@/components/elements/forms/buttons/Button/Button';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import UpdateUserInfo from '@/components/modules/UpdateUserInfo/UpdateUserInfo';
import useUpdateUserInfo from '@/components/modules/UpdateUserInfo/UpdateUserInfo.hooks';
import { ThreeDSComponent } from '@/components/modules/adyen';
import AdditionalParticipants from '@/components/modules/checkout/AdditionalParticipants/AdditionalParticipants';
import AdditionalParticipantsModal from '@/components/modules/checkout/AdditionalParticipantsModal/AdditionalParticipantsModal';
import BookingTerms from '@/components/modules/checkout/BookingTerms/BookingTerms';
import CheckoutDetails, { CheckoutDetailsProps } from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import CheckoutFormFields, {
  DEFAULT_FORM_FIELD_ID,
} from '@/components/modules/checkout/CheckoutFormFields/CheckoutFormFields';
import {
  CheckoutFormProvider,
  useCheckoutForm,
} from '@/components/modules/checkout/CheckoutFormFields/CheckoutFormFields.hooks';
import CheckoutModal from '@/components/modules/checkout/CheckoutModal/CheckoutModal';
import {
  CheckoutModalProvider,
  useCheckoutModal,
} from '@/components/modules/checkout/CheckoutModal/CheckoutModal.hooks';
import CheckoutTerms from '@/components/modules/checkout/CheckoutTerms/CheckoutTerms';
import PromotedPaymentMethods from '@/components/modules/checkout/PromotedPaymentMethods/PromotedPaymentMethods';
import CreditCardModal from '@/components/modules/modals/CreditCardModal';
import SwishModal from '@/components/modules/modals/SwishModal';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import FloatingCTAWrapper from '@/components/modules/pages/booking/checkout/FloatingCTAWrapper';
import ProtectedUserLogin from '@/components/templates/checkout/ProtectedUserLogin/ProtectedUserLogin';
import SelectPaymentOptions from '@/components/templates/checkout/SelectPaymentOptions/SelectPaymentOptions';
import UserInfo from '@/components/templates/checkout/UserInfo/UserInfo';
import UserLogin from '@/components/templates/checkout/UserLogin/UserLogin';
import VerifyOTP from '@/components/templates/login/VerifyOTP';
import { EVENT_NAME, SCREEN_NAME } from '@/constants/analytics';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { TermLinkIdentifier } from '@/constants/terms';
import {
  classnames,
  getUserInitials,
  isSistaminuten,
  salonTracking,
  shouldRequireLogin,
  trackMpEvent,
  trackPage,
  trackPageCio,
  url,
} from '@/helpers';
import {
  BuildBookingTrackingProps,
  buildBookingTrackingProps,
  getBookingCheckoutEmployee,
  getBookingCheckoutSummary,
  getBookingSubmitLabel,
  getCheckoutTerms,
  getCheckoutTermsVariant,
  getPaymentRedirectResultIfAny,
  getUpdateEditProfileFields,
  guestCheckoutManager,
  hasPaymentMethod,
  isStandardFormField,
  mapBookStateToCheckoutDetailsServices,
  mapBookingCheckoutToCheckoutDetailsProps,
} from '@/helpers/checkout';
import withPlaceDetailsFallback from '@/hoc/withPlaceDetailsFallback';
import { useAppSelector } from '@/hooks';
import { UseCardsProvider, useCardsManager } from '@/hooks/adyen/useCards';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import { CheckoutFormDataProvider, useCheckoutFormData } from '@/hooks/useCheckoutFormData';
import useExternalScript from '@/hooks/useExternalScript';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { LoginContext } from '@/hooks/useLogin';
import useMobileView from '@/hooks/useMobileView';
import { usePaymentsHistory } from '@/hooks/usePaymentsHistory';
import useTrackScreenView, { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { CheckoutMissingAction, SelectedPaymentMethod } from '@/types/checkout';
import { PaymentCard } from '@/types/paymentcards';
import { BookState, bookStateSchema } from '@/types/state/book';
import { User } from '@/types/user';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { FieldValues, UseFormHandleSubmit } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import isEmail from 'validator/lib/isEmail';
import {
  BookingCheckoutProvider,
  saveBookingCheckoutStateToLocalStorage,
  useBookingCheckout,
} from './BookingCheckout.hooks';

export const baseTranslationKey = 'pages.booking.checkout.BookingCheckout';

function getGuestCheckoutProps(place: BookState['place'], user: User, guestId: string) {
  const requiresLogin = !user && shouldRequireLogin(place);
  const shouldPromptGuestCheckout = !requiresLogin && Boolean(!isSistaminuten() && !user && !guestId);
  const isGuestCheckout = Boolean(!isSistaminuten() && !user && guestId);
  const loggedOutCheckout = Boolean(isSistaminuten() || isGuestCheckout);

  return { shouldPromptGuestCheckout, isGuestCheckout, loggedOutCheckout };
}

function handleUpdateUserInfoCallback({ success, retry }, closeUpdateUserInfo: () => void) {
  trackScreenView({ name: success ? 'screen_view_update_profile_success' : 'screen_view_update_profile_fail' });

  if (success) closeUpdateUserInfo();

  toast(
    ({ closeToast }) => (
      <Snackbar
        label={_s(`${baseTranslationKey}.editProfileModal.snackbar.label.${success ? 'success' : 'error'}`)}
        type={success ? 'success' : 'danger'}
        action={
          <button onClick={success ? closeToast : retry}>
            {_s(`${baseTranslationKey}.editProfileModal.snackbar.action.${success ? 'success' : 'error'}`)}
          </button>
        }
      />
    ),
    { autoClose: 2000 },
  );
}

function trackBookingCheckoutScreenView(props: BuildBookingTrackingProps) {
  const bookingTrackingProps = buildBookingTrackingProps(props);
  trackScreenView({
    name: 'screen_view_checkout_booking_summary',
    properties: {
      ...bookingTrackingProps.bookingProps,
      ...bookingTrackingProps.paymentMethodsProps,
      ...bookingTrackingProps.extraProps,
    },
  });
}

function onSubmitBookingValidation(
  {
    isTermsAccepted,
    isValidPaymentMethod,
    isGroupBooking,
    isValidParticipants,
    onCheckoutMissingAction,
  }: {
    isTermsAccepted: boolean;
    isValidPaymentMethod: boolean;
    isGroupBooking: boolean;
    isValidParticipants?: boolean;
    onCheckoutMissingAction: (action: CheckoutMissingAction) => void;
  },
  handleFormSubmit: UseFormHandleSubmit<FieldValues, undefined>,
  callback: (missingActions: CheckoutMissingAction[]) => void,
) {
  const missingActions: CheckoutMissingAction[] = [];

  if (!isTermsAccepted) missingActions.push('practitioner-terms');
  if (!isValidPaymentMethod) missingActions.push('payment-method');
  if (isGroupBooking && !isValidParticipants) missingActions.push('participants');

  missingActions.forEach((action) => onCheckoutMissingAction(action));

  if (isGroupBooking) {
    callback(missingActions);
  } else {
    handleFormSubmit(
      () => {
        callback(missingActions);
      },
      (errors) => {
        const fieldNames = Array.from(
          new Set(
            Object.keys(errors).map((formField) => (isStandardFormField(formField) ? formField : 'custom_field')),
          ),
        );

        if (missingActions.length) fieldNames.push(...missingActions);

        trackMpEvent(EVENT_NAME.VALIDATION_FAIL, {
          screen_name: SCREEN_NAME.CHECKOUT_BOOKING_SUMMARY,
          field_name: fieldNames,
        });
      },
    )();
  }
}

function validateAppliedBookingBundleIfAny(booking: BookState, dispatch: Dispatch): void {
  const { appliedBundle, services } = booking;

  if (!appliedBundle) return;

  const appliedBundleApplicableForCheckout = services.some(
    (service) => service.id === appliedBundle?.service?.serviceId,
  );

  if (appliedBundleApplicableForCheckout) return;

  toast(
    ({ closeToast }) => (
      <Snackbar
        label="Applied bundle not applicable for checkout" // <- @TDOO: translate
        type="highlight"
        action={<button onClick={closeToast}>OK</button>}
      />
    ),
    { autoClose: false },
  );

  dispatch(bookActions.removeBundle());
}

const BookingCheckout = () => {
  const {
    summary,
    selectedPaymentMethod,
    employee,
    formFields,
    cofThreeDS,
    submitting,
    swish,
    termsAccepted,
    missingActions,
    guestId,
    protectedEmailError,
    groupBooking,
    onToggleTerms,
    bookingCheckoutAPI,
    onChangePaymentMethod,
    onApplyGiftcard,
    onRemoveGiftcard,
    onDismissSwishQRCode,
    onUpdateGroupBooking,
    onUseGuestCheckout,
    onValidateEmailProtected,
    onDismissEmailProtectedError,
    onCheckoutMissingAction,
  } = useBookingCheckout();
  // experiment with radio buttons
  const usePromotedPaymentMethods =
    useGetAmplitudeExperimentVariant()('promoted-payment-methods-web')?.value === 'variant-options-visible';
  // experiment with radio buttons and popular badge
  const useHighlightedPaymentMethods =
    useGetAmplitudeExperimentVariant()('highlighted-payment-methods')?.value === 'variant-popular-badge-visible';

  const {
    formState: { dirtyFields, isValid },
    resetErrors,
    reset,
  } = useCheckoutForm();
  const {
    view,
    email,
    handleEmailSubmit,
    updateEmail,
    handleLogin,
    handleEmailResendClicked,
    updateView,
    clearLoginState,
  } = useContext(LoginContext);
  const pinInputProps = usePinInput({ fields: 6, validateCallback: handleLogin });
  const { onModalClose: onCheckoutModalClose, ...checkoutModalContext } = useCheckoutModal();
  const { formData, onUpdateFormData } = useCheckoutFormData();
  const {
    handleSubmit,
    setError: setFormError,
    setValue: setFormValue,
    watch,
    clearErrors: clearFormErrors,
  } = useCheckoutForm();
  const { isMobileView } = useMobileView();
  const [showEditProfile, setShowEditProfile] = useState(false);
  /**
   * if email is optional and there is no logged user, prompt user to fill in email
   * to get a better user experience
   */
  const [showPromptEmailField, setShowPromptEmailField] = useState(false);
  const updateUserInfo = useUpdateUserInfo({
    callback: (props) =>
      handleUpdateUserInfoCallback({ retry: props.retry, success: props.success }, handleCloseEditProfile),
    triggerSource: SCREEN_NAME.CHECKOUT_BOOKING_SUMMARY,
  });
  const booking = useAppSelector((state) => state.book) as BookState;
  const user = useAppSelector((state) => state.users.user);

  // Refs
  const selectPaymentMethodContainerRef = useRef<HTMLDivElement>(null);
  const bookingTermsContainerRef = useRef<HTMLDivElement>(null);
  const confirmationContainerRef = useRef<HTMLDivElement>(null);
  const addParticipantContainerRef = useRef<HTMLDivElement>(null);
  const isCheckoutCTAInView = useIntersectionObserver(confirmationContainerRef, { root: null, threshold: 0.3 });

  // Computed fields
  const isGroupBooking = booking.time.capacity > 1;
  const selectedCapacity = booking.time?.selectedCapacity || 1;
  const showSwishQrCode = Boolean(swish?.showQrCode);
  const showThreeDSecure = Boolean(cofThreeDS?.adyenActionJson);
  const hasFormfields = Boolean(formFields.length);
  const showFloatingCTA = isCheckoutCTAInView !== undefined && Boolean(!isCheckoutCTAInView);
  const isTermsAccepted = termsAccepted;
  const isValidParticipants = groupBooking?.hasAddedParticipant;
  const { shouldPromptGuestCheckout, isGuestCheckout, loggedOutCheckout } = getGuestCheckoutProps(
    booking.place,
    user,
    guestId,
  );
  const isUsingRadioButtonsVariant = Boolean(usePromotedPaymentMethods || useHighlightedPaymentMethods);

  const bookingTrackingProps = buildBookingTrackingProps({
    booking,
    employee,
    summary,
    selectedPaymentMethod,
    formFields,
    selectedCapacity,
  });

  const services = mapBookStateToCheckoutDetailsServices(booking, employee.shown);

  const { giftcards, payLater, ...rest } = mapBookingCheckoutToCheckoutDetailsProps(
    summary,
    selectedPaymentMethod,
    employee.shown,
    booking,
    isGuestCheckout,
  );

  const checkoutDetailsProps: CheckoutDetailsProps = {
    ...rest,
    payLater,
    services,
    giftcards: giftcards.map((giftcard) => ({
      ...giftcard,
      removeGiftcard: onRemoveGiftcard(giftcard.code),
    })),
  };

  const checkoutTerms = getCheckoutTerms(
    'booking',
    Boolean(giftcards.length) ? TermLinkIdentifier.COUPON : getCheckoutTermsVariant(selectedPaymentMethod),
    getBookingSubmitLabel({
      paymentMethod: selectedPaymentMethod.type,
      isMobileView,
      isFloating: !isCheckoutCTAInView,
    }),
  );

  // only move fields that are NOT dirty to update user info form (dirty fields are fields that have been changed by user)
  const dirty = dirtyFields && Object.keys(dirtyFields);
  const toUpdateUserInfoFormfields = formData[1]
    ? Object.fromEntries(Object.entries(formData[1]).filter(([key]) => !dirty.includes(key)))
    : {};

  const { email: _, ...prefilledUserProfileFormFields } = getUpdateEditProfileFields(toUpdateUserInfoFormfields);
  const updateUserInfoProps = { ...updateUserInfo, ...prefilledUserProfileFormFields };

  const requireLogin = (() => {
    if (isSistaminuten()) return false;

    if (!user && shouldRequireLogin(booking.place)) return true;

    return shouldPromptGuestCheckout;
  })();

  // Handlers
  const handleApplyGiftcard = (code: string) => {
    onApplyGiftcard(code)(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      handleCloseCheckoutModal();
    });
  };

  const handleCloseCheckoutModal = () => {
    onCheckoutModalClose();

    trackBookingCheckoutScreenView({
      booking,
      employee,
      summary,
      selectedPaymentMethod,
      formFields,
      selectedCapacity,
    });
  };
  const handleCloseEditProfile = () => {
    setShowEditProfile(false);
    trackBookingCheckoutScreenView({
      booking,
      employee,
      summary,
      selectedPaymentMethod,
      formFields,
      selectedCapacity,
    });
  };

  const handleOnPromptEmailFieldDismiss = () => {
    guestCheckoutManager().setOptions({ promptedEmail: true });
    setShowPromptEmailField(false);
    checkoutModalContext.onModalShow();
  };

  const handleOnPromptEmailFieldContinue = () => {
    guestCheckoutManager().setOptions({ promptedEmail: true });
    setShowPromptEmailField(false);
    setFormError('email', { message: _s('EnterEmail'), type: 'required' }, { shouldFocus: true });
    trackBookingCheckoutScreenView({
      booking,
      employee,
      summary,
      selectedPaymentMethod,
      formFields,
      selectedCapacity,
    });
  };

  const handleCloseTerms = () => {
    trackBookingCheckoutScreenView({
      booking,
      employee,
      summary,
      selectedPaymentMethod,
      formFields,
      selectedCapacity,
    });
  };

  const handleChangePaymentMethod = (method: SelectedPaymentMethod) => {
    if (!loggedOutCheckout && method.type === CHECKOUT_PAYMENT_METHOD.NEW_COF) {
      /**
       * For UX reasons we only want to change to "new card" as selected if user is in the radio buttons experiment
       * This is because we need to run the formfields validation before the user inputs their card details
       */
      if (isUsingRadioButtonsVariant) {
        checkoutModalContext.onModalShow('cof');
      }
      return;
    }

    onChangePaymentMethod(method);

    if (loggedOutCheckout && method.type === CHECKOUT_PAYMENT_METHOD.NEW_COF) {
      return;
    }

    onCheckoutModalClose();
    trackBookingCheckoutScreenView({
      booking,
      employee,
      summary,
      selectedPaymentMethod: method,
      formFields,
      selectedCapacity,
    });
  };

  const handleCloseLoginModal = () => {
    clearLoginState();
    trackBookingCheckoutScreenView({
      booking,
      employee,
      summary,
      selectedPaymentMethod,
      formFields,
      selectedCapacity,
    });
  };

  const handleOnCreditCardSelect = (card: PaymentCard) => {
    handleChangePaymentMethod({
      type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
      brand: card.brand,
      id: card.id,
      lastFour: card.lastFour,
    });
  };

  const handleCloseAdditionalParticipantModal = () => {
    if (groupBooking.isUpdate || !groupBooking.hasAddedParticipant) {
      reset();
    } else {
      resetErrors();
    }

    onUpdateGroupBooking({
      showModal: false,
      isUpdate: false,
      participantId: groupBooking.hasAddedParticipant ? null : groupBooking.participantId,
    });
  };

  const handleDismissSwishQRCode = () => {
    trackBookingCheckoutScreenView({
      booking,
      employee,
      summary,
      selectedPaymentMethod,
      formFields,
      selectedCapacity,
    });
    onDismissSwishQRCode();
  };

  const handleSubmitAdditionalParticipantModal = () => {
    onUpdateGroupBooking({ showModal: false, isUpdate: false, participantId: null });
  };

  /**
   * Get a participant to show in AdditionalParticipant modal
   */
  const getCurrentParticipant = (): {
    participantFormId: string; // Id that participant will have in the form that is sent in booking request
    initialUser?: Record<string, string>; // User profile data to prefill fields in additionalParticipantModal
  } => {
    // Prefill if updating an existing participant or adding the first participant to the form
    const shouldPrefillUser = groupBooking.isUpdate || !groupBooking.hasAddedParticipant;

    return {
      ...(shouldPrefillUser ? { initialUser: formData[groupBooking.participantId] } : {}),
      participantFormId: shouldPrefillUser ? groupBooking.participantId : (Object.keys(formData).length + 1).toString(),
    };
  };

  /**
   * Get added participants that is going to be sent in booking request.
   * In case user have not added at least one participant then none is returned.
   */
  const getAddedParticipants = () => {
    return groupBooking.hasAddedParticipant
      ? Object.entries(formData).map(([key, value]) => ({
          id: key,
          ...(value.givenName || value.familyName ? { name: `${value.givenName} ${value.familyName}` } : {}),
          ...(value.email ? { email: value.email } : {}),
        }))
      : [];
  };

  const handleOpenAdditionalParticipantModal = (participantId?: string) => {
    const isUpdatingExistingParticipant = Boolean(participantId);
    onUpdateGroupBooking({
      ...(isUpdatingExistingParticipant ? { isUpdate: true, participantId: participantId } : {}),
      showModal: true,
    });
  };

  const handleOnRemoveCard = (card: PaymentCard) => {
    if (selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF && selectedPaymentMethod.id === card.id) {
      handleChangePaymentMethod({ type: CHECKOUT_PAYMENT_METHOD.NONE });
    }
  };

  const handleOnDismissEmailProtectedError = () => {
    setFormValue('email', undefined);
    clearFormErrors('email');
    onDismissEmailProtectedError();
    clearLoginState();
    trackBookingCheckoutScreenView({
      booking,
      employee,
      summary,
      selectedPaymentMethod,
      formFields,
      selectedCapacity,
    });
  };

  /**
   *
   * Guest checkout needs to run validation before letting user select payment method
   * this is due to the fact that if user selects card as a payment method the card details
   * will be entered inside the credit card modal and submitted directly instead of creating a CoF token.
   *
   * If we are not doing validation here the user will first be notified about form errors after they
   * submitted card details which will make the UX very bad.
   */
  const handleOnSelectPaymentOptionsClick = () => {
    if (loggedOutCheckout) {
      onSubmitBookingValidation(
        {
          isGroupBooking,
          isTermsAccepted,
          isValidPaymentMethod: true,
          isValidParticipants,
          onCheckoutMissingAction,
        },
        handleSubmit,
        (missingActions) => {
          if (missingActions.length) {
            trackMpEvent(EVENT_NAME.VALIDATION_FAIL, {
              screen_name: SCREEN_NAME.CHECKOUT_BOOKING_SUMMARY,
              field_name: missingActions,
            });
            return;
          }

          const hasOptionalEmailField = formFields.some(
            (field) => field.id === DEFAULT_FORM_FIELD_ID.EMAIL && !field.required,
          );
          const hasNotFilledEmail = !formData['1']?.email;
          const hasPromptedEmail = guestCheckoutManager().getOptions()?.promptedEmail;

          if (!hasPromptedEmail && hasOptionalEmailField && hasNotFilledEmail) {
            setShowPromptEmailField(true);
            return;
          }

          checkoutModalContext.onModalShow();
        },
      );
      return;
    }

    checkoutModalContext.onModalShow();
  };

  const handleBeforeThreeDSRedirect = () => {
    saveBookingCheckoutStateToLocalStorage({
      formData,
      formFields,
      missingActions,
      selectedPaymentMethod,
      submitting,
      summary,
      termsAccepted,
      cofThreeDS,
    });
  };

  // Effects
  useTrackScreenView({
    name: 'screen_view_checkout_booking_summary',
    properties: {
      ...bookingTrackingProps.bookingProps,
      ...bookingTrackingProps.paymentMethodsProps,
      ...bookingTrackingProps.extraProps,
    },
  });

  useTrackScreenView(
    {
      name: 'page_view_checkout_booking_summary',
      properties: {
        ...bookingTrackingProps.bookingProps,
        ...bookingTrackingProps.paymentMethodsProps,
        ...bookingTrackingProps.extraProps,
      },
    },
    [],
  );

  useTrackScreenView(
    {
      name: 'screen_view_checkout_booking_swish_summary',
      properties: {
        ...bookingTrackingProps.bookingProps,
        ...bookingTrackingProps.paymentMethodsProps,
        ...bookingTrackingProps.extraProps,
      },
    },
    [showSwishQrCode],
    [showSwishQrCode],
  );

  useTrackScreenView(
    {
      name: 'screen_view_select_payment_method',
      properties: { trigger_source: 'checkout_booking_summary' },
    },
    [checkoutModalContext.isOpen, checkoutModalContext.screen],
    [checkoutModalContext.isOpen && checkoutModalContext.screen === 'list'],
  );

  useTrackScreenView(
    { name: 'screen_view_checkout_add_gift_card' },
    [checkoutModalContext.isOpen, checkoutModalContext.screen],
    [checkoutModalContext.isOpen && checkoutModalContext.screen === 'applyGiftcard'],
  );

  useTrackScreenView(
    { name: 'screen_view_checkout_add_value_card' },
    [checkoutModalContext.isOpen, checkoutModalContext.screen],
    [checkoutModalContext.isOpen && checkoutModalContext.screen === 'applyValuecard'],
  );

  useTrackScreenView(
    { name: 'screen_view_checkout_add_wellness_card' },
    [checkoutModalContext.isOpen, checkoutModalContext.screen],
    [checkoutModalContext.isOpen && checkoutModalContext.screen === 'applyWellnesscard'],
  );

  useTrackScreenView(
    { name: 'screen_view_update_profile', properties: { trigger_source: 'checkout_booking_summary' } },
    [showEditProfile],
    [showEditProfile],
  );

  useTrackScreenView(
    { name: 'screen_view_prompt_optional_email_field', properties: { trigger_source: 'checkout_booking_summary' } },
    [showPromptEmailField],
    [showPromptEmailField],
  );

  useTrackScreenView(
    { name: 'screen_view_email_required_login', properties: { trigger_source: 'checkout_booking_summary' } },
    [protectedEmailError],
    [protectedEmailError],
  );

  useTrackScreenView(
    { name: 'screen_view_login_success', properties: { trigger_source: 'checkout_booking_summary' } },
    [view],
    [view === 'verifySuccess'],
  );

  useTrackScreenView(
    { name: 'screen_view_login_fail', properties: { trigger_source: 'checkout_booking_summary' } },
    [pinInputProps.error, pinInputProps.clipError],
    [pinInputProps.error || pinInputProps.clipError],
  );

  useTrackScreenView(
    { name: 'screen_view_otp_login_verify', properties: { trigger_source: 'checkout_booking_summary' } },
    [view],
    [view === 'verifyOtp'],
  );

  useEffect(() => {
    if (bookingTrackingProps?.transactionProps?.allowMarketing) {
      trackPage();
      trackPageCio('booking_step_confirm_booking', {
        merchant_id: bookingTrackingProps?.transactionProps?.place?.id,
        merchant_name: bookingTrackingProps?.transactionProps?.place?.name,
        service_name: bookingTrackingProps?.transactionProps?.contentName,
      });
    }
    salonTracking(booking.place);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (missingActions.includes('payment-method') && isValid) {
      selectPaymentMethodContainerRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
    } else if (missingActions.includes('practitioner-terms') && isValid) {
      bookingTermsContainerRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
    } else if (missingActions.includes('participants')) {
      addParticipantContainerRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingActions]);

  useEffect(() => {
    if (user && view === 'verifyOtp') updateView('verifySuccess');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, view]);

  /**
   * If user is not logged in we need to check if email is protected
   * and throw a modal to force user to sign in to continue with email
   */
  useEffect(() => {
    if (!isGuestCheckout) return;

    const subscription = watch((field, info) => {
      const value = info.name === 'email' && info.type === 'change' && field?.['email'];

      if (!value || value === undefined) return;

      const isValidEmail = isEmail(value.trim(), { allow_utf8_local_part: false });

      if (isValidEmail === true)
        onValidateEmailProtected(value, () => setFormError('email', { message: _s('EmailProtected') }));
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, isGuestCheckout]);

  return (
    <PageViewLayout
      type="subView"
      title={_s(`${baseTranslationKey}.page-title`)}
      back
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      {submitting && <LoadingPlaceHolder className="fixed h-screen" />}
      <div className="bg-brown-50 lg:bg-transparent">
        <div className="lg:py-xxl lg:container">
          <div className="gap-xxl flex items-start">
            <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
              <CardWrapper className="w-full lg:hidden">
                <CheckoutDetails {...checkoutDetailsProps} />
              </CardWrapper>
              {requireLogin && (
                <CardWrapper>
                  <UserLogin
                    email={email}
                    handleEmailSubmit={handleEmailSubmit}
                    updateEmail={updateEmail}
                    triggerSource={SCREEN_NAME.CHECKOUT_BOOKING_SUMMARY}
                    {...(shouldPromptGuestCheckout && { continueAsGuest: onUseGuestCheckout })}
                  />
                </CardWrapper>
              )}
              {!requireLogin && (
                <>
                  {!isGroupBooking && user && (
                    <CardWrapper>
                      <UserInfo
                        email={user?.contact?.email ?? ''}
                        name={`${user?.about?.givenName ?? ''} ${user?.about?.familyName ?? ''}`}
                        phone={user?.contact?.phone ?? ''}
                        initials={getUserInitials(user)}
                        onClick={() => setShowEditProfile(true)}
                      />
                    </CardWrapper>
                  )}
                  {isGroupBooking && (
                    <CardWrapper>
                      <div ref={addParticipantContainerRef}>
                        <AdditionalParticipants
                          capacity={booking.time.capacity}
                          onUpdateParticipants={(id) => handleOpenAdditionalParticipantModal(id)}
                          onAddParticipants={() => handleOpenAdditionalParticipantModal()}
                          participants={getAddedParticipants()}
                          error={missingActions.includes('participants')}
                        />
                      </div>
                    </CardWrapper>
                  )}
                  {!isGroupBooking && hasFormfields && (
                    <CardWrapper>
                      <CheckoutFormFields
                        isGuestCheckout={isGuestCheckout}
                        formFields={formFields}
                        initialFormValues={formData['1']}
                        onUpdateFormField={onUpdateFormData}
                      />
                    </CardWrapper>
                  )}
                  {employee.terms && (
                    <CardWrapper>
                      <div ref={bookingTermsContainerRef}>
                        <BookingTerms
                          terms={employee.terms}
                          termsAccepted={termsAccepted}
                          error={missingActions.includes('practitioner-terms')}
                          onChange={onToggleTerms}
                          onClose={handleCloseTerms}
                        />
                      </div>
                    </CardWrapper>
                  )}
                  {summary.canPayOnline && (
                    <CardWrapper>
                      <div ref={selectPaymentMethodContainerRef}>
                        {usePromotedPaymentMethods || useHighlightedPaymentMethods ? (
                          <PromotedPaymentMethods
                            summary={summary}
                            selectedPaymentMethod={selectedPaymentMethod}
                            onChangePaymentMethod={handleChangePaymentMethod}
                            triggerSource="checkout_booking_summary"
                            useHighlighted={useHighlightedPaymentMethods}
                            error={missingActions.includes('payment-method')}
                            {...checkoutModalContext}
                          />
                        ) : (
                          <SelectPaymentOptions
                            method={selectedPaymentMethod}
                            onClick={handleOnSelectPaymentOptionsClick}
                            error={missingActions.includes('payment-method')}
                          />
                        )}
                      </div>
                    </CardWrapper>
                  )}
                  <div ref={confirmationContainerRef}>
                    <CardWrapper>
                      <div className="gap-xl p-lg flex flex-col">
                        <SubmitCTA />
                        <CheckoutTerms
                          description={checkoutTerms.description}
                          linkLabel={checkoutTerms.linkLabel}
                          termsPageId={checkoutTerms.termsPageId}
                          onClose={handleCloseTerms}
                          triggerSource="checkout_booking_summary"
                        />
                      </div>
                    </CardWrapper>
                  </div>
                  <FloatingCTAWrapper
                    description={checkoutTerms.description}
                    linkLabel={checkoutTerms.linkLabel}
                    termsPageId={checkoutTerms.termsPageId}
                    show={showFloatingCTA && isMobileView}
                    onCloseTerms={handleCloseTerms}>
                    <SubmitCTA floating />
                  </FloatingCTAWrapper>
                </>
              )}
            </div>
            <div className="lg:gap-lg sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px] lg:flex-col">
              <CardWrapper className="w-full">
                <CheckoutDetails key={2} {...checkoutDetailsProps} />
              </CardWrapper>
              {showFloatingCTA && !isMobileView && (
                <CardWrapper className="w-full">
                  <div className="gap-xl p-lg flex flex-col">
                    <SubmitCTA />
                    <CheckoutTerms
                      description={checkoutTerms.description}
                      linkLabel={checkoutTerms.linkLabel}
                      termsPageId={checkoutTerms.termsPageId}
                      onClose={handleCloseTerms}
                      triggerSource="checkout_booking_summary"
                    />
                  </div>
                </CardWrapper>
              )}
            </div>
          </div>
        </div>
      </div>
      {checkoutModalContext.screen === 'cof' ? (
        <CreditCardModal
          isOpen={checkoutModalContext.isOpen}
          onBack={checkoutModalContext.onModalBack}
          onClose={handleCloseCheckoutModal}
          onBeforeThreeDSRedirect={handleBeforeThreeDSRedirect}
          onCardSelect={handleOnCreditCardSelect}
          onRemoveCard={handleOnRemoveCard}
          triggerSource="checkout_booking_summary"
          returnPath="/booking/checkout/validate-3ds-redirect"
          {...(loggedOutCheckout && {
            guestId,
            onAuthorize: (data) => {
              bookingCheckoutAPI().submitGuestCoF(data);
              handleCloseCheckoutModal();
            },
            amount: selectedPaymentMethod.final,
          })}
          {...checkoutModalContext}
        />
      ) : (
        <CheckoutModal
          summary={summary}
          selectedPaymentMethod={selectedPaymentMethod}
          onChangePaymentMethod={handleChangePaymentMethod}
          onApplyGiftcard={handleApplyGiftcard}
          onModalClose={handleCloseCheckoutModal}
          triggerSource="checkout_booking_summary"
          {...checkoutModalContext}
        />
      )}
      {showThreeDSecure && (
        <Modal isOpen={!submitting}>
          <Modal.Content floating={!isMobileView}>
            <div className={classnames('gap-xl pb-lg px-lg flex flex-col', isMobileView && 'px-lg')}>
              <ThreeDSComponent
                action={JSON.parse(cofThreeDS?.adyenActionJson)}
                handleOnAdditionalDetails={(data) => bookingCheckoutAPI().submitThreeDS(data, summary.payLater)}
              />
            </div>
          </Modal.Content>
        </Modal>
      )}
      {showSwishQrCode && <SwishModal onClose={handleDismissSwishQRCode} qrCode={swish.payment.qrCode} />}
      {view === 'verifyOtp' && (
        <Modal isOpen>
          <Modal.Content floating={!isMobileView}>
            <Modal.Header
              title={_s(`loginModal.verifyOtp.title`)}
              {...(view === 'verifyOtp' && { onClose: handleCloseLoginModal })}
            />
            <div className={classnames('gap-xl pb-lg px-lg flex flex-col', isMobileView && 'px-lg')}>
              {view === 'verifyOtp' && (
                <VerifyOTP
                  email={email}
                  source="checkout_booking_summary"
                  handleEmailResendClicked={handleEmailResendClicked}
                  {...pinInputProps}
                />
              )}
            </div>
          </Modal.Content>
        </Modal>
      )}
      {protectedEmailError && view === 'requestToken' && (
        <Modal isOpen>
          <Modal.Content floating={!isMobileView} gradientBackground>
            <Modal.Header
              title={_s(`${baseTranslationKey}.protectedUserLoginModal.title`)}
              onClose={handleOnDismissEmailProtectedError}
            />
            <div className={classnames(isMobileView && 'px-lg')}>
              <ProtectedUserLogin
                email={{ ...email, value: formData['1']?.email }}
                handleEmailSubmit={handleEmailSubmit}
                updateEmail={updateEmail}
                triggerSource={SCREEN_NAME.CHECKOUT_BOOKING_SUMMARY}
              />
            </div>
          </Modal.Content>
        </Modal>
      )}
      <Modal isOpen={showPromptEmailField}>
        <Modal.Content floating={!isMobileView}>
          <Modal.Header title={_s(`${baseTranslationKey}.promptEmailFieldModal.title`)} />
          <div className={classnames('space-y-lg flex flex-col', isMobileView && 'px-lg')}>
            <div className="space-y-md flex flex-col">
              <p>{_s(`${baseTranslationKey}.promptEmailFieldModal.body.p1`)}</p>
              <p>{_s(`${baseTranslationKey}.promptEmailFieldModal.body.p2`)}</p>
            </div>
            <div className="space-x-md flex justify-end">
              <Button variant="link" onClick={handleOnPromptEmailFieldDismiss}>
                {_s(`${baseTranslationKey}.promptEmailFieldModal.cta.secondary`)}
              </Button>
              <Button variant="primary" onClick={handleOnPromptEmailFieldContinue}>
                {_s(`${baseTranslationKey}.promptEmailFieldModal.cta.primary`)}
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
      <Modal isOpen={showEditProfile}>
        <Modal.Content floating={!isMobileView}>
          <Modal.Header title={_s(`${baseTranslationKey}.editProfileModal.title`)} onClose={handleCloseEditProfile} />
          <div className={classnames(isMobileView && 'px-lg')}>
            <UpdateUserInfo {...updateUserInfoProps} />
          </div>
        </Modal.Content>
      </Modal>
      {groupBooking.showModal && (
        <AdditionalParticipantsModal
          capacity={booking.time.capacity}
          onClose={handleCloseAdditionalParticipantModal}
          updateParticipant={groupBooking.isUpdate}
          formFields={formFields}
          {...getCurrentParticipant()}
          onSubmit={handleSubmitAdditionalParticipantModal}
        />
      )}
      <SEO
        {...seoPropsFromBaseString('checkout', `${url.getBaseUrl()}booking/checkout`)}
        noindex={isSistaminuten()}
        image={url.getBaseImageUrl()}
      />
    </PageViewLayout>
  );
};

const SubmitCTA = ({ floating = false }: { floating?: boolean }) => {
  const {
    selectedPaymentMethod,
    summary,
    submitting,
    termsAccepted,
    groupBooking,
    guestId,
    bookingCheckoutAPI,
    onCheckoutMissingAction,
  } = useBookingCheckout();
  const { handleSubmit } = useCheckoutForm();
  const { isMobileView } = useMobileView();
  const checkoutModal = useCheckoutModal();
  const booking = useAppSelector((state) => state.book) as BookState;
  const user = useAppSelector((state) => state.users)?.user;
  const paymentMethod = selectedPaymentMethod.type;
  const { final } = summary.availablePaymentMethods.find((method) => method.type === paymentMethod);
  const isGroupBooking = booking.time.capacity > 1;
  const isTermsAccepted = termsAccepted;
  const isValidPaymentMethod = paymentMethod !== CHECKOUT_PAYMENT_METHOD.NONE;
  const isValidParticipants = groupBooking.hasAddedParticipant;
  const { loggedOutCheckout } = getGuestCheckoutProps(booking.place, user, guestId);

  const submitBooking = (missingActions: CheckoutMissingAction[]) => {
    if (missingActions.length) {
      trackMpEvent(EVENT_NAME.VALIDATION_FAIL, {
        screen_name: SCREEN_NAME.CHECKOUT_BOOKING_SUMMARY,
        field_name: missingActions,
      });
      return;
    }

    switch (paymentMethod) {
      case CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE:
        return bookingCheckoutAPI().submitPayAtPlace();
      case CHECKOUT_PAYMENT_METHOD.STORED_COF:
        return bookingCheckoutAPI().submitCoF(selectedPaymentMethod.id);
      case CHECKOUT_PAYMENT_METHOD.SWISH:
        return bookingCheckoutAPI().submitSwish(booking);
      case CHECKOUT_PAYMENT_METHOD.QLIRO:
        return bookingCheckoutAPI().initQliro();
      case CHECKOUT_PAYMENT_METHOD.KLARNA:
        return bookingCheckoutAPI().initKlarna();
      case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
        return bookingCheckoutAPI().submitGooglePay(final, summary.payLater);
      case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
        return bookingCheckoutAPI().submitApplePay(final, summary.payLater);
      case CHECKOUT_PAYMENT_METHOD.NEW_COF:
        if (loggedOutCheckout) {
          return checkoutModal.onModalShow('cof');
        }
        break;
    }
  };

  const buttonProps: ButtonProps = {
    size: floating ? 'sm' : 'lg',
    block: !floating,
    disabled: submitting,
    label: getBookingSubmitLabel({ paymentMethod: paymentMethod, isFloating: floating, isMobileView: isMobileView }),
    onClick: () =>
      onSubmitBookingValidation(
        {
          isGroupBooking,
          isTermsAccepted,
          isValidPaymentMethod,
          isValidParticipants,
          onCheckoutMissingAction,
        },
        handleSubmit,
        submitBooking,
      ),
    className: 'whitespace-nowrap',
  };

  return <Button {...buttonProps} />;
};

/**
 * Wrap the checkout page in a component that checks if the book state is valid
 * and other validation that needs to be done before rendering the checkout page.
 */
export default withPlaceDetailsFallback(() => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const user = useAppSelector((state) => state?.users?.user);
  const bookState = useAppSelector((state) => state.book);
  const parsedBookState = bookStateSchema.safeParse(bookState);

  if (parsedBookState.success === false) {
    if (history.location.state && sessionStorage.getItem('bookPlace')) {
      Sentry.captureException(parsedBookState.error);
    }
    return <GoBack />;
  }

  const { saved, services, time, place } = parsedBookState.data;
  const invalidState = saved || !services || !services.length || !place || !time || !time.timestamp;

  if (invalidState) return <GoBack />;

  const employee = getBookingCheckoutEmployee(location.state, parsedBookState.data);
  const summary = getBookingCheckoutSummary({ booking: parsedBookState.data, employee });
  const hasCoF = hasPaymentMethod(summary, CHECKOUT_PAYMENT_METHOD.STORED_COF);
  const { cards, loading, error } = useCardsManager([], !!user && hasCoF);
  const isLoadingCards = loading && !error && !cards.length;
  const { paymentHistory, loading: loadingPaymentHistory } = usePaymentsHistory(place.id);

  useExternalScript({ src: 'https://pay.google.com/gp/p/js/pay.js', cleanup: false, load: hasCoF });

  useEffect(() => {
    validateAppliedBookingBundleIfAny(parsedBookState.data, dispatch);
    const paymentRedirectResultSuccess = getPaymentRedirectResultIfAny(location, history);
    if (paymentRedirectResultSuccess || paymentRedirectResultSuccess === undefined) return;
    toast(({ closeToast }) => (
      <Snackbar label={_s(`${baseTranslationKey}.paymentRedirectError`)} type="danger" onClose={closeToast} />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingCards || loadingPaymentHistory) return <LoadingPlaceHolder />;

  return (
    <UseCardsProvider initialCards={cards}>
      <CheckoutFormDataProvider employeeId={String(employee.actual.id)}>
        <BookingCheckoutProvider cards={cards} paymentHistory={paymentHistory}>
          <CheckoutModalProvider>
            <CheckoutFormProvider>
              <BookingCheckout />
            </CheckoutFormProvider>
          </CheckoutModalProvider>
        </BookingCheckoutProvider>
      </CheckoutFormDataProvider>
    </UseCardsProvider>
  );
});
