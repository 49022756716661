import { Button } from '@/components/elements/forms/buttons';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { LoadingIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import ListCards from '@/components/templates/creditCards/ListCards';
import ManageCards from '@/components/templates/creditCards/ManageCards';
import { classnames, getCardName, promiseWrapper } from '@/helpers';
import { buildBrowserInfo } from '@/helpers/googlepay';
import { useCardsContext } from '@/hooks/adyen/useCards';
import useMobileView from '@/hooks/useMobileView';
import { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { adyenServices } from '@/services';
import { CofPaymentData, NewOnlineCardPaymentData } from '@/types/adyen';
import { TriggerSource } from '@/types/analytics';
import { PaymentCard } from '@/types/paymentcards';
import { PaymentData } from '@adyen/adyen-web/dist/types/components/types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ThreeDSComponent } from '../adyen';
import AddCard from '../adyen/AddCard';
import Modal from './redesign/Modal/Modal';

const baseTranslationKey = 'components.modules.modals.CreditCardModal';

type CreditCardModalProps = {
  isOpen: boolean;
  returnPath: string;
  amount?: number;
  guestId?: string;
  onCardSelect: (card: PaymentCard) => void;
  onRemoveCard: (card: PaymentCard) => void;
  onBeforeThreeDSRedirect?: () => void;
  onAuthorize?: (paymentData: CofPaymentData) => void;
  onClose: () => void;
  onBack?: () => void;
};

const CreditCardModal = ({
  isOpen,
  triggerSource,
  returnPath,
  guestId,
  amount,
  onCardSelect,
  onClose,
  onBack,
  onRemoveCard,
  onBeforeThreeDSRedirect,
  onAuthorize,
}: CreditCardModalProps & { triggerSource: TriggerSource }) => {
  const [cardToRemove, setCardToRemove] = useState<PaymentCard>(null);
  const [threeDSAction, setThreeDSAction] = useState<string>(null);
  const { loading, loadCards, cards, removeCard: confirmRemoveCard } = useCardsContext();
  const [view, updateView] = useState<'list' | 'manage' | 'remove' | 'add' | 'pay'>('list');
  const { isMobileView } = useMobileView();

  useEffect(() => {
    if (isOpen) {
      switch (view) {
        case 'list':
          trackScreenView({ name: 'screen_view_select_payment_card', properties: { trigger_source: triggerSource } });
          break;
        case 'remove':
          trackScreenView({ name: 'screen_view_delete_payment_card', properties: { trigger_source: triggerSource } });
          break;
        case 'add':
        case 'pay':
          trackScreenView({
            name: 'screen_view_add_payment_card_details',
            properties: { trigger_source: triggerSource },
          });
          break;
        case 'manage':
          trackScreenView({ name: 'screen_view_manage_payment_card', properties: { trigger_source: triggerSource } });
          break;
      }
    }
  }, [isOpen, view, triggerSource]);

  const handleOnAuthorize = async (paymentData: PaymentData) => {
    const paymentMethod = paymentData.paymentMethod;

    const parsedPaymentData: NewOnlineCardPaymentData = {
      type: 'newCard',
      encryptedCardNumber: paymentMethod.encryptedCardNumber,
      encryptedExpiryMonth: paymentMethod.encryptedExpiryMonth,
      encryptedExpiryYear: paymentMethod.encryptedExpiryYear,
      encryptedSecurityCode: paymentMethod.encryptedSecurityCode,
      holderName: paymentMethod.holderName,
    };

    const saveUserCardData: CofPaymentData = {
      paymentMethod: parsedPaymentData,
      redirectInfo: { returnPath },
      browserInfo: paymentData.browserInfo || buildBrowserInfo(),
    };

    if (onAuthorize) {
      onAuthorize(saveUserCardData);
      return;
    }

    const { data, error } = await promiseWrapper(adyenServices.saveUserCard({ paymentData: saveUserCardData }));

    if (data && data.requiresAction) {
      const type = JSON.parse(data?.adyenActionJson || {})?.type;
      if (type === 'redirect') onBeforeThreeDSRedirect?.();
      setThreeDSAction(data.adyenActionJson);
      return;
    }

    if (error) {
      toast(({ closeToast }) => <Snackbar type="success" label={_s('serverError')} onClose={closeToast} />);
    }

    loadCards();
    updateView('list');
  };

  const handleOnAdditionalDetails = async (response) => {
    const { data, error } = await promiseWrapper(adyenServices.validate3DS({ details: response.data.details }));

    if (error || data?.resultCode !== 'Authorised') {
      toast(({ closeToast }) => (
        <Snackbar type="success" label={_s('checkoutError.StoreTokenError')} onClose={closeToast} />
      ));
    }

    setThreeDSAction(null);
    loadCards();
    updateView('list');
  };

  const handleOnError = () => null;

  const handleConfirmRemoveCard = async () => {
    const success = await confirmRemoveCard(cardToRemove.id);
    updateView('manage');

    if (success) {
      trackScreenView({ name: 'screen_view_delete_payment_card_success' });
      toast(({ closeToast }) => <Snackbar type="success" label={_s('cardRemoved')} onClose={closeToast} />);
      onRemoveCard(cardToRemove);
      loadCards();
    }

    if (!success) {
      trackScreenView({ name: 'screen_view_delete_payment_card_error' });
      toast(({ closeToast }) => <Snackbar type="success" label={_s('removeCardError')} onClose={closeToast} />);
    }

    setCardToRemove(null);
  };

  const handleRemoveCardSelect = (card: PaymentCard) => {
    setCardToRemove(card);
    updateView('remove');
  };

  const handleRemoveCardCancel = () => {
    setCardToRemove(null);
    updateView('manage');
  };

  const handleCardSelect = (card: PaymentCard) => {
    onCardSelect(card);
    updateView('list');
  };

  const handleOnClose = () => {
    onClose();
    updateView('list');
  };

  const handleOnBackClick = () => {
    if (view === 'list') {
      onBack();
    }
    if (view === 'manage') {
      updateView('list');
    }
    if (view === 'add' || view === 'pay') {
      updateView('list');
      if (cards.length < 1) onBack();
    }
  };

  const showBack = view !== 'remove';
  const showClose = view !== 'remove';
  const title = _s(`${baseTranslationKey}.view.${view}.title`);
  const viewToShow = loading ? 'loading' : view;
  const addCardView = guestId ? 'pay' : 'add';

  if (isOpen && viewToShow !== addCardView && !loading && !cards?.length) updateView(addCardView);

  return (
    <Modal isOpen={isOpen}>
      <Modal.Content floating={!isMobileView}>
        <Modal.Header
          title={title}
          {...(showBack && { onBack: handleOnBackClick })}
          {...(showClose && { onClose: handleOnClose })}
        />
        <div className={classnames(isMobileView && 'px-lg')}>
          {viewToShow === 'loading' && <CardsLoading />}
          {viewToShow === 'list' && (
            <>
              <ListCards cards={cards} onCardClick={handleCardSelect} />
              <div className="py-lg space-y-lg flex flex-col">
                <Button
                  leftIcon={<Icon variant="plus" />}
                  variant="primary"
                  size="md"
                  block
                  onClick={() => updateView('add')}>
                  {_s(`${baseTranslationKey}.view.list.addCTA`)}
                </Button>
                <Button
                  leftIcon={<Icon variant="credit-card" />}
                  variant="secondary"
                  size="md"
                  block
                  onClick={() => updateView('manage')}>
                  {_s(`${baseTranslationKey}.view.list.manageCTA`)}
                </Button>
              </div>
            </>
          )}
          {viewToShow === 'manage' && <ManageCards cards={cards} removeCard={handleRemoveCardSelect} />}
          {viewToShow === 'remove' && (
            <div className="space-y-lg flex flex-col">
              <p className="text-md text-black-600">
                {_s(`${baseTranslationKey}.view.remove.body`)}
                <br /> {getCardName(cardToRemove.brand)} ...{cardToRemove.lastFour}
              </p>
              <div className="space-x-md flex items-center justify-end">
                <Button variant="secondary" onClick={handleConfirmRemoveCard}>
                  {_s(`${baseTranslationKey}.view.remove.confirm`)}
                </Button>
                <Button variant="primary" onClick={handleRemoveCardCancel}>
                  {_s(`${baseTranslationKey}.view.remove.cancel`)}
                </Button>
              </div>
            </div>
          )}
          {(viewToShow === 'add' || viewToShow === 'pay') && (
            <>
              {threeDSAction && (
                <ThreeDSComponent
                  action={JSON.parse(threeDSAction)}
                  handleOnAdditionalDetails={handleOnAdditionalDetails}
                />
              )}
              {!threeDSAction && viewToShow === 'add' && (
                <AddCard
                  onAuthorize={handleOnAuthorize}
                  onError={handleOnError}
                  submitLabel={_s('addCard')}
                  disclaimerLabel={_s(`${baseTranslationKey}.view.${view}.disclaimer`)}
                />
              )}
              {!threeDSAction && viewToShow === 'pay' && (
                <AddCard
                  onAuthorize={handleOnAuthorize}
                  onError={handleOnError}
                  submitLabel={_s('pay')}
                  disclaimerLabel={_s(`${baseTranslationKey}.view.${view}.disclaimer`)}
                  amount={amount}
                  guestId={guestId}
                />
              )}
            </>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

const CardsLoading = () => {
  return (
    <div className="py-md flex flex-col items-center justify-center">
      <LoadingIcon className="w-3xl" />
    </div>
  );
};

export default CreditCardModal;
