import { AVATAR_ICON_SIZE } from '@/constants/AvatarConstants';
import { classnames } from '@/helpers';
import { getSvgPath } from 'figma-squircle';

import Icon from '@/components/icons/Icon';
import { AvatarVariantIcon } from '../AvatarVariantIcon';
import { AvatarProps } from '../types';

const getStyle = (size, inner: boolean): React.CSSProperties => {
  const { width, height, cornerRadius } = inner ? innserSizes[size] : sizes[size];
  const path = getSvgPath({
    width,
    height,
    cornerRadius,
    cornerSmoothing: 1,
  });
  return {
    width,
    height,
    clipPath: `path('${path}')`,
  };
};

const sizes = {
  sm: {
    width: 32,
    height: 32,
    cornerRadius: 6,
  },
  md: {
    width: 48,
    height: 48,
    cornerRadius: 9,
  },
  lg: {
    width: 64,
    height: 64,
    cornerRadius: 12,
  },
  xl: {
    width: 96,
    height: 96,
    cornerRadius: 18,
  },
};
const innserSizes = {
  sm: {
    width: 30,
    height: 30,
    cornerRadius: 5.25,
  },
  md: {
    width: 46,
    height: 46,
    cornerRadius: 8.25,
  },
  lg: {
    width: 62,
    height: 62,
    cornerRadius: 11.25,
  },
  xl: {
    width: 94,
    height: 94,
    cornerRadius: 17.25,
  },
};

const CompanyAvatar = ({ variant = 'default', size = 'xl', alt, imgUrl }: AvatarProps) => {
  return (
    <div className="w-3x relative inline-block">
      <div className={classnames('bg-black-100 flex items-center justify-center')} style={{ ...getStyle(size, false) }}>
        {imgUrl && (
          <img
            src={imgUrl}
            alt={alt}
            className="relative z-10 h-full w-full object-cover"
            style={{ ...getStyle(size, true) }}
            itemProp="image"
            itemType="http://schema.org/ImageObject"
            itemScope
          />
        )}
        {!imgUrl && (
          <div className="bg-black-100">
            <Icon variant="shop-alt" color="black-500" size={AVATAR_ICON_SIZE[size]} />
          </div>
        )}
      </div>
      {variant !== 'default' && <AvatarVariantIcon size={size} variant={variant} />}
    </div>
  );
};

export default CompanyAvatar;
