import { ListItem } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { getCardName, hasCardExpired } from '@/helpers';
import { getSelectedPaymentDetails } from '@/helpers/checkout';
import { _s } from '@/locale';
import { PaymentCard } from '@/types/paymentcards';

const ListCards = ({ cards, onCardClick }: { cards: PaymentCard[]; onCardClick: (card: PaymentCard) => void }) => {
  return (
    <div className="flex flex-col">
      {cards?.map((card) => {
        const isExpired = hasCardExpired(card.expiryMonth, card.expiryYear);
        const { icon } = getSelectedPaymentDetails({
          type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
          brand: card.brand,
          id: card.id,
          lastFour: card.lastFour,
        });

        return (
          <ListItem
            key={card.id}
            underline
            leftSlot={<Icon variant={icon} noFilter style={{ width: 48, height: 48 }} />}
            rightSlot={<Icon variant="chevron-s-right" />}
            onClick={() => onCardClick(card)}>
            <h3 className="mb-1 font-semibold">{getCardName(card.brand)}</h3>
            <p className="relative top-[-4px] flex items-center">
              ...
              {card.lastFour}.&nbsp;
              <span className={`${isExpired ? 'text-danger' : ''}`}>
                {`${isExpired ? _s('expired') : _s('expires')}: ${card.expiryMonth}/${card.expiryYear}`}
              </span>
            </p>
          </ListItem>
        );
      })}
    </div>
  );
};

export default ListCards;
