import Header from '@/components/elements/Header/Header';
import { Switch } from '@/components/elements/forms/Switch';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { useState } from 'react';
import Modal from '../../modals/redesign/Modal/Modal';

type BookingTermsProps = {
  terms: string | React.ReactNode;
  termsAccepted: boolean;
  error?: boolean;
  onChange: () => void;
  onClose: () => void;
};

const baseTranslationKey = 'components.modules.checkout.BookingTerms';

const BookingTerms = ({ terms, termsAccepted, error, onChange, onClose }: BookingTermsProps) => {
  const { isMobileView } = useMobileView();
  const [showTerms, setShowTerms] = useState(false);

  useTrackScreenView(
    { name: 'screen_view_practitioner_terms', properties: { trigger_source: 'checkout_booking_summary' } },
    [showTerms],
    [showTerms],
  );

  return (
    <div className="pb-lg">
      <div className="pl-lg py-md">
        <Header label={_s(`${baseTranslationKey}.title`)} size="md" />
      </div>
      <ListItem
        {...(error && { underline: true, className: 'border-danger-500' })}
        rightSlot={<Switch switched={termsAccepted} onChange={onChange} />}>
        <ListItemContent
          subTitle={
            <span>
              {_s(`${baseTranslationKey}.description`)}
              <button onClick={() => setShowTerms(true)} className="text-information-700 inline underline">
                {_s(`${baseTranslationKey}.terms`)}
              </button>
            </span>
          }
        />
      </ListItem>
      {error && <p className="pl-lg pt-sm text-danger-500 text-xxs">{_s(`${baseTranslationKey}.error`)}</p>}
      <Modal isOpen={showTerms}>
        <Modal.Content floating={!isMobileView}>
          <Modal.Header
            title={_s(`${baseTranslationKey}.modal.title`)}
            onClose={() => {
              setShowTerms(false);
              onClose();
            }}
          />
          <div className="px-xxl py-md md:p-0">{terms}</div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default BookingTerms;
